import Message from './Message';
import BandyerSDK from '../../index';
import { ExposeError } from '../../../helpers/errorHandler';

let channelUniqueName = null;
const events = require('events');

export default class Channel extends events {
    constructor(uniqueName) {
        super();
        channelUniqueName = uniqueName;
        const logicalChannel = BandyerSDK.getInstance().chat.getChannelByUniqueName(uniqueName);
        if (!logicalChannel) {
            throw new ExposeError({ code: 'channel_not_found', message: 'Channel does not exist' });
        }
        this._channelId = logicalChannel.channelId;
        this._participants = logicalChannel.participants.map(user => user.userAlias);
        this._messages = new Map();
        Array.from(logicalChannel.messages.values()).forEach((message) => {
            this._messages.set(message.messageId, new Message(this, message));
        });
        logicalChannel.on('messageAdded', (message) => {
            const externalMessage = new Message(this, message);
            this._messages.set(message.messageId, externalMessage);
            this.emit('channel:message:added', externalMessage);
        });
    }


    get channelId() {
        return this._channelId;
    }

    get participants() {
        return this._participants;
    }

    get unreadMessages() {
        const channel = BandyerSDK.getInstance().chat.getChannelByUniqueName(channelUniqueName);
        if (channel) {
            return channel.unreadMessages;
        }
        throw new Error('The channel was removed');
    }

    get messages() {
        return Array.from(this._messages.values());
    }

    async fetchMessages(messageToFetch, anchor, direction) {
        const channel = BandyerSDK.getInstance().chat.getChannelByUniqueName(channelUniqueName);
        if (channel) {
            const toReturn = channel.getMessages(messageToFetch, anchor, direction);
            const messages = toReturn.messages ? toReturn.messages.map((m) => {
                const message = new Message(this, m);
                this._messages.set(m.messageId, message);
                return message;
            }) : [];
            return { anchor: toReturn.anchor, messages };
        }
        throw new ExposeError({ code: 'channel_not_exists', message: 'The channel does not exists anymore' });
    }

    async sendMessage(text) {
        const channel = BandyerSDK.getInstance().chat.getChannelByUniqueName(channelUniqueName);
        if (channel) {
            const message = await channel.sendMessage(text);
            const externalMessage = new Message(this, message);
            this._messages.set(message.messageId, externalMessage);
            return externalMessage;
        }
        throw new ExposeError({ code: 'channel_not_exists', message: 'The channel does not exists anymore' });
    }
}
