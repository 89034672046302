export const TOGGLE_INPUT_DISABLED = 'TOGGLE_INPUT_DISABLED';
export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN';
export const SET_LOCAL_USER_INFO = 'SET_LOCAL_USER_INFO';
export const SET_CHAT_OPEN = 'SET_CHAT_OPEN';
export const SET_CHAT_CLOSE = 'SET_CHAT_CLOSE';
export const SET_FULL_SCREEN_MODE = 'SET_FULL_SCREEN_MODE';
export const HIDE_CHAT = 'HIDE_CHAT';
export const SET_RECORD_VALUE = 'SET_RECORD_VALUE';
export const LOGOUT = 'LOGOUT';

/* MESSAGE ACTIONS  */

export const ADD_NEW_USER_MESSAGE = 'ADD_NEW_USER_MESSAGE';
export const ADD_NEW_RESPONSE_MESSAGE = 'ADD_NEW_RESPONSE_MESSAGE';
export const ADD_NEW_LINK_SNIPPET = 'ADD_NEW_LINK_SNIPPET';
export const ADD_COMPONENT_MESSAGE = 'ADD_COMPONENT_MESSAGE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const EMPTY_MESSAGE = 'EMPTY_MESSAGE';
export const REMOVE_CHAT = 'REMOVE_CHAT';

/* TWILIO CHAT ACTIONS */
export const WIDGET_READY = 'WIDGET_READY';
export const CHAT_IS_LOADING = 'CHAT_IS_LOADING';
export const CHAT_HAS_ERROR = 'CHAT_HAS_ERROR';

/* TWILIO CHANNELS ACTIONS */

export const SUBSCRIBED_CHANNELS_FETCHED = 'SUBSCRIBED_CHANNELS_FETCHED';
export const SUBSCRIBED_CHANNELS_FETCHED_NO_RESULTS = 'SUBSCRIBED_CHANNELS_FETCHED_NO_RESULTS';
export const IS_TYPING = 'IS_TYPING';

/* TWILIO VIDEOCALL ACTIONS */

export const CREATE_ROOM_REQUEST = 'CREATE_ROOM_REQUEST';
export const RINGING_REQUEST = 'RINGING_REQUEST';
export const RINGING_RESPONSE = 'RINGING_RESPONSE';

/* CHANGE OF VIEWS */

export const CHANGE_VIEW = 'CHANGE_VIEW';
export const UPDATE_CALL = 'UPDATE_CALL';

/* CALL ACTIONS */

export const PUBLISH_VIDEO = 'PUBLISH_VIDEO';
export const PUBLISH_SCREEN = 'PUBLISH_SCREEN';
export const SUBSCRIBE_STREAM = 'SUBSCRIBE_STREAM';
export const CHANGE_MAIN_VIDEO = 'CHANGE_MAIN_VIDEO';
export const CHANGE_MUTE_AUDIO = 'CHANGE_MUTE_AUDIO';
export const CHANGE_MUTE_VIDEO = 'CHANGE_MUTE_VIDEO';
export const SET_EXTENSION_URL = 'SET_EXTENSION_URL';
export const SHOW_EXTENSION_ALERT = 'SHOW_EXTENSION_ALERT';
export const SET_CALL_TYPE = 'SET_CALL_TYPE';

/* ACTIVITY */

export const UPDATE_MESSAGE_RECEIVED = 'UPDATE_MESSAGE_RECEIVED';
export const UPDATE_MESSAGE_SENT = 'UPDATE_MESSAGE_SENT';
export const SET_UNREAD_MESSAGE_FOR_CHANNEL = 'SET_UNREAD_MESSAGE_FOR_CHANNEL';
export const CLEAR_UNREAD_MESSAGE_FOR_CHANNEL = 'CLEAR_UNREAD_MESSAGE_FOR_CHANNEL';

/* CUSTOM STYLE */

export const CHANGE_FONT_FAMILY = 'CHANGE_FONT_FAMILY';
export const CHANGE_HEADER_STYLE_BACKGROUND = 'CHANGE_HEADER_STYLE_BACKGROUND';
export const CHANGE_HEADER_STYLE_COLOR = 'CHANGE_HEADER_STYLE_COLOR';
export const CHANGE_HEADER_BUTTON_STYLE_BACKGROUND = 'CHANGE_HEADER_BUTTON_STYLE_BACKGROUND';
export const CHANGE_HEADER_BUTTON_STYLE_COLOR = 'CHANGE_HEADER_BUTTON_STYLE_COLOR';
export const CHANGE_BODY_STYLE_BACKGROUND = 'CHANGE_BODY_STYLE_BACKGROUND';
export const CHANGE_BODY_STYLE_COLOR = 'CHANGE_BODY_STYLE_COLOR';
export const CHANGE_LAUNCHER_STYLE_BACKGROUND = 'CHANGE_LAUNCHER_STYLE_BACKGROUND';
export const CHANGE_LAUNCHER_STYLE_COLOR = 'CHANGE_LAUNCHER_STYLE_COLOR';
export const CHANGE_MESSAGE_SENT_STYLE_BACKGROUND = 'CHANGE_MESSAGE_SENT_STYLE_BACKGROUND';
export const CHANGE_MESSAGE_SENT_STYLE_COLOR = 'CHANGE_MESSAGE_SENT_STYLE_COLOR';
export const CHANGE_MESSAGE_RECEIVED_STYLE_BACKGROUND = 'CHANGE_MESSAGE_RECEIVED_STYLE_BACKGROUND';
export const CHANGE_MESSAGE_RECEIVED_STYLE_COLOR = 'CHANGE_MESSAGE_RECEIVED_STYLE_COLOR';
export const CHANGE_DIAL_STYLE_BACKGROUND = 'CHANGE_DIAL_STYLE_BACKGROUND';
export const CHANGE_DIAL_STYLE_COLOR = 'CHANGE_DIAL_STYLE_COLOR';
export const CHANGE_CALL_STYLE_BACKGROUND = 'CHANGE_CALL_STYLE_BACKGROUND';
