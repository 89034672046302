import actions from './types';

function changeErrorKey(errorKey) {
    return {
        type: actions.CHANGE_ERROR_KEY,
        errorKey
    };
}

export default {
    changeErrorKey
};
