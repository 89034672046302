import React, { PureComponent } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Style from '../../style.scss';

class RecordingBadge extends PureComponent {
    render() {
        const toolTipRecording = <FormattedMessage id="Call.Recording" defaultMessage="The call is recorded" />;
        return (
            <Tooltip className={Style['recording-badge']} title={toolTipRecording} placement="left">
                <FiberManualRecordIcon style={{ color: '#f20600' }} />
            </Tooltip>
        );
    }
}

RecordingBadge.propTypes = {};

RecordingBadge.defaultProps = {};

export default RecordingBadge;
