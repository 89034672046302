import { connect } from 'react-redux';
import CallComponent from './CallComponent';
import callOperations from './redux/operations';
import widgetOperations from '../../../../redux/operations';

const mapStateToProps = state => ({
    mainVideo: state.call.get('mainVideo'),
    audioMuted: state.call.get('audioMuted'),
    videoMuted: state.call.get('videoMuted'),
    webcamPublished: state.call.get('webcamPublished'),
    screenPublished: state.call.get('screenPublished'),
    extensionUrl: state.call.get('extensionUrl'),
    showExtensionAlert: state.call.get('extensionAlert'),
    localUser: state.behavior.get('localUser'),
    recording: state.behavior.get('recording'),
    channels: state.channels,
    notification: state.channels.filter(ch => ch.get('unreadMessages') > 0).size,
    mediaDevices: state.call.get('mediaDevices'),
    selectedAudioDevice: state.call.get('selectedAudioDevice'),
    selectedVideoDevice: state.call.get('selectedVideoDevice'),
    cameraPermissionDenied: state.call.get('cameraPermissionDenied'),
    microphonePermissionDenied: state.call.get('microphonePermissionDenied'),
    mediaStream: state.call.get('mediaStream'),
    streamEnded: state.call.get('streamEnded'),
    streamFailed: state.call.get('streamFailed'),
    recordingError: state.call.get('recordingError'),
    reconnectingCall: state.call.get('reconnectingCall'),
    error: state.call.get('error'),
    verification: state.call.get('verification'),
    remoteMuted: state.call.get('remoteMuted'),
    filesToDownload: state.call.get('filesToDownload'),
    filesUploaded: state.call.get('filesUploaded'),
    recordingInfo: state.call.get('recordingInfo'),
    isAdmin: state.call.get('isAdmin'),
    callTools: state.behavior.get('callTools'),
    chat: state.behavior.get('chat'),
    showRequestPlayPermission: state.call.get('showRequestPlayPermission'),
    failedMediaElements: state.call.get('failedMediaElements'),
    showMuteByAdmin: state.call.get('showMuteByAdmin')
});

const mapDispatchToProps = dispatch => ({
    changeView: view => dispatch(widgetOperations.changeView(view)),
    publishStream: call => dispatch(callOperations.publishWebcamStream(call)),
    toggleAudio: call => dispatch(callOperations.togglePublisherAudio(call)),
    toggleVideo: call => dispatch(callOperations.togglePublisherVideo(call)),
    disconnectCall: callAlias => dispatch(callOperations.disconnectTheCall(callAlias)),
    publishScreen: (call, fps) => dispatch(callOperations.publishScreenStream(call, fps)),
    unpublishScreen: call => dispatch(callOperations.unpublishScreenStream(call)),
    closeAlert: () => dispatch(callOperations.showExtensionAlert(false)),
    upgradeToPublisherWebcam: call => dispatch(callOperations.upgradeToPublisherWebcam(call)),
    requestPermissionToUpgradeVideo: call => dispatch(callOperations.requestPermissionToUpgradeVideo(call)),
    getMediaDevices: request => dispatch(callOperations.getEnumerateDevices(request)),
    selectAudioDevice: audioDevice => dispatch(callOperations.selectAudioDevice(audioDevice)),
    selectVideoDevice: videoDevice => dispatch(callOperations.selectVideoDevice(videoDevice)),
    changeDeviceSource: (callAlias, audioDevice, videoDevice) => dispatch(callOperations.changeDeviceSource(callAlias, audioDevice, videoDevice)),
    setCameraPermissionDenied: value => dispatch(callOperations.setCameraPermissionDenied(value)),
    setMicrophonePermissionDenied: value => dispatch(callOperations.setMicrophonePermissionDenied(value)),
    setStreamEndedError: value => dispatch(callOperations.setStreamEndedError(value)),
    setStreamFailedError: value => dispatch(callOperations.setStreamFailedError(value)),
    addFileUploaded: file => dispatch(callOperations.setFileUploaded(file)),
    updateDownloadFile: file => dispatch(callOperations.updateDownloadFile(file)),
    removeFileUploaded: fileName => dispatch(callOperations.removeFileUploaded(fileName)),
    handleManualRecording: recording => dispatch(callOperations.handleManualRecording(recording)),
    setRequestPlayPermission: show => dispatch(callOperations.showRequestPlayPermission(show)),
    setShowMuteByAdmin: show => dispatch(callOperations.showMuteByAdmin(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(CallComponent);
