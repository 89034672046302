import Logger from '../../../logger';
import store from '../../../store/store';
import { CALL, CALL_WINDOW, MESSAGE_RECEIVED, MESSAGE_SENT } from '../../../constants';
import {
    addResponseMessage,
    addUserMessage, emptyMessages, setUnreadMessagesForChannel,
    updateChannelLastMessage,
    updateMessageReceived, updateMessageSent, updateUnreadMessagesForChannel
} from '../../../store/actions/dispatcher';
import BandyerChat from '../client';


let instance;
const events = require('events');

class MessageManager extends events {
    constructor() {
        if (instance) {
            return instance;
        }
        super();
        instance = this;
        this._client = BandyerChat.getInstance();
        this._channels = new Map();
        this._L = Logger.scope('BandyerChat - MessageManager');
    }

    async handleMessageAdded(channel, message) {
        const { behavior } = store.getState();
        const view = behavior.get('view');
        if (message.who === this._client.localUserAlias) {
            this.dispatchMessageSent(channel.uniqueName, message);
            return;
        }

        const { selectedChannel } = this._client._channelsManager;

        if (selectedChannel && selectedChannel.uniqueName === channel.uniqueName && view !== CALL && view !== CALL_WINDOW) {
            // set unread messages to 0
            await channel.setAllMessagesConsumed();
        }

        // dispatch all message read to channel
        const unreadMessages = await channel.getUnconsumedMessageCount();
        updateUnreadMessagesForChannel(channel.uniqueName, unreadMessages);
        setUnreadMessagesForChannel(channel.uniqueName, unreadMessages);


        this.dispatchMessageReceived(channel.uniqueName, message);
    }

    dispatchMessageReceived(channelUniqueName, message) {
        const messageToEmit = message.toObj();
        addResponseMessage(channelUniqueName, messageToEmit);
        updateMessageReceived(messageToEmit);
        updateChannelLastMessage(channelUniqueName, messageToEmit);

        this._client.emit(MESSAGE_RECEIVED, { channelUniqueName, messageId: messageToEmit.messageId });
    }

    dispatchMessageSent(channelUniqueName, message) {
        const messageToEmit = message.toObj();
        addUserMessage(channelUniqueName, messageToEmit);
        updateChannelLastMessage(channelUniqueName, messageToEmit);
        updateMessageSent(messageToEmit);
        this._client.emit(MESSAGE_SENT, { channelUniqueName, messageId: messageToEmit.messageId });
    }


    static destroy() {
        emptyMessages();
        instance = null;
    }
}


export default MessageManager;
