import { MESSAGE_SENDER } from '../../../constants';
import BandyerChat from '../client';
import Logger from '../../../logger';

class Message {
    constructor(messageData) {
        this._L = Logger.scope('BandyerChat - message');
        this.messageId = messageData.messageId;
        this.message = messageData.messageBody;
        this.timestamp = messageData.createdAt.getTime();
        this.who = messageData.senderId;
        this.type = 'text';
        if (messageData.senderId === BandyerChat.getInstance()._currentuser) {
            this.sender = MESSAGE_SENDER.CLIENT;
        } else if (messageData.senderId === 'System') {
            this.sender = MESSAGE_SENDER.SYSTEM;
        } else {
            this.sender = MESSAGE_SENDER.RESPONSE;
        }
    }

    toObj() {
        return {
            messageId: this.messageId,
            message: this.message,
            timestamp: this.timestamp,
            who: this.who,
            type: this.type,
            sender: this.sender
        };
    }
}

export default Message;
