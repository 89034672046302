import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
import Widget from '../widget/WidgetContainer';

import localeData from '../../assets/locales/lang.json';

/* const language = (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.userLanguage;
// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]; */

addLocaleData([...en, ...it]);
function mapStateToProps(state) {
    const language = state.behavior.get('language');
    return { language };
}

/* function BandyerChatWidget() {
    return <IntlProvider messages={messages}><Widget /></IntlProvider>;
} */

class BandyerChatWidget extends React.Component {
    render() {
        const { language } = this.props;
        const messages = localeData[language] || localeData.en;
        return (
            <IntlProvider messages={messages} key={language} locale={language}>
                <Widget />
            </IntlProvider>
        );
    }
}

export default connect(mapStateToProps)(BandyerChatWidget);
