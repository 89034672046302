import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Style from './style.scss';
import { Loader } from 'react-loaders';

class ChatLoader extends PureComponent {
    render() {
        return (
            <div className={Style['chat-loader']}>
                <Loader type="ball-beat" active={this.props.isLoading} />
            </div>
        );
    }
}

ChatLoader.propTypes = { isLoading: PropTypes.bool };

ChatLoader.defaultProps = { isLoading: false };

export default ChatLoader;
