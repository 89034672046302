import { VideoUtil } from '@bandyer/web-core-av';
import * as constants from '../../constants';
import Logger from '../../logger';
import store from '../../store/store';
import Style from '../../components/widget/components/main/components/call/style.scss';
import * as dispatcher from '../../store/actions/dispatcher';

class AttachStream {
    constructor() {
        this._L = Logger.scope('Call - AttachStream');
        this._mainVideoContainer = Style['main-video-container'];
        this._carouselVideoContainer = Style['carousel-video-container'];
        this._callContainer = Style['call-container'];
        // how to clean up this variables after room disconnection?
        this._streams = {
            localWebcam: null,
            localScreen: null,
            remoteWebcam: null,
            remoteScreen: null
        };
    }

    /**
     * This fn handle the video added event. There are different cases:
     * - local-webcam: the main container is empty, I append the video in it. Otherwise, I append to carousel container
     * - local-ss: append the video everytime in carousel video.
     * - remote-webcam: append the video in main container if there is not the remote SS. Otherwise it goes to carousel.
     * If the main container contains a video, it moves this video to carousel.
     * - remote-ss: append the video everytime in main container and moves other to carousel.
     * @param stream
     * @param action
     */
    async handleVideoAdded(stream, action) {
        // todo usare le utility di attach stream del core-av
        const state = store.getState();
        const usersDetails = state.usersDetails.get('usersDetails');
        const { userAlias } = stream.getAttributes();
        const customImage = usersDetails.has(userAlias) ? usersDetails.get(userAlias).get('image') : '';
        let mediaElements;
        switch (action) {
            case constants.LOCAL_WEBCAM:
                this._L.debug(`[handleVideoAdded] - ${constants.LOCAL_WEBCAM}`);
                this._streams.localWebcam = stream;
                if (!AttachStream.hasChildren(this._mainVideoContainer)) {
                    mediaElements = VideoUtil.createVideoTag(
                        stream,
                        this._mainVideoContainer,
                        Style[constants.LOCAL_WEBCAM],
                        true,
                        customImage
                    );
                } else {
                    mediaElements = VideoUtil.createVideoTag(
                        stream,
                        this._carouselVideoContainer,
                        Style[constants.LOCAL_WEBCAM],
                        true,
                        customImage
                    );
                }
                break;
            case constants.LOCAL_SS:
                // aggiunto un local ss, dove lo metto? Devo controllare in che stato siamo.
                // lo metto sempre nel carousel
                this._L.debug(`[handleVideoAdded] - ${constants.LOCAL_SS}`);
                this._streams.localScreen = stream;
                mediaElements = VideoUtil.createVideoTag(
                    stream,
                    this._carouselVideoContainer,
                    Style[constants.LOCAL_SS],
                    true,
                    customImage
                );
                break;
            case constants.REMOTE_WEBCAM:
                // aggiunta remote webcam, deve andare nel main video, solo se il main video
                // non ha un remote ss.
                this._L.debug(`[handleVideoAdded] - ${constants.REMOTE_WEBCAM}`);
                this._streams.remoteWebcam = stream;
                if (!this.isVideoInMainVideoContainer(Style[constants.REMOTE_SS])) {
                    // se c'erano video qui dentro devo toglierli e metterli su carousel
                    if (AttachStream.hasChildren(this._mainVideoContainer)) {
                        this.moveElements(this._mainVideoContainer, this._carouselVideoContainer);
                    }
                    mediaElements = VideoUtil.createVideoTag(
                        stream,
                        this._mainVideoContainer,
                        Style[constants.REMOTE_WEBCAM],
                        false,
                        customImage
                    );
                } else {
                    mediaElements = VideoUtil.createVideoTag(
                        stream,
                        this._carouselVideoContainer,
                        Style[constants.REMOTE_WEBCAM],
                        false,
                        customImage
                    );
                }
                break;
            case constants.REMOTE_SS:
                this._L.debug(`[handleVideoAdded] - ${constants.REMOTE_SS}`);
                this._streams.remoteScreen = stream;
                if (AttachStream.hasChildren(this._mainVideoContainer)) {
                    this.moveElements(this._mainVideoContainer, this._carouselVideoContainer);
                }
                mediaElements = VideoUtil.createVideoTag(
                    stream,
                    this._mainVideoContainer,
                    Style[constants.REMOTE_SS],
                    true,
                    customImage
                );
                break;
            default:
        }
        // need await because the createVideoTag is a promise, use await here to avoid the use many times
        const { audio, video } = await mediaElements;
        if (audio) {
            this._checkPlayPermission(audio);
        }
        if (video) {
            this._checkPlayPermission(video);
        }
    }

    /**
     * First of all, it removes the video from DOM. After that, if the main container is empty,
     * some video needs to go into it. If there is a remote webcam, the remote webcam goes into main container.
     * If there is not, the local webcam goes to main container.
     * @param stream
     */
    handleVideoRemoved(stream) {
        const state = store.getState();
        const usersDetails = state.usersDetails.get('usersDetails');
        this._L.debug('[handleVideoRemoved] - stream ID:', stream.getID());
        this.removeVideo(stream.getID());
        const carouselEl = document.getElementById(this._carouselVideoContainer);
        if (!AttachStream.hasChildren(this._mainVideoContainer) && carouselEl) {
            const videoInCarousel = carouselEl.children;
            for (let i = 0; i < videoInCarousel.length; i++) {
                if (videoInCarousel[i].classList.contains(Style[constants.REMOTE_WEBCAM])) {
                    const { userAlias } = this._streams.remoteWebcam.getAttributes();
                    const customImage = usersDetails.has(userAlias) ? usersDetails.get(userAlias).get('image') : '';
                    this.moveVideoToDiv(this._mainVideoContainer, Style[constants.REMOTE_WEBCAM], customImage);
                    return constants.REMOTE_WEBCAM;
                }
            }
            for (let i = 0; i < videoInCarousel.length; i++) {
                if (videoInCarousel[i].classList.contains(Style[constants.LOCAL_WEBCAM])) {
                    const { userAlias } = this._streams.localWebcam.getAttributes();
                    const customImage = usersDetails.has(userAlias) ? usersDetails.get(userAlias).get('image') : '';
                    this.moveVideoToDiv(this._mainVideoContainer, Style[constants.LOCAL_WEBCAM], customImage);
                    return constants.LOCAL_WEBCAM;
                }
            }
        }
    }

    /**
     * The remove function find the element and removes it from DOM
     * @param id
     */
    removeVideo(id) {
        const video = document.getElementById(id);
        if (video) {
            video.remove();
        }
    }

    /**
     * Creates the div to append if the user has no video
     * @param userObj
     * @param shape of the img to append, valid values are "rounded" or "rectangle"
     * @returns {Element}
     * @private
     */
    // static _profileImg(userInfoObj = {}) {
    //     const divContainer = document.createElement('div');
    //     const img = document.createElement('img');
    //     const userImage = userInfoObj.image ? `${constants.BASE_URL_AVATAR}${userInfoObj.image}` : `${constants.BASE_URL_AVATAR}profile.jpg`;
    //     img.src = userImage;
    //     img.className = 'profile-img-call rounded';
    //     divContainer.className = 'user-no-video';
    //     divContainer.appendChild(img);
    //
    //     return divContainer;
    // }

    // static retrieveUserInfo(userObj) {
    //     let toReturn = 'Guest';
    //     if (userObj.firstName && userObj.lastName) {
    //         toReturn = `${userObj.firstName} ${userObj.lastName}`;
    //     } else if (userObj.email) {
    //         toReturn = `${userObj.email}`;
    //     }
    //     return toReturn;
    // }

    static hasChildren(id) {
        const el = document.getElementById(id);
        if (el) {
            return el.children.length > 0;
        }
        return false;
    }

    /**
     * This fn check if a specified video is in the main container. It returns true if the video is a child of
     * main container, false otherwise.
     * @param className
     * @returns {boolean}
     */
    isVideoInMainVideoContainer(className) {
        const mainVideoChildren = document.getElementById(this._mainVideoContainer).children;
        let toReturn = false;
        for (let i = 0; i < mainVideoChildren.length; i++) {
            if (mainVideoChildren[i].classList.contains(Style[className])) {
                toReturn = true;
            }
        }
        return toReturn;
    }

    /**
     * This fn moves the specified video into the destinationDivId. It creates the video using the function
     * _createVideoTag, after that it removes the old one from the DOM.
     * @param destinationDivId
     * @param videoName
     */
    moveVideoToDiv(destinationDivId, videoName, customImage) {
        const parentEl = document.getElementById(destinationDivId);
        const videoEl = document.getElementsByClassName(videoName)[0];
        if (parentEl && videoEl) {
            const videoData = this.whatVideoIs(videoEl);
            videoEl.remove();
            const muteVideo = videoData.className !== constants.REMOTE_WEBCAM;
            VideoUtil.createVideoTag(
                videoData.stream,
                destinationDivId,
                Style[videoData.className],
                muteVideo,
                customImage
            );
            // this._createVideoTag(videoData.stream, destinationDivId, videoData.className);
        }
    }

    /**
     * This fn given the oldParentId and newParentId, it moves the videoTag from the old to the new.
     * @param oldParentId
     * @param newParentId
     */
    moveElements(oldParentId, newParentId) {
        const newParentEl = document.getElementById(newParentId);
        const oldParentEl = document.getElementById(oldParentId);
        if (oldParentEl && newParentEl) {
            while (oldParentEl.firstChild) {
                newParentEl.prepend(oldParentEl.firstChild);
                // Old logic to handle stream move
                /*
                const videoData = this.whatVideoIs(oldParentEl.firstChild);
                oldParentEl.firstChild.remove();
                const muteVideo = videoData.className !== constants.REMOTE_WEBCAM;
                //VideoUtil.createVideoTag(videoData.stream, newParentId, videoData.className, muteVideo);
                // this._createVideoTag(videoData.stream, newParentId, videoData.className, muteVideo); */
            }
        }
    }

    /**
     * Given a className it returns the specified stream and classname.
     * @param videoEl
     */
    whatVideoIs(videoEl) {
        const { className } = videoEl;
        let toReturn = {};
        if (className.indexOf(Style[constants.REMOTE_SS]) !== -1) {
            toReturn = {
                stream: this._streams.remoteScreen,
                className: constants.REMOTE_SS
            };
        } else if (className.indexOf(Style[constants.REMOTE_WEBCAM]) !== -1) {
            toReturn = {
                stream: this._streams.remoteWebcam,
                className: constants.REMOTE_WEBCAM
            };
        } else if (className.indexOf(Style[constants.LOCAL_SS]) !== -1) {
            toReturn = {
                stream: this._streams.localScreen,
                className: constants.LOCAL_SS
            };
        } else if (className.indexOf(Style[constants.LOCAL_WEBCAM]) !== -1) {
            toReturn = {
                stream: this._streams.localWebcam,
                className: constants.LOCAL_WEBCAM
            };
        }
        return toReturn;
    }

    _checkPlayPermission(htmlMediaObject) {
        if ('onloadedmetadata' in HTMLVideoElement.prototype) {
            htmlMediaObject.addEventListener('loadedmetadata', () => {
                const state = store.getState();
                const { call } = state;
                const showRequestPlayPermission = call.get('showRequestPlayPermission');
                if (htmlMediaObject.paused && !showRequestPlayPermission) {
                    // the autoplay property on video element sometimes didn't work probably due to new chrome autoplay policy
                    htmlMediaObject.play().catch(err => {
                        this._L.error('Cannot autoplay audio/video, asking permissions:', err);
                        // the video cannot be played until there is an interaction with the page
                        // fire the event that tells to the delegated component to show a dialog
                        dispatcher.showRequestPlayPermission(true);
                        dispatcher.pushFailedMediaElement(htmlMediaObject);
                    });
                }
            });
            htmlMediaObject.addEventListener('pause', () => {
                const state = store.getState();
                const { call } = state;
                const showRequestPlayPermission = call.get('showRequestPlayPermission');
                if (!showRequestPlayPermission) {
                    htmlMediaObject.play().catch(err => {
                        this._L.error('Cannot autoplay audio/video, asking permissions:', err);
                        // the video cannot be played until there is an interaction with the page
                        // fire the event that tells to the delegated component to show a dialog
                        dispatcher.showRequestPlayPermission(true);
                        dispatcher.pushFailedMediaElement(htmlMediaObject);
                    });
                }
            });
        }
    }

    get mainVideoContainer() {
        return this._mainVideoContainer;
    }

    get carouselVideoContainer() {
        return this._carouselVideoContainer;
    }
}

export default AttachStream;
