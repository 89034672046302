import Logger from '../../../logger';
import {
    emptyChannels,
    handleChannelAdded as dispatchChannelAdded,
    handleChannelRemoved as dispatchChannelRemoved, handleChanneSelectedAfterRemove, setHaveChat
} from '../../../store/actions/dispatcher';
import store from '../../../store/store';
import Channel from '../data/channel';
import Message from '../data/message';
import MessageManager from './messageManager';
import BandyerChat from '../client';

const FORWARD_FETCH = 30;
let instance;
const events = require('events');

class ChannelsManager extends events {
    constructor() {
        if (instance) {
            return instance;
        }
        super();
        instance = this;
        this._client = BandyerChat.getInstance();
        this._channelsByUniqueName = new Map();
        this._channelsByChannelId = new Map();
        this._messageManager = new MessageManager();
        this.selectedChannel = null;
        this._L = Logger.scope('BandyerChat - ChannelManager');
    }

    static filterChannelStructureForEvent({ uniqueName, participants, lastMessage, unreadMessages, dateCreated, createdBy }) {
        return {
            /* type, */
            chat: uniqueName,
            participants: [participants],
            lastMessage,
            unreadMessages,
            dateCreated,
            createdBy
        };
    }

    async handleChannelAdded(channel) {
        try {
            let subscribedChannel = new Channel();
            subscribedChannel = await subscribedChannel.init(channel);
            this._channelsByUniqueName.set(subscribedChannel.uniqueName, subscribedChannel);
            this._channelsByChannelId.set(subscribedChannel.channelId, subscribedChannel);
            if (this._channelsByChannelId.size === 1) {
                clearTimeout(this._loadingTimeout);
                setHaveChat(true);
            }
            const isNew = subscribedChannel.dateCreated > this._client._dateCreated;
            dispatchChannelAdded(subscribedChannel.toObj(), isNew);
            return subscribedChannel;
        } catch (e) {
            switch (e.message) {
                case 'noMember':
                    this._L.warn('Invalid channel added - The channel has no member');
                    break;
                default:
                    this._L.warn('Invalid channel added', e.message);
            }
            throw e;
        }
    }

    handleChannelRemoved(channelId) {
        this._L.debug('_handleChannelRemoved', channelId);
        const channel = this._channelsByChannelId.get(channelId);
        if (channel) {
            const { uniqueName } = channel;
            dispatchChannelRemoved(uniqueName);
            handleChanneSelectedAfterRemove(uniqueName);
            this._channelsByChannelId.delete(channelId);
            this._channelsByUniqueName.delete(uniqueName);
            if (this._channelsByChannelId.size === 0) {
                setHaveChat(false);
            }
            return { channelId, participants: channel.participants.map(u => u.userAlias) };
        }
        return {};
    }

    async removeChannel(uniqueName) {
        this._L.debug('removeChannel', uniqueName, this._channelsByUniqueName.get(uniqueName));
        const channel = this._channelsByUniqueName.get(uniqueName);
        return this._client._bandyerChatSdk.deleteChannel(channel.channelId);
    }


    async selectCurrentChannel(uniqueName = null) {
        this._L.debug('[selectCurrentChannel] - uniqueName', uniqueName);
        if (!uniqueName) {
            this._L.warn('[selectCurrentChannel] - No unique name specified');
            throw new Error('[selectCurrentChannel] - No unique name specified');
        }
        const channel = this._channelsByUniqueName.get(uniqueName);
        this._L.debug(channel);
        if (channel) {
            const state = store.getState();

            if (!state.messages.has(uniqueName) && channel.lastMessage) {
                const { localUserAlias } = this._client;
                const lastReadMessageId = channel.lastReadUserMessage.get(localUserAlias);
                const fetchUnreadPromises = [];

                const fetchUnread = async(messageId) => {
                    const { anchor } = await channel.getMessages(FORWARD_FETCH, messageId, 'forward');
                    if (anchor) {
                        await fetchUnread(anchor);
                    }
                };
                fetchUnreadPromises.push(fetchUnread(lastReadMessageId));
                fetchUnreadPromises.push(channel.getMessages(20, lastReadMessageId));
                await Promise.all(fetchUnreadPromises);
            }

            await channel.setAllMessagesConsumed();
            this.selectedChannel = channel;
            return channel.toObj();
        }
        this._L.warn("[selectCurrentChannel] - Channel doesn't exist");
        return Promise.reject({ name: 'channel_not_found' });
    }

    async sendMessage(uniqueName, message, options) {
        const channel = this._channelsByUniqueName.get(uniqueName);
        if (channel) {
            await channel.setAllMessagesConsumed();
            return channel.sendMessage(message);
        }
        return Promise.reject({ name: 'channel_not_found' });
    }

    handleMessageAdded(channelId, messageData) {
        const channel = this._channelsByChannelId.get(channelId);
        const message = new Message(messageData);
        channel.addMessage(message);
        channel.disableTyping(messageData.sender);
        this._messageManager.handleMessageAdded(channel, message);
        return message;
    }

    fetchMessage(uniqueName, anchor, direction = 'backwards') {
        this._L.debug('[fetchMessage] uniqueName - ', uniqueName, ' - anchor -', anchor);
        const channel = this._channelsByUniqueName.get(uniqueName);
        if (channel) {
            return channel.getMessages(20, anchor, direction);
        }
        return [];
    }

    handleTyping(channelId, userId) {
        const channel = this._channelsByChannelId.get(channelId);
        if (channel) {
            channel.handleTyping(userId);
        }
    }

    isTyping(uniqueName) {
        const channel = this._channelsByUniqueName.get(uniqueName);
        if (channel) {
            channel.typing();
        }
    }

    static destroy() {
        MessageManager.destroy();
        emptyChannels();
        instance = null;
    }

    get channelsByChannelId() {
        return this._channelsByChannelId;
    }

    get channelsByUniqueName() {
        return this._channelsByUniqueName;
    }
}

export default ChannelsManager;
