import { FormattedMessage } from 'react-intl';
import React from 'react';

export const gearHeader = <FormattedMessage id="Call.ToolTipGear" defaultMessage="Settings" />;
export const cameraHeader = <FormattedMessage id="Gear.CameraHeader" defaultMessage="Camera" />;
export const microphoneHeader = <FormattedMessage id="Gear.MicrophoneHeader" defaultMessage="Microphone" />;
export const recordingHeader = <FormattedMessage id="Gear.RecordingHeader" defaultMessage="Recoridng" />;
export const virtualBackgroundHeader = (
    <FormattedMessage id="Gear.VirtualBackground" defaultMessage="Virtual background" />
);
export const blurHeader = <FormattedMessage id="Gear.Blur" defaultMessage="Blur" />;
export const noCamera = <FormattedMessage id="Gear.NoCamera" defaultMessage="No Camera" />;
export const noMicrophone = <FormattedMessage id="Gear.NoMicrophone" defaultMessage="No Microphone" />;
export const unknownCamera = <FormattedMessage id="Gear.UnknownCamera" defaultMessage="Unknown Camera" />;
export const unknownMicrophone = <FormattedMessage id="Gear.UnknownMicrophone" defaultMessage="Unknown Microphone" />;
export const noCameraPermission = (
    <FormattedMessage id="Gear.NoCameraPermission" defaultMessage="You don't have camera permission" />
);
export const allowCamera = <FormattedMessage id="Gear.AllowCamera" defaultMessage="Allow" />;
export const noMicrophonePermission = (
    <FormattedMessage id="Gear.NoMicrophonePermission" defaultMessage="You don't have microphone permission" />
);
export const allowMicrophone = <FormattedMessage id="Gear.AllowMicrophone" defaultMessage="Allow" />;
export const noVideo = <FormattedMessage id="Gear.NoVideo" defaultMessage="No video found" />;
export const closeButton = <FormattedMessage id="Dialog.CloseButton" defaultMessage="Close" />;

export const toolTipRecordingOn = (
    <FormattedMessage id="Gear.ToolTipRecordingOn" defaultMessage="Call recording in progress" />
);
export const toolTipRecordingOff = (
    <FormattedMessage id="Gear.ToolTipRecordingOff" defaultMessage="Click to start recording" />
);
