import * as CommunicationCenter from '@bandyer/web-communication-center';
import { BrowserPlugin } from '@bandyer/web-core-av';
import { constants, BandyerEnum } from 'bandyersdkcommon';
import { fromJS } from 'immutable';
import {
    ACTION_ALREADY_TAKEN, CALL_WINDOW_HANGUP_ACTION, INACTIVE_USER, INACTIVE_USER_TOKEN_EXPIRED,
    JOIN_CALL_INVALID_MTM,
    ON_CALL_INCOMING,
    WIDGET_MODE_WINDOW
} from '../../constants';
import { buildChannelUniqueName, buildDeclineReason, buildHangupReason } from '../../helpers/utils';
import {
    changeViewToInactiveUser, changeViewToTerms, resetCall,
    setUserStatusInChannel,
    updateCommunicationCenterState, updateParticipantsStateInRoom, updateUserStatus
} from '../../store/actions/dispatcher';
import * as dispatcher from '../../store/actions/dispatcher';
import CallWindow from '../call/callWindow';
import Logger from '../../logger';
import store from '../../store/store';
import UserDetailsService from '../userDetails';

const events = require('events');
const { name: packageName, version: packageVersion } = require('../../../package.json');

let instance = null;
export default class SwitchboardService extends events {
    constructor(region, environment, appId) {
        if (instance) {
            return instance;
        }
        super();
        this._L = Logger.scope('BandyerChat - switchboardService');
        this._maxParticipants = 2;
        this._userId = null;
        this._communicationCenter = CommunicationCenter.initialize(region, environment, appId, {
            clientName: packageName,
            clientVersion: packageVersion
        });
        this._attachListener();

        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'demo') {
            window.communicationCenter = this._communicationCenter;
        }
        const { behavior } = store.getState();
        const language = behavior.get('language');
        this._callWindow = new CallWindow(this._communicationCenter, region, environment, language);

        this._callWindow.on(CALL_WINDOW_HANGUP_ACTION, ({ call }) => {
            const { onGoingCall } = this._communicationCenter;
            if (onGoingCall && onGoingCall.callAlias && onGoingCall.callStatus === BandyerEnum.CallStatus.DIALING) {
                dispatcher.hangUpCall(onGoingCall.callAlias);
            } else {
                dispatcher.handleStoppedCall(call);
            }
        });
        this._callWindow.on('call_started_window', ({ call }) => {
            this.emit('call_status_changed', { status: call.callStatus });
        });
        instance = this;
    }

    static destroy() {
        CommunicationCenter.shutdown();
        instance = null;
    }

    updateAccessToken(accessToken) {
        return this._communicationCenter.updateAccessToken(accessToken);
    }

    getChatToken() {
        return this._communicationCenter.getChatToken();
    }

    _attachListener() {
        this._communicationCenter.on('user:update', (user) => {
            if (user) {
                if (this._userId !== user.userAlias) {
                    const buildUniqueName = buildChannelUniqueName([this._userId, user.userAlias]);
                    const status = user.presence ? 'online' : 'offline';
                    const { channels } = store.getState();
                    const channel = channels.find(ch => ch.get('uniqueName') === buildUniqueName);
                    if (channel && channel.get('status') !== status) {
                        setUserStatusInChannel(buildUniqueName, status);
                        updateUserStatus(user.userAlias, status);
                        this.emit('user_status_update', {
                            userId: user.userAlias,
                            status
                        });
                    }
                }
                UserDetailsService.getInstance().provideDetails([fromJS(user.toObj())], true);
            }
        });

        this._communicationCenter.on(ON_CALL_INCOMING, data => this._handleOnCallIncoming(data));

        this._communicationCenter.on('switchboard:access_token:is_about_to_expire', (data) => {
            this.emit('access_token_is_about_to_expire', data);
        });

        this._communicationCenter.on('switchboard:access_token:expired', async(data) => {
            this._L.error('[token_expired] - automatically logout');
            this.emit('access_token_expired', data);
            changeViewToInactiveUser(INACTIVE_USER_TOKEN_EXPIRED);
        });

        this._communicationCenter.on('connect_error', (data /* { message: string; data: { code: string; message: string } } */) => {
            if (data.message === 'access_token_expired') {
                this._L.error('[token_expired] - automatically logout');
                this.emit('access_token_expired', data);
                changeViewToInactiveUser(INACTIVE_USER_TOKEN_EXPIRED);
            } else if (data.message === 'terms_required' && data.data && data.data.terms) {
                changeViewToTerms(data.data.terms);
            } else {
                const { behavior } = store.getState();
                const call = behavior.get('call');
                updateCommunicationCenterState(constants.COMMUNICATION_CENTER_DISCONNECTED); // update state
                // check if there is an active call and his status
                if (call) {
                    if (
                        call.get('callStatus') === BandyerEnum.CallStatus.DIALING
                        || call.get('callStatus') === BandyerEnum.CallStatus.RINGING
                    ) {
                        resetCall();
                    }
                }
            }
        });


        this._communicationCenter.on(constants.SDK_SOCKET_CONNECTION_DISCONNECTED, () => {
            const { behavior } = store.getState();
            const call = behavior.get('call');
            updateCommunicationCenterState(constants.COMMUNICATION_CENTER_DISCONNECTED); // update state
            // check if there is an active call and his status
            if (call) {
                if (
                    call.get('callStatus') === BandyerEnum.CallStatus.DIALING
                    || call.get('callStatus') === BandyerEnum.CallStatus.RINGING
                ) {
                    resetCall();
                }
            }
        });
        this._communicationCenter.on(constants.SDK_SOCKET_CONNECTION_RECONNECTING, () => {
            const { behavior } = store.getState();
            const call = behavior.get('call');
            updateCommunicationCenterState(constants.COMMUNICATION_CENTER_CONNECTING); // update state
            if (call) {
                if (
                    call.get('callStatus') === BandyerEnum.CallStatus.DIALING
                    || call.get('callStatus') === BandyerEnum.CallStatus.RINGING
                ) {
                    resetCall();
                }
            }
        });
        this._communicationCenter.on(constants.SDK_SOCKET_CONNECTION_RECONNECT, () => {
            const { behavior } = store.getState();
            const call = behavior.get('call');
            updateCommunicationCenterState(constants.COMMUNICATION_CENTER_CONNECTED); // update state
            if (call) {
                if (!(call.get('callStatus') === BandyerEnum.CallStatus.CONNECTED)) {
                    resetCall();
                }
            } else {
                resetCall();
            }
        });
        this._communicationCenter.on('switchboard:user:force_disconnect', async(data) => {
            Logger.warn('[force_disconnect] - automatically logout - reason', data);
            this.emit('user_force_disconnect', data);
            changeViewToInactiveUser(INACTIVE_USER);
        });

        this._communicationCenter.on('room:participant:update', (data) => {
            const { behavior } = store.getState();
            const call = behavior.get('call');

            const { userAlias, state, roomAlias } = data;
            if (call.get('callAlias') === roomAlias) {
                updateParticipantsStateInRoom(userAlias, state);
            }
        });
    }


    async connect(userId, accessToken, terms = null) {
        this._userId = userId;
        const { behavior } = store.getState();
        const locale = behavior.get('language');
        const UserInfo = await this._communicationCenter.connect(userId, accessToken, { terms, locale });
        updateCommunicationCenterState(constants.COMMUNICATION_CENTER_CONNECTED); // update state
        if (UserDetailsService.getInstance().provideDetails) {
            UserDetailsService.getInstance().provideDetails([fromJS(UserInfo.toObj())], true).then(() => {
                const { usersDetails } = store.getState();
                if (usersDetails.get('usersDetails').has(userId)) {
                    const user = usersDetails.get('usersDetails').get(userId);
                    this._communicationCenter.updateUser(user.get('formattedName'), user.get('image'));
                }
            });
        }
        return UserInfo.toObj();
    }

    async disconnect() {
        return this._communicationCenter.shutdown();
    }


    async joinCallURL(url) {
        try {
            const result = dispatcher.getWidgetMode();

            const autoConnect = result !== WIDGET_MODE_WINDOW;
            const callCreated = await this._communicationCenter.joinCall(url, autoConnect);
            this._registerCallEvents(callCreated);
            if (callCreated.callParticipants.length > 2) {
                callCreated.hangUp();
                throw new Error(JOIN_CALL_INVALID_MTM);
            }
            if (result === WIDGET_MODE_WINDOW) {
                this._callWindow.startCallWindow();
            }
            const recordingInProgress = callCreated.callOptions.recording === 'automatic';
            dispatcher.updateRecordingInfo({ recordingInProgress, recording: callCreated.callOptions.recording });
            return this._buildCallData(callCreated);
        } catch (e) {
            this._L.error('[joinCallURL] - Error: ', e);
            // TODO Handle errors
            throw e;
        }
    }

    /**
     * This function start a call using the communication center api. It calls the call method of Communication center.
     * After that, it calls the register callEvents function to listen the event of call created.
     * @param callee
     * @param options
     * @returns {Promise<{callInfo: Object, callDirection: string, callStatus: any, callAlias: string | string, callInitiator: ParticipantObjectClient | string, callParticipants: ParticipantObjectClient[] | UserObjectClient[]}>}
     * @private
     */
    async _startCall(callee, options) {
        try {
            let callCreated = null;
            if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
                callCreated = await this._communicationCenter.call(callee, options, false);
                this._callWindow.startCallWindow();
            } else {
                callCreated = await this._communicationCenter.call(callee, options);
            }
            this._registerCallEvents(callCreated);
            const recordingInProgress = callCreated.callOptions.recording === 'automatic';
            dispatcher.updateRecordingInfo({ recordingInProgress, recording: callCreated.callOptions.recording });
            const call = this._buildCallData(callCreated);
            this.emit('call_created', call);
            return call;
        } catch (e) {
            this._L.error('[_startCall] error', e);
            if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
                this.closeWindowCall();
            }
            // TODO handle error
            throw e;
        }
    }

    /**
     * Interface to handle the plugin forIE
     * @param callee
     * @param options
     * @returns {Promise<void>}
     */
    async startCall(callee = [], options = {}) {
        return BrowserPlugin.handlePluginForIE(
            this._startCall.bind(this, callee, options),
            dispatcher.changeViewToIEPlugin
        );
    }

    async _answer(callAlias) {
        try {
            const currentCall = this._communicationCenter.getCall(callAlias);
            this.emit('call_status_changed', { status: BandyerEnum.CallStatus.CONNECTING });
            if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
                if (currentCall.callStatus === BandyerEnum.CallStatus.DIALING) {
                    this._callWindow.answerCallWindow(callAlias);
                    await currentCall.answer(false);
                } else {
                    this._callWindow.focusWindow();
                }
            } else {
                await currentCall.answer();
                dispatcher.changeViewToCall();
            }
            return this._buildCallData(currentCall);
        } catch (e) {
            this._L.warn('[_answer] - error:', e);
            if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
                this.closeWindowCall();
            }
            throw e;
            // TODO handle error
        }
    }

    async answer(callAlias) {
        this._L.debug('[s.answer]');
        return BrowserPlugin.handlePluginForIE(this._answer.bind(this, callAlias), dispatcher.changeViewToIEPlugin);
    }

    async decline(callAlias, reason) {
        BrowserPlugin.stopPluginRequest();
        try {
            const currentCall = this._communicationCenter.getCall(callAlias);
            await currentCall.decline(buildDeclineReason(reason));
        } catch (e) {
            this._L.warn('[decline] - Err:', e);
            // TODO handle errors
            throw e;
        }
    }

    async hangUp(callAlias, reason) {
        BrowserPlugin.stopPluginRequest();
        try {
            const formattedReason = buildHangupReason(reason);
            const currentCall = this._communicationCenter.getCall(callAlias);
            await currentCall.hangUp(formattedReason);
        } catch (e) {
            this._L.warn('[hangUp] - Err:', e);
            switch (e.name) {
                case 'CallActionAlreadyTaken':
                    throw ACTION_ALREADY_TAKEN;
                    // in this case if the error is action already taken, I just ignore the error and continue execution
                default:
            }
        } finally {
            if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
                this.closeWindowCall();
            }
        }
    }

    async getUserStatus(userAlias) {
        try {
            const user = await this._communicationCenter.getUser(userAlias);
            return {
                userAlias: user.userAlias,
                status: user.status
            };
        } catch (e) {
            throw e;
        }
    }

    async getUsersStatusList() {
        try {
            const users = await this._communicationCenter.getUsers();
            const toReturn = [];
            users.forEach((user) => {
                toReturn.push({
                    userAlias: user.userAlias,
                    status: user.status
                });
            });
            return toReturn;
        } catch (e) {
            throw e;
        }
    }


    _handleOnCallIncoming(call) {
        // handle on call incoming


        if (call.callParticipants.length > this._maxParticipants) {
            return;
        }
        this._registerCallEvents(call);
        dispatcher.handleIncomingCall(this._buildCallData(call));
        const recordingInProgress = call.callOptions.recording === 'automatic';
        dispatcher.updateRecordingInfo({ recordingInProgress, recording: call.callOptions.recording });
        this.emit(ON_CALL_INCOMING, call);
        // send a ringing feedback
        this._communicationCenter.updateStateInRoom({ roomAlias: call.callAlias, state: 'ringing' });
    }

    /**
     * If the call has been answered, the user change the view to Call View
     * @param roomEvent
     * @private
     */
    _handleStartedEvent(roomEvent) {
        const { call } = roomEvent;
        dispatcher.updateCall(this._buildCallData(call));
        if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
            dispatcher.changeViewToChannels();
        } else {
            dispatcher.changeViewToCall();
        }
    }


    _handleDialEvent(roomEvent) {
        dispatcher.handleDeclinedCall();
        if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
            this._callWindow.closeWindow();
            this._callWindow.cleanCallInterval();
        }
    }

    _handleDeleteCall(roomEvent) {
        const { call } = roomEvent;
        if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
            this._callWindow.closeWindow();
            this._callWindow.cleanCallInterval();
        }
        dispatcher.handleStoppedCall(call);
    }


    _registerCallEvents(call) {
        call.on(constants.SDK_EVENTS_CALL_DIAL_ANSWERED, (roomEvent) => {
            dispatcher.updateCall(this._buildCallData(roomEvent.call));
            this.emit('call_status_changed', { status: roomEvent.call.callStatus });
        });
        call.on(constants.SDK_EVENTS_CALL_DIAL_DECLINED, (roomEvent) => {
            if (roomEvent.call.callDirection === 'outgoing') {
                this._handleDialEvent(roomEvent);
            }
        });
        call.on(constants.SDK_EVENTS_CALL_DIAL_STOPPED, (roomEvent) => {
            if (roomEvent.call.callDirection === 'outgoing' || roomEvent.reason === 'answered_on_another_device') {
                this._handleDialEvent(roomEvent);
            }
        });
        call.on(constants.SDK_EVENTS_CALL_DELETED, (roomEvent) => {
            this._handleDeleteCall(roomEvent);
        });

        call.on('call_started', (roomEvent) => {
            this._handleStartedEvent(roomEvent);
            this.emit('call_status_changed', { status: roomEvent.call.callStatus });
        });

        call.on('call_ended', (roomEvent) => { // use instead od call deleted because if the call is running on remote we not receive a call deleted but we need to notify the client status
            this.emit('call_status_changed', { status: roomEvent.call.callStatus });
        });
    }

    _buildCallData = call => ({
        callInfo: call.callInfo,
        callDirection: call.callDirection,
        callStatus: call.callStatus,
        callAlias: call.callAlias,
        callInitiator: call.callInitiator,
        callParticipants: call.callParticipants,
        callType: call.callType,
        callOptions: call.callOptions
    })

    focusWindowCall() {
        if (this._callWindow) {
            this._callWindow.focusWindow();
        }
    }

    closeWindowCall() {
        const { onGoingCall } = this._communicationCenter;
        if (onGoingCall) {
            this._communicationCenter.getCall(onGoingCall.callAlias).disconnect();
        }
        if (this._callWindow) {
            this._callWindow.closeWindow();
        }
    }

    getCall(callAlias) {
        return this._communicationCenter.getCall(callAlias);
    }

    get communicationCenter() {
        return this._communicationCenter;
    }
}
