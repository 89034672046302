import { Map, List } from 'immutable';
import { MESSAGE_SENDER } from 'constants';

import { createNewMessage, createComponentMessage, loadMessages } from './helper';
import * as actionTypes from '../actions/actionTypes';

const sortByTimestamp = (i, j) => {
    if (i.has('timestamp') && j.has('timestamp')) {
        if (i.get('timestamp') < j.get('timestamp')) {
            return -1;
        }
        return 1;
    }
    return -1;
};

const initialState = Map({});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ADD_NEW_USER_MESSAGE: {
            if (state.has(action.uniqueName)) {
                const previousList = state.get(action.uniqueName);
                let messagesList = previousList.push(createNewMessage({
                    messageId: action.messageObj.messageId,
                    text: action.messageObj.message,
                    sender: action.messageObj.sender,
                    index: action.messageObj.index,
                    readTimestamp: action.messageObj.readTimestamp,
                    timestamp: action.messageObj.timestamp,
                    status: action.messageObj.status
                }));
                messagesList = messagesList.sort(sortByTimestamp);
                return state.set(action.uniqueName, messagesList);
            }
            return state;
        }
        case actionTypes.ADD_NEW_RESPONSE_MESSAGE: {
            if (state.has(action.uniqueName)) {
                const previousList = state.get(action.uniqueName);
                let messagesList = previousList.push(createNewMessage(
                    {
                        messageId: action.messageObj.messageId,
                        text: action.messageObj.message,
                        sender: action.messageObj.sender,
                        index: action.messageObj.index,
                        readTimestamp: action.messageObj.readTimestamp,
                        timestamp: action.messageObj.timestamp,
                        status: action.messageObj.status
                    }
                ));
                messagesList = messagesList.sort(sortByTimestamp);
                return state.set(action.uniqueName, messagesList);
            }
            return state;
        }
        case actionTypes.ADD_COMPONENT_MESSAGE: {
            const previousList = state.get(action.uniqueName);
            let messagesList = previousList.push(createComponentMessage(action.component, action.props));
            messagesList = messagesList.sort(sortByTimestamp);
            return state.set(action.uniqueName, messagesList);
        }
        case actionTypes.SET_MESSAGE: {
            let messagesList = List([]);
            if (!state.has(action.uniqueName)) {
                messagesList = loadMessages(action.messages);
            } else {
                const previousList = state.get(action.uniqueName);
                messagesList = loadMessages(action.messages).concat(previousList);
            }
            messagesList = messagesList.sort(sortByTimestamp);
            return state.set(action.uniqueName, messagesList);
        }
        case actionTypes.EMPTY_MESSAGE: {
            return Map({});
        }
        case actionTypes.REMOVE_CHAT: {
            const nextState = state.remove(action.uniqueName);
            return nextState;
        }
        default:
            return state;
    }
}
