import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ArrowBack from '@material-ui/icons/ArrowBack';
import VideoCall from '@material-ui/icons/VideoCall';
import Call from '@material-ui/icons/Call';
import IconButton from '@material-ui/core/IconButton';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage } from 'react-intl';
import { constants } from 'bandyersdkcommon';
import { connect } from 'react-redux';
import {
    CALL_TYPE_AUDIO_UPGRADABLE,
    CALL_TYPE_AUDIO_VIDEO,
    CALL_TYPE_AUDIO_ONLY,
    CALL,
    WIDGET_MODE_EMBED,
    CALL_WINDOW
} from '../../../../../../../../constants';
import widgetOperations from '../../../../../../redux/operations';
import Style from './style.scss';
import { buildLastMessage } from '../../../../../../../../helpers/utils';
import { updateUnreadMessagesForChannel } from '../../../../../../../../store/actions/dispatcher';

const ToolTipAudioOnly = <FormattedMessage id="Channels.ToolTipAudioOnly" defaultMessage="Audio only" />;
const ToolTipAudioUpgradable = (
    <FormattedMessage id="Channels.ToolTipAudioUpgradable" defaultMessage="Audio upgradable" />
);
const ToolTipAudioVideo = <FormattedMessage id="Channels.ToolTipAudioVideo" defaultMessage="Video-call" />;
const ToolTipBackToCall = <FormattedMessage id="Channels.ToolTipBackToCall" defaultMessage="Back to call" />;

class Header extends Component {
    constructor() {
        super();
        this.state = { open: false, actionPerformed: false };
    }

    deselectChannel = () => {
        const { selectedChannel } = this.props;
        updateUnreadMessagesForChannel(selectedChannel, 0);
        this.props.selectCurrentChannel('');
        this.props.changeToChannel();
        widgetOperations.resetCurrentChannel();
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    backToCall = () => {
        const { changeView, mode, focusWindowCall, call } = this.props;
        if (mode === WIDGET_MODE_EMBED) {
            changeView(CALL);
        } else {
            changeView(CALL_WINDOW);
            focusWindowCall(call);
        }
    };

    createRoom = (callType) => {
        const { createRoom, recording, participant, isAdmin } = this.props;
        const { actionPerformed } = this.state;
        if (!actionPerformed) {
            this.setState({ actionPerformed: true });
            const callOptions = {
                recording,
                callType,
                isAdmin
            };
            createRoom([participant.get('userAlias')], callOptions);
        }
    };

    startCallButton = (callType) => {
        let toRender = null;
        const disabled = this.props.communication_center_state !== constants.COMMUNICATION_CENTER_CONNECTED;
        switch (callType) {
            case CALL_TYPE_AUDIO_ONLY:
                toRender = (
                    <Tooltip title={ToolTipAudioOnly}>
                        <IconButton
                            variant="fab"
                            aria-label="Audio call"
                            disabled={disabled}
                            onClick={() => this.createRoom(this.props.widgetCallType)}
                            style={disabled ? {} : { color: this.props.headerStyle.get('button').get('color') }}
                        >
                            <Call />
                        </IconButton>
                    </Tooltip>
                );
                break;
            case CALL_TYPE_AUDIO_UPGRADABLE:
                toRender = (
                    <Tooltip title={ToolTipAudioUpgradable}>
                        <IconButton
                            variant="fab"
                            disabled={disabled}
                            aria-label="Audio upgradable call"
                            onClick={() => this.createRoom(this.props.widgetCallType)}
                            style={disabled ? {} : { color: this.props.headerStyle.get('button').get('color') }}
                        >
                            <Call />
                        </IconButton>
                    </Tooltip>
                );
                break;
            case CALL_TYPE_AUDIO_VIDEO:
            default:
                toRender = (
                    <>
                        <Tooltip title={ToolTipAudioUpgradable}>
                            <IconButton
                                variant="fab"
                                aria-label="Audio upgradable call"
                                disabled={disabled}
                                onClick={() => this.createRoom(CALL_TYPE_AUDIO_UPGRADABLE)}
                                style={disabled ? {} : { color: this.props.headerStyle.get('button').get('color') }}
                            >
                                <Call />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={ToolTipAudioVideo}>
                            <IconButton
                                variant="fab"
                                aria-label="Video call"
                                disabled={disabled}
                                onClick={() => this.createRoom(this.props.widgetCallType)}
                                style={disabled ? {} : { color: this.props.headerStyle.get('button').get('color') }}
                            >
                                <VideoCall />
                            </IconButton>
                        </Tooltip>
                    </>
                );
        }

        return toRender;
    };

    render() {
        const { headerStyle, participant, call, widgetCallType, channel } = this.props;
        return (
            <div
                className={Style.header}
                style={{
                    background: headerStyle.get('background'),
                    color: headerStyle.get('color')
                }}
            >
                <div className={Style['header-body']}>
                    <div>
                        <IconButton className="bandyer-header-action" aria-label="Back" onClick={this.deselectChannel}>
                            <ArrowBack style={{ color: headerStyle.get('button').get('color') }} />
                        </IconButton>
                    </div>
                    <div className={Style.username}>
                        <h3 className={Style.userNameLabel} style={{ color: headerStyle.get('color') }}>
                            {participant.get('formattedName')}
                        </h3>
                        {channel.get('isTyping') ? (
                            <FormattedMessage id="Channels.Typing" defaultMessage="Is typing..." />
                        ) : (
                            <span>{channel.get('status')}</span>
                        )}
                    </div>
                </div>
                <div className={Style['action-buttons']}>
                    <div className="bandyer-header-action">
                        {call.isEmpty() && this.startCallButton(widgetCallType)}
                    </div>
                    {!call.isEmpty() && (
                        <Tooltip title={ToolTipBackToCall}>
                            <IconButton
                                aria-label="back-to-call"
                                onClick={this.backToCall}
                                style={{ color: headerStyle.get('button').get('color') }}
                            >
                                <PhoneCallbackIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    channel: PropTypes.instanceOf(Map),
    createRoom: PropTypes.func,
    participant: PropTypes.instanceOf(Map),
    headerStyle: PropTypes.instanceOf(Map),
    selectCurrentChannel: PropTypes.func,
    changeToChannel: PropTypes.func,
    selectedChannel: PropTypes.string,
    widgetCallType: PropTypes.string,
    recording: PropTypes.string,
    call: PropTypes.instanceOf(Map),
    changeView: PropTypes.func,
    focusWindowCall: PropTypes.func,
    communication_center_state: PropTypes.string,
    mode: PropTypes.string,
    isAdmin: PropTypes.bool
};

Header.defaultProps = {
    mode: WIDGET_MODE_EMBED,
    channel: Map({}),
    createRoom: null,
    participant: Map({}),
    headerStyle: Map({}),
    selectCurrentChannel: null,
    changeToChannel: null,
    selectedChannel: '',
    widgetCallType: CALL_TYPE_AUDIO_VIDEO,
    recording: 'none',
    call: Map({}),
    changeView: null,
    focusWindowCall: null,
    communication_center_state: null,
    isAdmin: false
};

const mapStateToProps = state => ({ isAdmin: state.behavior.get('isAdmin') });


export default connect(mapStateToProps)(Header);
