import BandyerSDK from '../../../../../../../services';
import Logger from '../../../../../../../logger';

const axios = require('axios').default;

const MAX_FILE_SIZE = 150 * 1024 * 1024; // 150 MB

class FileShareUtil {
    constructor() {
        this._L = new Logger('Util- FileShare');
    }

    getUploadPolicy = (callAlias) => {
        const { jwt } = BandyerSDK.getInstance().switchboard.getCall(callAlias).callHost;

        const policyUrl = BandyerSDK.getInstance().environment === 'production' ? `https://cs.${BandyerSDK.getInstance().region}.bandyer.com/connect/api/upload/policy` : `https://cs.${BandyerSDK.getInstance().environment}.${BandyerSDK.getInstance().region}.bandyer.com/connect/api/upload/policy`;
        return axios
            .get(policyUrl, {
                params: { uploadType: 'file_upload' },
                headers: {
                    authorization: `Bearer ${jwt}`,
                    Pragma: 'no-cache',
                    'Cache-Control': 'no-cache, no-store'
                }
            })
            .then(response => response.data)
            .catch((err) => {
                throw new Error('Unable to retrieve S3 policy', err);
            });
    };

    uploadFile = async(policy, file, progressCallBack, cancelFunction = null) => {
        const splitFileName = file.name.split('.');
        const fileExtension = splitFileName.length > 1 ? `.${splitFileName.pop()}` : '';
        const config = {
            onUploadProgress: (progressEvent) => {
                progressCallBack(progressEvent, `${policy.key.split('/').pop()}${fileExtension}`);
            },
            cancelToken: cancelFunction.token
        };
        const formData = new FormData();
        formData.append('key', `${policy.key}${fileExtension}`);
        formData.append('Policy', policy.policy);
        formData.append('X-Amz-Credential', policy.amzHeaders['X-Amz-Credential']);
        formData.append('X-Amz-Algorithm', policy.amzHeaders['X-Amz-Algorithm']);
        formData.append('X-Amz-Date', policy.amzHeaders['X-Amz-Date']);
        formData.append('X-Amz-Signature', policy.amzHeaders['X-Amz-Signature']);
        formData.append('Cache-Control', 'max-age=86400');
        formData.append('Content-Type', 'multipart/form-data');
        formData.append('file', file);
        return axios.post(policy.endpoint, formData, config);
    };

    cancelFunction = () => axios.CancelToken.source();

    emitFileUploadEvent = (url, userAlias) => {
        BandyerSDK.getInstance().call.uploadFile({ url, senderName: userAlias, userAlias });
    };

    validateFile = (file) => {
        if (!file || file.size > MAX_FILE_SIZE) {
            // other check ??
            return false;
        }
        return true;
    };
}

export default FileShareUtil;
