import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import { Map } from 'immutable';
import { Fab, Tooltip } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import Style from '../../style.scss';

class FullScreen extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { open: false };
    }

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        this.setState({ open: true });
    };

    render() {
        const { open } = this.state;
        const ToolTipFullScreen = <FormattedMessage id="Call.ToolTipFullScreen" defaultMessage="Full screen" />;
        return (
            <Tooltip title={ToolTipFullScreen} open={open}>
                <Fab
                    size="small"
                    style={{
                        background: this.props.headerStyle.get('button').get('background'),
                        color: this.props.headerStyle.get('button').get('color')
                    }}
                    aria-label="add"
                    className={Style.callTopButtons}
                    onClick={this.props.toggleFullScreen}
                    id={Style.fullscreenIcon}
                >
                    {this.props.fullScreenMode ? (
                        <FullScreenExitIcon
                            onMouseEnter={this.handleTooltipOpen}
                            onMouseLeave={this.handleTooltipClose}
                            onClick={this.handleTooltipClose}
                        >
                            {' '}
                        </FullScreenExitIcon>
                    ) : (
                        <FullScreenIcon
                            onMouseEnter={this.handleTooltipOpen}
                            onMouseLeave={this.handleTooltipClose}
                            onClick={this.handleTooltipClose}
                        />
                    )}
                </Fab>
            </Tooltip>
        );
    }
}

FullScreen.propTypes = {
    toggleFullScreen: PropTypes.func,
    fullScreenMode: PropTypes.bool,
    headerStyle: PropTypes.instanceOf(Map)
};

FullScreen.defaultProps = {
    toggleFullScreen: null,
    fullScreenMode: false,
    headerStyle: Map({})
};

export default FullScreen;
