import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import Loader from 'react-loaders';
import CallEnd from '@material-ui/icons/CallEnd';
import { Fab, Grid } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Style from './style.scss';


class DialOut extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { showParticipantState: false, formattedName: null };
    }

    componentDidMount() {
        const { provideDetails, call } = this.props;
        const participants = [];
        for (const p of call.get('callParticipants')) {
            const userAlias = p.get('user');
            participants.push(userAlias);
        }
        provideDetails(participants);
        this.setState({ formattedName: this.buildParticipantList() });
    }

    componentDidUpdate(prevProps) {
        const { participantsStateInRoom, call, usersDetails, intl } = this.props;

        if (prevProps.participantsStateInRoom !== participantsStateInRoom) {
            const callParticipants = call.get('callParticipants');
            const callInitiator = call.get('callInitiator');
            const participants = [];
            for (const p of callParticipants) {
                const userAlias = p.get('user').get('userAlias');
                if (userAlias !== callInitiator.get('user').get('userAlias')) {
                    participants.push(
                        userAlias
                    );
                }
            }
            if ((participants.length === 1 && participantsStateInRoom.has(participants[0]))) {
                const formattedMessage = intl.formatMessage({
                    id: `Dial.participantFeedback.${participantsStateInRoom.get(participants[0])}`,
                    defaultMessage: 'is ringing'
                });
                this.setState({ showParticipantState: true, participantStateLabel: `${formattedMessage} ` });
            }
        }
        if (prevProps.usersDetails !== usersDetails) {
            this.setState({ formattedName: this.buildParticipantList() });
        }
    }


    buildParticipantList = () => {
        const { call, usersDetails } = this.props;
        const callParticipants = call.get('callParticipants');
        const callInitiator = call.get('callInitiator');
        const toReturn = [];
        for (const p of callParticipants) {
            const userAlias = p.get('user').get('userAlias');
            if (userAlias !== callInitiator.get('user').get('userAlias')) {
                toReturn.push(
                    usersDetails && usersDetails.has(userAlias)
                        ? usersDetails.get(userAlias).get('formattedName')
                        : userAlias
                );
            }
        }
        return toReturn.join(',');
    };

    render() {
        const { showParticipantState, participantStateLabel, formattedName } = this.state;
        const { call, dialStyle, hangUp, recordingInfo } = this.props;
        return (
            <div
                className={Style['dial-out-container']}
                style={{
                    color: dialStyle.get('color'),
                    background: dialStyle.get('background')
                }}
            >
                {recordingInfo.recording === 'automatic' && (
                    <span className={Style['recording-badge']}>
                        <FormattedMessage id="Dial.recordingAuto" defaultMessage="This call is recorded" />
                        <FiberManualRecordIcon fontSize="small" style={{ color: '#f20600' }} />
                    </span>
                ) }
                {recordingInfo.recording === 'manual' && (
                    <span className={Style['recording-badge']}>
                        <FormattedMessage id="Dial.recordingManual" defaultMessage="This call could be recorded" />
                        <FiberManualRecordIcon fontSize="small" style={{ color: '#f20600' }} />
                    </span>
                )}
                <Grid
                    style={{ margin: '0px' }}
                    spacing={3}
                    className={Style.actions}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    direction="column"
                >
                    <Grid item>
                        {!showParticipantState && (
                            <div style={{ color: dialStyle.get('color') }}>
                                <FormattedMessage id="Channels.Callout" defaultMessage="You are calling" />
                                {' '}
                                {formattedName}
                            </div>
                        )}

                        {showParticipantState && (
                            <div style={{ color: dialStyle.get('color') }}>
                                {formattedName}
                                {' '}
                                {participantStateLabel}
                            </div>
                        )}
                    </Grid>
                    <Grid item>
                        <Fab aria-label="hangup-reject" className={Style['hangup-call']} onClick={() => hangUp(call.get('callAlias'))}>
                            <CallEnd />
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

DialOut.propTypes = {
    hangUp: PropTypes.func,
    call: PropTypes.instanceOf(Map),
    dialStyle: PropTypes.instanceOf(Map),
    usersDetails: PropTypes.instanceOf(Map),
    provideDetails: PropTypes.func,
    recordingInfo: PropTypes.exact({
        recordingInProgress: PropTypes.bool,
        recording: PropTypes.string
    }),
    participantsStateInRoom: PropTypes.instanceOf(Map),
    intl: intlShape.isRequired
};

DialOut.defaultProps = {
    hangUp: null,
    call: Map({}),
    dialStyle: Map({}),
    usersDetails: Map({}),
    provideDetails: null,
    recordingInfo: { recordingInProgress: false, recording: 'none' },
    participantsStateInRoom: Map({})
};

export default injectIntl(DialOut);
