import { constants } from 'bandyersdkcommon';
import { Room, Publisher, BaseUser, setLogLevel as coreAVsetLogLevel } from '@bandyer/web-core-av';
import * as dispatcher from '../../store/actions/dispatcher';
import Logger from '../../logger';
import AttachStream from './attachStream';
import {
    SUBSCRIBER_SCREEN,
    SUBSCRIBER_WEBCAM,
    LOCAL_WEBCAM,
    LOCAL_SS,
    REMOTE_WEBCAM,
    REMOTE_SS,
    GUM_ERROR,
    GENERIC_CALL_ERROR,
    PUBLISH_STREAM_NOT_VALID,
    CALL_TYPE_AUDIO_UPGRADABLE,
    E_BANDYER_CALL_ROOM_RECONNECTING,
    E_BANDYER_CALL_ROOM_RECONNECTED,
    PUBLISHER_WEBCAM, INACTIVE_USER, KICKED_VIEW
} from '../../constants';
import store from '../../store/store';

const events = require('events');

let instance = null;
class Call extends events {
    constructor(bandyerCommunicationCenter, userId) {
        super();
        this._localUserAlias = userId;
        this._L = Logger.scope('Services - Call');
        this._bandyerCommunicationCenter = bandyerCommunicationCenter;
        this._bandyerAVCore = {
            publisher: null,
            room: null
        };
        this._attachStream = new AttachStream();
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'demo') {
            window.bandyerAVCORE = this._bandyerAVCore;
            coreAVsetLogLevel(1);
        }
        this.registerCommunicationCenterEvents();
    }

    static initialize(bandyerCommunicationCenter, screenSharingExtensionId = null) {
        if (!instance) {
            instance = new Call(bandyerCommunicationCenter, screenSharingExtensionId);
        }
        return instance;
    }

    static destroy() {
        instance = null;
    }

    /**
     * This function register the events of the communication center such as incoming call
     */
    registerCommunicationCenterEvents() {
        this._bandyerCommunicationCenter.on('call:user:verified', (verification) => {
            if (verification.userId !== undefined) {
                dispatcher.setVerification(verification);
            }
        });
        this._bandyerCommunicationCenter.on(constants.SOCKET_MULTIPLE_SOCKET, () => {
            dispatcher.handleMultipleSocket();
        });

        this._bandyerCommunicationCenter.on('upload:new', (file) => {
            if (file.url) {
                dispatcher.addFileToDownload(file);
            }
        });

        this._bandyerCommunicationCenter.on('room:recording:start', () => {
            this.emit('room:recording:started');
            dispatcher.updateRecordingInProgress(true);
        });

        this._bandyerCommunicationCenter.on('room:recording:stop', () => {
            this.emit('room:recording:stopped');
            dispatcher.updateRecordingInProgress(false);
        });

        this._bandyerCommunicationCenter.on('call:user:force_disconnect', async() => {
            if (this._bandyerCommunicationCenter.onGoingCall) {
                await this.disconnectCall(this._bandyerCommunicationCenter.onGoingCall.callAlias);
            }
        });

        this._bandyerCommunicationCenter.on('room:admin:mute_audio', async(data, callback) => {
            if (this._bandyerCommunicationCenter.onGoingCall) {
                if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localWebcam) {
                    this._bandyerAVCore.publisher.localWebcam.muteAudioTrack();
                    // show toast

                    const isMuted = this._bandyerAVCore.publisher.localWebcam.isAudioMuted();
                    dispatcher.showMuteByAdmin(isMuted);
                    dispatcher.muteAudio(isMuted);
                    const response = isMuted
                        ? {}
                        : { error: { code: 'client_mute_error,', message: 'Fail to mute audio' } };
                    callback(response);
                }
            }
        });

        this._bandyerCommunicationCenter.on('room:admin:kick', async() => {
            if (this._bandyerCommunicationCenter.onGoingCall) {
                await this.disconnectCall(this._bandyerCommunicationCenter.onGoingCall.callAlias);
                dispatcher.changeViewToKicked(KICKED_VIEW);
            }
        });
    }

    uploadFile(uploadInfo) {
        this._bandyerCommunicationCenter.uploadFile(uploadInfo);
    }


    async disconnectCall(callAlias) {
        this._L.debug('[disconnectCall] - callAlias: ', callAlias);
        // USER VERIFICATION ENDED
        dispatcher.setVerification(null);
        dispatcher.resetFile();
        if (this._bandyerCommunicationCenter.getCall(callAlias)) {
            this._bandyerCommunicationCenter.getCall(callAlias).disconnect();
        }
        if (this._bandyerAVCore.room) {
            this._bandyerAVCore.room.disconnect();
        }
    }

    /**
     * The publishStream function handle the room connection in licode and initialization of the publisher object
     * @param {String} callAlias
     */
    async publishStream(callAlias = null) {
        this._L.debug('[s.publishStream]', callAlias);
        const { room } = this._bandyerAVCore;
        this._L.debug('[s.publishStream- room]', room);
        if (callAlias) {
            try {
                if (!room || !room.room || room.room.state === 0) {
                    const currentCall = this._bandyerCommunicationCenter.getCall(callAlias);
                    this._bandyerAVCore.room = Room.initialize(currentCall.callToken.token);
                    dispatcher.setIsAdmin(!!currentCall.callHost.isAdmin);
                    const { jwt } = currentCall.callHost;
                    const { sessionAlias } = currentCall.callToken;
                    dispatcher.setFeedbackInfo(jwt, sessionAlias);
                    // populate virtualBackgrounds list
                    const data = {}; // future purpose
                    const timePromise = new Promise((resolve) => {
                        setTimeout(resolve, 1000, []);
                    });
                    const virtualBackgrounds = await Promise.race([
                        this._bandyerCommunicationCenter.getBackgroundList(data),
                        timePromise
                    ]);
                    dispatcher.setVirtualBackgrounds(virtualBackgrounds);
                    this._registerRoomEvents();
                    await this._bandyerAVCore.room.connectRoom();
                    const currentUser = await this._bandyerCommunicationCenter.getCurrentUser();
                    this._L.debug(
                        `[s.publishStream] - User stream to publish:${currentUser.userAlias} with allowCamera: ${currentUser.allowCamera}`
                    );
                    this._bandyerAVCore.publisher = Publisher.initialize(
                        BaseUser.initialize(currentUser.toObj()),
                        currentUser.allowCamera
                    );
                    if (virtualBackgrounds.length) {
                        this._bandyerAVCore.publisher.setVirtualBackground(virtualBackgrounds[0].imageUrl);
                    }
                } else {
                    // if there is an active room, get the remote stream and attach them
                    const streams = this._bandyerAVCore.room.getRemoteStreams();
                    streams.forEach((stream) => {
                        this._handleStreamSubscribed(stream);
                    });
                }
                const devices = await this.enumerateDevices();
                if (devices.every(device => (device.kind === 'audioinput' ? device.label === '' : true))) {
                    dispatcher.setMicrophonePermissionDenied(true);
                } else {
                    dispatcher.setMicrophonePermissionDenied(false);
                }
                if (devices.every(device => (device.kind === 'videoinput' ? device.label === '' : true))) {
                    dispatcher.setCameraPermissionDenied(true);
                } else {
                    dispatcher.setCameraPermissionDenied(false);
                }
            } catch (e) {
                this._L.warn('[publishStream] - Err', e);
                switch (e.name) {
                    case 'RoomConnectionError':
                        // the message can be room_connection_failed or room_config_token_not_valid
                        // faccio un throw mandando l'utente alla pagine di errore con scritto
                        // Per favore riprovare, errore generico.
                        throw GENERIC_CALL_ERROR;
                    case 'GetUserMediaError':
                        // the message can be sdk_gum_error or stream_config_not_valid
                        // faccio un throw mandando l'utente alla pagine di errore con scritto
                        // Per favore riprovare,  accetta i permessi o controlla di avere wwbcam funzionante
                        throw GUM_ERROR;
                    case 'PublishStreamError':
                        // publish_no_stream or publish_stream_failed or sdk_room_av_not_connected
                        // faccio un throw mandando l'utente alla pagine di errore con scritto
                        // Per favore riprovare, errore generico.
                        throw GENERIC_CALL_ERROR;
                    default:
                        throw e;
                }
            }
            const currentCall = this._bandyerCommunicationCenter.getCall(callAlias);
            const callPermission = currentCall.getCallPermission();
            return this.publishWebCam(callAlias, callPermission);
        }
        throw new Error('[Publish stream] - call alias not valid');
    }

    subscribeStream(stream) {
        this._L.debug('[s.subscribeStream]', stream);
        return this._bandyerAVCore.room.subscribeStream(stream, {
            audio: true,
            video: true,
            data: true
        });
    }

    /**
     * The publish webcam method will publish the webcam stream based on the current call permission.
     * Firstly, it retrieves the callPermission given the "callAlias". After, it calls bandyerAVCore.initLocalWebCam method to acquire the stream.
     * Then, it publish the stream in the AVCore room (licode room).
     * @param {String} callAlias
     * @param config
     */
    async publishWebCam(callAlias, config = { audio: true, video: true }) {
        this._L.debug('[s.publishWebCam] - config: ', config);
        try {
            this._L.debug('[s.publishWebCam] - first request: ');
            const state = store.getState();
            const virtualBackgroundConfig = state.behavior.get('virtualBackground');
            await this._bandyerAVCore.publisher.initLocalWebCam(config, virtualBackgroundConfig);

            this._bandyerAVCore.publisher.localWebcam.on('stream-ended', (stream) => {
                stream.closeStream();
                dispatcher.setStreamEndedError(true);
            });

            // these dispatchers handles the icon in the call footer
            dispatcher.publisherHasAudio();
            dispatcher.publisherHasVideo();
            await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localWebcam);
            const devices = await this.enumerateDevices();
            if (config.audio !== false) {
                dispatcher.setMicrophonePermissionDenied(false);
                dispatcher.setCameraPermissionDenied(false);
                if (devices.every(device => (device.kind === 'videoinput' ? device.label === '' : true))) {
                    dispatcher.setCameraPermissionDenied(true);
                }
            }
            if (config.video && config.video !== false) {
                const currentCall = this._bandyerCommunicationCenter.getCall(callAlias);
                if (currentCall.callType === CALL_TYPE_AUDIO_UPGRADABLE) {
                    currentCall.upgradeVideo();
                }
                // event sent to CommunicationCenter to notify the room that the current user has upgraded the video
            }
            this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localWebcam);
            if (this._bandyerAVCore.publisher.localScreen && this._bandyerAVCore.publisher.localScreen.hasScreen()) {
                // attach local stream if we back to call
                this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localScreen);
            }
            const { stream } = this._bandyerAVCore.publisher.localWebcam;
            if (stream && stream.stream) {
                dispatcher.setMediaStream(stream.stream);
            } else {
                dispatcher.setMediaStream(null);
            }
            this._L.debug('[e.publishWebCam]');
            return;
        } catch (e) {
            this._L.warn('[publishWebCam] - Err', e);
            this._L.warn('[publishWebCam] - Err', e.name);
            this._bandyerAVCore.publisher.stopLocalWebCam();
            switch (e.name) {
                case 'RoomConnectionError':
                    // the message can be room_connection_failed or room_config_token_not_valid
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // Per favore riprovare, errore generico.
                    throw GENERIC_CALL_ERROR;
                case 'GetUserMediaError': {
                    dispatcher.setError(e.message);
                    this._L.debug('[publishWebCam] - GetUserMediaError');
                    // Re publish with no video first, if fail, retry in data only and open the gear
                    const devices = await this.enumerateDevices();
                    this._L.debug('[publishWebCam] - Devices', devices);
                    config.audio = true;
                    config.video = true;
                    if (devices.every(device => (device.kind === 'audioinput' ? device.label === '' : true))) {
                        dispatcher.setMicrophonePermissionDenied(true);
                        dispatcher.setCameraPermissionDenied(true);
                        config.audio = false;
                        config.video = false;
                        this.publishWebCam(callAlias, config);
                        return;
                    }
                    dispatcher.setMicrophonePermissionDenied(false);
                    dispatcher.setCameraPermissionDenied(true);
                    config.video = false;
                    this.publishWebCam(callAlias, config);
                    return;
                }
                case 'PublishStreamError': {
                    // publish_no_stream or publish_stream_failed or sdk_room_av_not_connected
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // Per favore riprovare, errore generico.
                    if (e.message === PUBLISH_STREAM_NOT_VALID) {
                        // this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localWebcam);
                        throw PUBLISH_STREAM_NOT_VALID;
                    }
                    return;
                }
                default:
                    throw e;
            }
        }
    }

    async publishScreen(callAlias, fps) {
        this._L.debug('[s.publishScreen] callAlias', callAlias, 'fps', fps);
        try {
            await this._bandyerAVCore.publisher.initLocalScreen(false, fps, this._screenSharingExtensionId);
            this._bandyerAVCore.publisher.localScreen.on('stream-ended', (stream) => {
                stream.closeStream();
            });
            await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localScreen);
            this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localScreen);
            this._L.debug('[e.publishScreen]');
        } catch (e) {
            // todo gestire errore
            throw e;
        }
    }

    async unpublishWebcam() {
        if (this._bandyerAVCore.publisher.localWebcam.stream) {
            return this._bandyerAVCore.room.unpublishStream(this._bandyerAVCore.publisher.localWebcam, true);
        }
        return false;
    }

    async unpublishScreen() {
        if (this._bandyerAVCore.publisher.localScreen.stream) {
            return this._bandyerAVCore.room.unpublishStream(this._bandyerAVCore.publisher.localScreen, true);
        }
        return false;
    }

    async requestPermissionToUpgradeVideo(callAlias) {
        try {
            const result = await this._bandyerCommunicationCenter.requestPermissionToUpgradeVideo(callAlias);
            return result.roomAlias === callAlias;
        } catch (e) {
            return false;
        }
    }

    getCallStatus(callAlias) {
        return this._bandyerCommunicationCenter.getCall(callAlias).callStatus;
    }

    getCallOptions(callAlias) {
        return this._bandyerCommunicationCenter.getCall(callAlias)._callOptions;
    }

    toggleAudio() {
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localWebcam) {
            return this._bandyerAVCore.publisher.localWebcam.toggleAudioTrack();
        }
        return null;
    }

    toggleVideo() {
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localWebcam) {
            return this._bandyerAVCore.publisher.localWebcam.toggleVideoTrack();
        }
        return null;
    }

    hasAudio() {
        if (this._bandyerAVCore.publisher) {
            return this._bandyerAVCore.publisher.localWebcam.hasAudio();
        }
        return null;
    }

    hasVideo() {
        if (this._bandyerAVCore.publisher) {
            return this._bandyerAVCore.publisher.localWebcam.hasVideo();
        }
        return null;
    }

    enumerateDevices() {
        return this._bandyerAVCore.publisher.enumerateDevices();
    }

    handleManualRecording(recording) {
        const toReturn = this._bandyerCommunicationCenter.handleManualRecording(recording);
        if (toReturn) {
            if (recording) {
                this.emit('room:recording:started');
            } else {
                this.emit('room:recording:stopped');
            }
        }
        return toReturn;
    }

    /**
     * Toggle the virtualBackground functionality, also set the background image if forwarded
     * @param type
     * @param virtualBackground
     * @returns {Promise<void>}
     */
    async toggleVirtualBackground(type, virtualBackground = null) {
        this._L.debug('[toggleVirtualBackground] - type: ', type, ' virtualBackground: ', virtualBackground);
        if (virtualBackground) {
            this._bandyerAVCore.publisher.setVirtualBackground(virtualBackground);
        }
        const toReturn = await this._bandyerAVCore.publisher.toggleVirtualBackground(type);
        dispatcher.setVirtualBackgroundConfig(toReturn);
        this._L.debug('[toggleVirtualBackground] - result: ', toReturn);
        return toReturn;
    }


    _registerRoomEvents() {
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_ROOM_CONNECTED, (roomEvent) => {
            this._handleRoomConnected(roomEvent);
        });
        this._bandyerAVCore.room.on(E_BANDYER_CALL_ROOM_RECONNECTING, () => {
            // dispatch toggle variable for loader on callview
            dispatcher.setReconnectingCall(true);
        });
        this._bandyerAVCore.room.on(E_BANDYER_CALL_ROOM_RECONNECTED, async(roomEvent) => {
            this._L.debug('Call reconnected', roomEvent);
            // republish previous local Webcam
            this._bandyerAVCore.publisher.localWebcam.stream.failed = false;
            await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localWebcam);
            this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localWebcam);

            // check for previous localScreen
            if (this._bandyerAVCore.publisher.localScreen && this._bandyerAVCore.publisher.localScreen.hasScreen()) {
                // republish localScreen if exists
                this._bandyerAVCore.publisher.localScreen.stream.failed = false;
                await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localScreen);
                this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localScreen);
            }
            const { stream } = this._bandyerAVCore.publisher.localWebcam;
            if (stream && stream.stream) {
                dispatcher.setMediaStream(stream.stream);
            } else {
                dispatcher.setMediaStream(null);
            }
            // subscribe remote streams
            this._handleRoomConnected(roomEvent);
            dispatcher.setReconnectingCall(false);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_ROOM_DISCONNECTED, (roomEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_ROOM_DISCONNECTED}`, roomEvent);
            dispatcher.setRecordingError(false);
            dispatcher.updateRecordingInProgress(false);
            dispatcher.setReconnectingCall(false);
            switch (roomEvent.message) {
                case 'unexpected-disconnection':
                    this.disconnectCall(this._bandyerCommunicationCenter.onGoingCall.callAlias);
                    dispatcher.handleErrorCallSocket();
                    break;
                case 'expected-disconnection':
                    break;
                default:
                    dispatcher.handleErrorCallSocket();
            }
            this._handleRoomDisconnected();
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_ROOM_ERROR, () => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_ROOM_ERROR}`);
            throw GENERIC_CALL_ERROR;
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_ADDED, (streamEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_ADDED}`, streamEvent);
            // console.log(`Call  ########## ${constants.E_BANDYER_CALL_STREAM_ADDED}`, roomEvent);
            this._handleStreamAdded(streamEvent);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_REMOVED, (streamEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_REMOVED}`, streamEvent);
            // console.log(`Call AV Core module ########## ${constants.E_BANDYER_CALL_STREAM_REMOVED}`, streamEvent);
            this._handleStreamRemoved(streamEvent);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_SUBSCRIBED, (streamEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_SUBSCRIBED}`, streamEvent);
            // console.log(`Call AV Core module ########## ${constants.E_BANDYER_CALL_STREAM_SUBSCRIBED}`, roomEvent);
            this._handleStreamSubscribed(streamEvent);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_FAILED, async(wrappedStream) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_FAILED}`, wrappedStream);
            dispatcher.setStreamFailedError(true);
            this.recoverStreamFailed(wrappedStream);
        });
        this._bandyerAVCore.room.on('recording-error', () => {
            dispatcher.setRecordingError(true);
        });
    }

    recoverStreamFailed(wrappedStream) {
        this._L.debug(
            '[recoverStreamFailed], local: ',
            wrappedStream.stream.local,
            'failed:',
            wrappedStream.stream.isFailed(),
            'closed',
            wrappedStream.stream.isClosed()
        );
        if (wrappedStream.stream.local) {
            if (wrappedStream.stream.isFailed()) {
                setTimeout(async() => {
                    // bail
                    // Publish Again
                    try {
                        await this._bandyerAVCore.room.unpublishStream(wrappedStream);
                        await this._bandyerAVCore.room.publishStream(wrappedStream);
                        await this._handleStreamAddedLocal(wrappedStream);
                        dispatcher.setStreamFailedError(false);
                    } catch (e) {
                        this._L.debug('[recoverStreamFailed], local true isFailed: true catch error:', e);

                        this.recoverStreamFailed(wrappedStream);
                    }
                }, 1000);
            }
        } else {
            // Subscribe Again
            setTimeout(async() => {
                // bail
                if (!wrappedStream.stream.isClosed()) {
                    this._attachStream.handleVideoRemoved(wrappedStream);
                    try {
                        await this._bandyerAVCore.room.unsubscribeStream(wrappedStream);
                        await this._bandyerAVCore.room.subscribeStream(wrappedStream, {
                            video: wrappedStream.stream.hasVideo(),
                            audio: wrappedStream.stream.hasAudio(),
                            data: wrappedStream.stream.hasData()
                        });
                        dispatcher.setStreamFailedError(false);
                    } catch (e) {
                        this._L.debug('[recoverStreamFailed], local false isClosed: false catch error:', e);
                        this.recoverStreamFailed(wrappedStream);
                    }
                }
            }, 1000);
        }
    }

    /**
     * For all streams currently published in the room, it calls the subscribe function
     * @param roomEvent
     * @private
     */
    _handleRoomConnected(roomEvent) {
        // todo finire questo check
        const { streams } = roomEvent;
        this._L.debug('[_handleRoomConnected] - streams: ', roomEvent);
        for (let i = 0; i < streams.length; i++) {
            const stream = streams[i];
            this._L.debug('[_handleRoomConnected] - Subscribing to: ', stream);
            this._bandyerAVCore.room.subscribeStream(stream, {
                audio: true,
                video: true
            });
        }
    }

    _handleRoomDisconnected() {
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localWebcam) {
            this._bandyerAVCore.publisher.localWebcam.closeStream();
        }
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localScreen) {
            this._bandyerAVCore.publisher.localScreen.closeStream();
        }
    }


    /**
     * This fn handle stream. If the user is not local, the user must subscribe it.
     * If the stream is local and is screen, the fn must
     * @param stream
     * @private
     */
    _handleStreamAdded(stream) {
        if (!this._bandyerAVCore.room.isLocalStream(stream)) {
            this.subscribeStream(stream);
        }
    }

    async _handleStreamAddedLocal(stream) {
        if (stream.hasScreen()) {
            this._L.debug('[_handleStreamAddedLocal] - Screen');
            dispatcher.publishScreen(true);
            this._attachStream.handleVideoAdded(stream, LOCAL_SS);
        } else {
            this._L.debug('[_handleStreamAddedLocal] - Webcam', stream);
            dispatcher.publishWebcam(true);
            this._attachStream.handleVideoAdded(stream, LOCAL_WEBCAM);
            // update enumerateDevices
            if (stream.hasAudio() || stream.hasVideo()) {
                dispatcher.getEnumerateDevices();
            }
            const enumerateDevices = await this.enumerateDevices();
            const mediaStream = stream.stream.originalStream.stream;
            if (stream.hasAudio()) {
                const audioDevice = enumerateDevices.find(
                    device => device.label === mediaStream.getAudioTracks()[0].label && device.kind === 'audioinput'
                );
                audioDevice == null
                    ? dispatcher.selectAudioDevice('none')
                    : dispatcher.selectAudioDevice(audioDevice.deviceId);
            } else {
                dispatcher.selectAudioDevice('none');
            }
            if (stream.hasVideo()) {
                const videoDevice = enumerateDevices.find(
                    device => device.label === mediaStream.getVideoTracks()[0].label && device.kind === 'videoinput'
                );
                videoDevice == null
                    ? dispatcher.selectVideoDevice('none')
                    : dispatcher.selectVideoDevice(videoDevice.deviceId);
            } else {
                dispatcher.selectVideoDevice('none');
            }
        }
    }

    _handleStreamSubscribed(stream) {
        if (!this._bandyerAVCore.room.isLocalStream(stream)) {
            // this._attachStream.attachSubscriberWebcam(roomEvent.stream);
            if (stream.hasScreen()) {
                this._attachStream.handleVideoAdded(stream, REMOTE_SS);
                dispatcher.changeMainVideo(SUBSCRIBER_SCREEN);
            } else {
                stream.on('stream-update-config', (attr) => {
                    if (attr.videoMutedRemotely || attr.videoMutedLocally) {
                        dispatcher.setRemoteVideoMuted(true);
                    } else {
                        dispatcher.setRemoteVideoMuted(false);
                    }
                });
                if (stream.stream.isVideoMutedLocally() || stream.stream.isVideoMutedRemotely() || !stream.hasVideo()) {
                    dispatcher.setRemoteVideoMuted(true);
                } else {
                    dispatcher.setRemoteVideoMuted(false);
                }
                this._attachStream.handleVideoAdded(stream, REMOTE_WEBCAM);
                dispatcher.changeMainVideo(SUBSCRIBER_WEBCAM);
            }
        }
    }

    _handleStreamRemoved(stream) {
        if (this._bandyerAVCore.room._room.state !== 0 && this._bandyerAVCore.room.isLocalStream(stream)) {
            if (stream.hasScreen()) {
                dispatcher.publishScreen(false);
            }
        }
        this._attachStream.handleVideoRemoved(stream);
        if (this._attachStream.isVideoInMainVideoContainer(REMOTE_WEBCAM)) {
            dispatcher.changeMainVideo(SUBSCRIBER_WEBCAM);
        } else if (this._attachStream.isVideoInMainVideoContainer(REMOTE_SS)) {
            dispatcher.changeMainVideo(SUBSCRIBER_SCREEN);
        } else {
            dispatcher.changeMainVideo(PUBLISHER_WEBCAM);
        }
    }

    get virtualBackgroundState() {
        return this._bandyerAVCore.publisher.virtualBackgroundState;
    }

    get isVirtualBackgroundSupported() {
        return this._bandyerAVCore.publisher.isVirtualBackgroundSupported;
    }

    get activeCalls() {
        return this._activeCalls;
    }

    set activeCalls(value) {
        this._activeCalls = value;
    }
}

export default Call;
