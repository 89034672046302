// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes ball-beat{50%{opacity:0.2;transform:scale(0.75)}100%{opacity:1;transform:scale(1)}}@keyframes Bandyer-ball-beat{50%{opacity:0.2;transform:scale(0.75)}100%{opacity:1;transform:scale(1)}}.ball-beat>div{background-color:#fff;width:15px;height:15px;border-radius:100%;margin:2px;animation-fill-mode:both;display:inline-block;animation:ball-beat 0.7s 0s infinite linear}.ball-beat>div:nth-child(2n-1){animation-delay:-0.35s !important}@keyframes Bandyer-line-scale{0%{transform:scaley(1)}50%{transform:scaley(0.4)}100%{transform:scaley(1)}}.line-scale>div:nth-child(1){animation:line-scale 1s -.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)}.line-scale>div:nth-child(2){animation:line-scale 1s -.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)}.line-scale>div:nth-child(3){animation:line-scale 1s -.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)}.line-scale>div:nth-child(4){animation:line-scale 1s -.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)}.line-scale>div:nth-child(5){animation:line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)}.line-scale>div{background-color:#fff;width:4px;height:35px;border-radius:2px;margin:2px;animation-fill-mode:both;display:inline-block}.Bandyer-chat-loader{position:absolute;top:10px;right:10px;z-index:999}\n", ""]);
// Exports
exports.locals = {
	"chat-loader": "Bandyer-chat-loader",
	"ball-beat": "Bandyer-ball-beat",
	"line-scale": "Bandyer-line-scale"
};
module.exports = exports;
