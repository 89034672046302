import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Tooltip, Fab, IconButton, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import SendIcon from '@material-ui/icons/Send';
import Style from './style.scss';

const ToolTipErrorNetwork = <FormattedMessage id="Error.network" defaultMessage="Network error" />;


class Sender extends Component {
    constructor(props) {
        super(props);
        this.state = { userInput: props.channel.has('textArea') ? props.channel.get('textArea') : '' };
    }

    disableSend = () => {
        const { socketState } = this.props;
        const { userInput } = this.state;
        const cleanUserInput = userInput.replace(/\s+/g, '');
        return socketState !== 'CONNECTED' || cleanUserInput === '';
    };

    handleChange = (event) => {
        const { channel } = this.props;
        this.setState({ userInput: event.target.value });
        channel.set('textArea', event.target.value);
    }

    submitMessage = (event) => {
        const { sendMessage, channel } = this.props;
        sendMessage(event);
        this.setState({ userInput: '' });
        channel.set('textArea', '');
    }

    render() {
        const { placeholder, disabledInput, isTyping, socketState } = this.props;
        const { userInput } = this.state;
        return (
            <form className={Style.sender} onSubmit={this.submitMessage}>
                <input
                    type="text"
                    className={Style['new-message']}
                    id="new-message"
                    name="message"
                    value={userInput}
                    placeholder={placeholder}
                    disabled={disabledInput}
                    autoFocus={!window.DetectRTC.isMobileDevice}
                    autoComplete="off"
                    onChange={this.handleChange}
                    onKeyPress={isTyping}
                />
                <Tooltip placement="left" title={socketState !== 'CONNECTED' ? ToolTipErrorNetwork : ''}>
                    <div>
                        <Button
                            size="small"
                            type="submit"
                            className={Style.send}
                            disabled={this.disableSend()}
                        >
                            <SendIcon className={Style['send-icon']} />
                        </Button>
                    </div>
                </Tooltip>
            </form>
        );
    }
}

Sender.propTypes = {
    socketState: PropTypes.string,
    sendMessage: PropTypes.func,
    placeholder: PropTypes.string,
    disabledInput: PropTypes.bool,
    isTyping: PropTypes.func,
    channel: PropTypes.instanceOf(Map)
};

Sender.defaultProps = {
    socketState: null,
    sendMessage: null,
    placeholder: null,
    disabledInput: false,
    isTyping: null,
    channel: Map({})
};

export default Sender;
