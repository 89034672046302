// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-blur,.Bandyer-blur:hover{background:#7A7A7A;width:45px;height:45px;padding:5px 12px}.Bandyer-blur.Bandyer-active,.Bandyer-blur:hover.Bandyer-active{background:#009fe3}.Bandyer-blur .Bandyer-blur-icon,.Bandyer-blur:hover .Bandyer-blur-icon{width:35px;height:35px}.Bandyer-blur .Bandyer-progress,.Bandyer-blur:hover .Bandyer-progress{color:#009fe3;position:absolute;z-index:1}\n", ""]);
// Exports
exports.locals = {
	"blur": "Bandyer-blur",
	"active": "Bandyer-active",
	"blur-icon": "Bandyer-blur-icon",
	"progress": "Bandyer-progress"
};
module.exports = exports;
