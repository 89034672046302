import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Fab, IconButton, CircularProgress, StylesProvider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Constants from 'bandyersdkcommon/dist/src/constants';
import { MESSAGE_SENDER } from '../../../../../../../../constants';
import Style from './style.scss';
import Message from './components/Message';

class Messages extends Component {
    constructor(props) {
        super(props);
        const { channels, selectedChannel } = this.props;
        let notification = 0;
        let channel = null;
        channels.forEach((c) => {
            if (c.get('uniqueName') === selectedChannel) {
                notification = c.get('unreadMessages');
                channel = c;
            }
        });
        this.state = {
            showFastForward: false,
            countMessage: notification,
            scrollToUnReadMessages: false,
            viewScrollNumber: true,
            isFetching: false,
            stopFetch: false,
            channel
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.isFetching = false;
    }

    componentDidMount() {
        if (this.state.countMessage === 0) this.scrollToBottom();
        else {
            this.setState({ scrollToUnReadMessages: true });
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.socketState !== this.props.socketState
            && this.props.socketState === 'CONNECTED'
            && this.state.isFetching === true
        ) {
            const messagesDiv = document.getElementById(Style.messages);
            const scrollBefore = messagesDiv.scrollHeight;
            const messageId = this.props.messages.first().get('messageId');
            await this.props.fetchMessages(this.props.selectedChannel, messageId);
            const scrollAfter = messagesDiv.scrollHeight;
            messagesDiv.scrollTop = scrollAfter - scrollBefore;
            this.resetFetching();
        }
        if (
            prevProps.socketState === this.props.socketState
            && prevProps.messages !== this.props.messages
            && this.state.isFetching === false
        ) {
            const count = this.state.countMessage + 1;
            if (snapshot || this.props.messages.last().get('sender') === MESSAGE_SENDER.CLIENT) {
                this.scrollToBottom();
            } else if (this.state.showFastForward) {
                this.setState({ countMessage: count, viewScrollNumber: true });
            } else {
                this.scrollToBottom();
            }
        }
        if (this.state.scrollToUnReadMessages === true) {
            const unReadMessageLabel = document.getElementById(Style.unReadMessage);
            const messagesDiv = document.getElementById(Style.messages);
            unReadMessageLabel.scrollIntoView();
            messagesDiv.scrollTop -= 10;
            this.setState({
                scrollToUnReadMessages: false,
                viewScrollNumber: false
            });
        }
        if (prevProps.messages !== this.props.messages && this.isFetching === true) {
            this.resetFetching();
        }
        if (prevProps.channels !== this.props.channels) {
            let channel = null;
            const notification = 0;
            this.props.channels.forEach((c) => {
                if (c.get('uniqueName') === this.props.selectedChannel) {
                    channel = c;
                }
            });
            this.setState({ channel });
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const messagesDiv = document.getElementById(Style.messages);
        if (messagesDiv.scrollHeight - messagesDiv.scrollTop - messagesDiv.clientHeight <= 0) {
            return true;
        }
        return false;
    }

    handleTimestampLabel = () => {
        const { language } = this.props;

        const messagesDiv = document.getElementById(Style.messages);
        const renderedMessages = Array.from(messagesDiv.childNodes);

        const showTimestampLabel = messagesDiv.scrollTop > 1;
        this.setState({ showTimestampLabel });
        const visibleMessage = renderedMessages.find((message) => {
            const messageRect = message.getBoundingClientRect();
            const messagesDivRectTop = messagesDiv.getBoundingClientRect().top + 100;

            const visible = messageRect.top < messagesDivRectTop && messageRect.bottom > messagesDivRectTop;

            return message.className === Style.message && visible;
        });
        if (visibleMessage) {
            const date = new Date(Number.parseInt(visibleMessage.getAttribute('data-timestamp'), 10));
            const messageMoment = moment(date);
            if (messageMoment.isSame(moment(), 'd')) {
                this.setState({ floatingDate: messageMoment.locale(language).calendar().split(' ')[0] });
            } else {
                this.setState({ floatingDate: messageMoment.locale(language).format('DD MMMM') });
            }
        }
    }


    handleScroll = async() => {
        const { scrollToUnReadMessages, stopFetch } = this.state;

        this.handleTimestampLabel();


        if (this.isFetching || scrollToUnReadMessages) {
            return;
        }
        const messagesDiv = document.getElementById(Style.messages);
        const { fetchMessages, selectedChannel, messages } = this.props;
        const firstMessage = messages.first();
        if (firstMessage) {
            const messageId = firstMessage.get('messageId');
            const scrollBefore = messagesDiv.scrollHeight;
            if (messagesDiv.scrollHeight - messagesDiv.scrollTop - messagesDiv.clientHeight > 1) {
                this.setState({ showFastForward: true });
                if (messagesDiv.scrollTop < 100 && !stopFetch) {
                    this.isFetching = true;
                    this.setState({ isFetching: this.isFetching });
                    if (this.props.socketState === 'CONNECTED') {
                        const messagesFetched = await fetchMessages(selectedChannel, messageId);
                        if (messagesFetched.length === 0) {
                            this.isFetching = false;
                            this.setState({ isFetching: this.isFetching, stopFetch: true });
                        }
                        const scrollAfter = messagesDiv.scrollHeight;
                        messagesDiv.scrollTop = scrollAfter - scrollBefore;
                    }
                }
            } else {
                this.setState({
                    showFastForward: false,
                    countMessage: 0
                });
            }
        }
    };

    scrollToBottom = () => {
        const messagesDiv = document.getElementById(Style.messages);
        messagesDiv.scrollTop = messagesDiv.scrollHeight;
        this.setState({ countMessage: 0 });
    };

    unreadMessagesLabel = (index, size) => {
        const unReadText = <FormattedMessage id="Conversation.unreadMessages" defaultMessage="Un-read messages" />;
        if (size - this.state.countMessage === index) {
            return (
                <div id={Style.unReadMessage} className={Style.unReadMessage}>
                    {unReadText}
                </div>
            );
        }
    };

    resetFetching = () => {
        this.isFetching = false;
        this.setState({ isFetching: this.isFetching });
    };

    getMessageDate = (timestamp) => {
        const { language } = this.props;
        const currentTimestamp = new Date(timestamp);
        const messageMoment = moment(currentTimestamp);
        if (messageMoment.isSame(moment(), 'd')) {
            return messageMoment.locale(language).calendar().split(' ')[0]; // get today label
        }
        return messageMoment.locale(language).format('DD MMMM');
    }

    isRead = (message) => {
        const { channel } = this.state;
        const { participant, messages } = this.props;
        const userAlias = participant.get('userAlias');
        const lastReadUserMessage = channel.get('lastReadUserMessage');
        if (lastReadUserMessage) {
            const lastReadUserMessageId = lastReadUserMessage.get(userAlias);
            if (lastReadUserMessageId) {
                const readMessage = messages.find(m => m.get('messageId') === lastReadUserMessageId);
                if (readMessage) {
                    return readMessage.get('timestamp') >= message.get('timestamp');
                }
            }
        }
        return false;
    }

    render() {
        const { showFastForward, countMessage, viewScrollNumber, isFetching, floatingDate, showTimestampLabel } = this.state;
        return (
            <div
                id={Style.messages}
                className={Style['messages-container']}
                onScroll={this.handleScroll}
                style={{
                    background: this.props.bodyStyle.get('background'),
                    color: this.props.bodyStyle.get('color')
                }}
            >
                {isFetching && (
                    <div className={Style.fetcherLoader}>
                        <CircularProgress className={Style.circularProgress} size={15} />
                    </div>
                )}
                {!isFetching && showTimestampLabel && (
                    <div className={Style.floatingDate}>
                        <div className={Style.messageDate}>
                            {' '}
                            {floatingDate}
                            {' '}
                        </div>
                    </div>
                )
                }

                {this.props.messages && this.props.messages.first() && (
                    <div className={Style.stickyDate}>
                        <div className={Style.messageDate}>
                            {this.getMessageDate(this.props.messages.first().get('timestamp'))}
                        </div>
                    </div>
                )}

                {this.props.messages.map((message, index, array) => {
                    const currentTimestamp = new Date(message.get('timestamp'));
                    const nextElement = array.get(index + 1);
                    let printDate = false;
                    let date = this.getMessageDate(message.get('timestamp'));
                    if (nextElement) {
                        const nextTimestamp = new Date(nextElement.get('timestamp'));
                        printDate = nextTimestamp.getDate() !== currentTimestamp.getDate();
                        date = this.getMessageDate(nextElement.get('timestamp'));
                    }
                    return printDate ? (
                        <React.Fragment key={message.get('messageId')}>
                            <div className={Style.message} data-timestamp={message.get('timestamp')}>
                                {this.unreadMessagesLabel(index, this.props.messages.size)}
                                {this.props.profileAvatar && message.get('showAvatar') && (
                                    <img src={this.props.profileAvatar} className={Style.avatar} alt="profile" />
                                )}
                                <Message message={message} isRead={this.isRead(message)} messageStyle={this.props.messageStyle} />
                            </div>
                            <div className={Style.stickyDate}>
                                <div className={Style.messageDate}>
                                    {date}
                                </div>
                            </div>
                        </React.Fragment>

                    ) : (
                        <div className={Style.message} key={message.get('messageId')} data-timestamp={message.get('timestamp')}>
                            {this.unreadMessagesLabel(index, this.props.messages.size)}
                            {this.props.profileAvatar && message.get('showAvatar') && (
                                <img src={this.props.profileAvatar} className={Style.avatar} alt="profile" />
                            )}
                            <Message message={message} isRead={this.isRead(message)} messageStyle={this.props.messageStyle} />
                        </div>
                    );
                }) }

                {showFastForward && (
                    <IconButton
                        size="small"
                        aria-label="delete"
                        className={Style.arrowButton}
                        onClick={this.scrollToBottom}
                    >
                        {!!countMessage && viewScrollNumber && <div className={Style.notification}>{countMessage}</div>}
                        <ExpandMoreIcon className="arrow" />
                    </IconButton>
                )}
            </div>
        );
    }
}

Messages.propTypes = {
    messages: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    profileAvatar: PropTypes.string,
    headerStyle: PropTypes.instanceOf(Map),
    messageStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    selectedChannel: PropTypes.string,
    channels: PropTypes.instanceOf(List),
    fetchMessages: PropTypes.func,
    socketState: PropTypes.string,
    language: PropTypes.string,
    participant: PropTypes.instanceOf(Map)
};

Messages.defaultProps = {
    messages: List([]),
    profileAvatar: '',
    headerStyle: Map({}),
    messageStyle: Map({}),
    bodyStyle: Map({}),
    selectedChannel: '',
    channels: List([]),
    fetchMessages: null,
    socketState: null,
    language: 'en',
    participant: Map({})
};

export default connect(store => ({
    selectedChannel: store.behavior.get('selectedChannel'),
    channels: store.channels,
    messages: store.messages.get(store.behavior.get('selectedChannel')),
    messageStyle: store.styles.get('message')
}))(Messages);
