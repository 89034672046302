import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BandyerEnum } from 'bandyersdkcommon';
import classNames from 'classnames';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Style from '../Channel/style.scss';
import { fetchUserImage } from '../../../../../../../../helpers/utils';

import variables from '../../../../../../../../styles/variables.scss';

const styles = {
    badge: { display: 'none' },
    badgeOnline: {
        backgroundColor: variables.bGreen,
        border: '2px solid #fff',
        'border-radius': '6px',
        top: '80%',
        display: 'block',
        right: 3,
        width: 12,
        height: 12
    }
};

class UserStatus extends PureComponent {
    render() {
        const { classes, participants, status } = this.props;
        const classNameToApply = classNames(classes.badge, {
            [classes.badgeOnline]: status === BandyerEnum.UserStatus.ONLINE
        });

        return (
            <Badge color="primary" variant="dot" classes={{ badge: classNameToApply }}>
                {participants && participants.has('image') ? (
                    <Avatar src={fetchUserImage(participants)} className={Style['channel-avatar']} />
                ) : (
                    <Avatar src="" className={Style['channel-avatar']} />
                )}
            </Badge>
        );
    }
}

UserStatus.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(UserStatus);
