import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import DoneIcon from '@material-ui/icons/Done';
import { LinearProgress, Box, Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import DownloadJS from 'downloadjs';
import { ReactComponent as File } from '../../../../../../../assets/icon/File.svg';
import Style from '../style.scss';

const DetectRTC = require('detectrtc');

class UploadItem extends PureComponent {
    constructor(props) {
        super(props);
    }

    delete = () => {
        const { cancelUpload, fileItem } = this.props;
        cancelUpload(fileItem.get('name'), fileItem.get('cancelFunction'));
    };

    download = () => {
        const { fileItem } = this.props;
        if (DetectRTC.osName === 'iOS' && DetectRTC.osVersion.split('.')[0] < 13) {
            window.open(fileItem.get('url'), '_blank');
        } else {
            DownloadJS(fileItem.get('url'), undefined, undefined, fileItem.withCredentials || false);
        }
    };

    renderIcon = () => {
        const { fileItem, networkError } = this.props;
        if (fileItem.has('error')) {
            return (
                <IconButton style={{ color: '#000' }} disabled={networkError} onClick={this.reUploadFile}>
                    <ReplayIcon />
                </IconButton>
            );
        }
        if (fileItem.get('progress') === 100) {
            return <DoneIcon className={Style.doneIcon} />;
        }
        return (
            <IconButton style={{ color: '#000' }} onClick={this.delete} className={Style.dismissIcon}>
                <CloseIcon />
            </IconButton>
        );
    };

    reUploadFile = async () => {
        const { fileItem, fileUpload, removeItem } = this.props;
        await removeItem(fileItem.get('name'));
        fileUpload(fileItem.get('error').file);
    };

    render() {
        const { fileItem } = this.props;
        return (
            <Box
                p={1}
                display="flex"
                alignItems="center"
                flexDirection="row"
                className={Style['file-item']}
                key={fileItem.get('name')}
            >
                <SvgIcon style={{ color: '#606060' }} component={File} />
                <Box
                    p={1}
                    flexDirection="column"
                    flexGrow={1}
                    className={Style['progress-container']}
                    onClick={this.download}
                >
                    <Tooltip title={fileItem.get('name')}>
                        <div className={Style.fileName}>{fileItem.get('name')}</div>
                    </Tooltip>
                    {fileItem.has('error') ? (
                        <span className={Style.errorMessage}>{fileItem.get('error').message}</span>
                    ) : (
                        <LinearProgress variant="determinate" value={fileItem.get('progress')} />
                    )}
                </Box>
                {this.renderIcon()}
            </Box>
        );
    }
}

UploadItem.propTypes = {
    fileItem: PropTypes.instanceOf(Map),
    cancelUpload: PropTypes.func,
    fileUpload: PropTypes.func,
    removeItem: PropTypes.func,
    networkError: PropTypes.bool
};

UploadItem.defaultProps = {
    fileItem: Map([]),
    cancelUpload: null,
    fileUpload: null,
    removeItem: null,
    networkError: false
};

export default UploadItem;
