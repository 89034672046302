import React, { PureComponent } from 'react';
import Linkify from 'react-linkify';
import { MESSAGE_PROP_TYPES } from 'constants';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { buildMessageTimestamp } from '../../../../../../../../../../helpers/utils';
import Style from './style.scss';

class Message extends PureComponent {
    getSender = () => (this.props.message.get('sender') === 'client' ? 'sent' : 'received');

    showTick = () => this.props.message.get('sender') === 'client'

    render() {
        // const sanitizedHTML = marked.parse(this.props.message.get('text'), { sanitize: true });
        const { message, messageStyle, isRead } = this.props;
        return (

            <div
                className={Style[message.get('sender')]}
                style={{
                    background: messageStyle.get(this.getSender()).get('background'),
                    color: messageStyle.get(this.getSender()).get('color')
                }}
            >
                <Linkify properties={{ target: '_blank', style: { color: '#0c2e41', fontWeight: 'bold' } }}>
                    {message.get('text')}
                </Linkify>
                <span className={Style.timestamp}>
                    {buildMessageTimestamp(message.get('timestamp'))}
                    {this.showTick() && isRead && (
                        <DoneAllIcon
                            style={{ color: messageStyle.get(this.getSender()).get('color') }}
                            className={Style.readTick}
                        />
                    )}
                    {this.showTick() && !isRead && (
                        <DoneIcon
                            style={{ color: messageStyle.get(this.getSender()).get('color') }}
                            className={Style.readTick}
                        />
                    )}
                </span>
            </div>


        );
    }
}

Message.propTypes = {
    isRead: PropTypes.bool,
    message: MESSAGE_PROP_TYPES.MESSAGE,
    messageStyle: PropTypes.instanceOf(Map)
};

export default Message;
