// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-virtual-background,.Bandyer-virtual-background:hover{background:#7a7a7a;width:45px;height:45px;padding:5px 12px}.Bandyer-virtual-background.Bandyer-active,.Bandyer-virtual-background:hover.Bandyer-active{background:#009fe3}.Bandyer-virtual-background .Bandyer-virtual-background-icon,.Bandyer-virtual-background:hover .Bandyer-virtual-background-icon{width:35px;height:35px}.Bandyer-virtual-background .Bandyer-progress,.Bandyer-virtual-background:hover .Bandyer-progress{color:#009fe3;position:absolute;z-index:1}\n", ""]);
// Exports
exports.locals = {
	"virtual-background": "Bandyer-virtual-background",
	"active": "Bandyer-active",
	"virtual-background-icon": "Bandyer-virtual-background-icon",
	"progress": "Bandyer-progress"
};
module.exports = exports;
