// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader-hidden{display:none}.loader-active{display:block}.Bandyer-inactive-user-container{padding:10px 5px;height:350px;text-align:center;color:#fff}.Bandyer-inactive-user-container .Bandyer-main-error{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);display:flex;flex-direction:column}.Bandyer-inactive-user-container .Bandyer-main-controls{margin:20px}.Bandyer-inactive-user-container .Bandyer-progress{margin:spacing(2)}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-error-container{height:100%}}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"inactive-user-container": "Bandyer-inactive-user-container",
	"main-error": "Bandyer-main-error",
	"main-controls": "Bandyer-main-controls",
	"progress": "Bandyer-progress",
	"error-container": "Bandyer-error-container"
};
module.exports = exports;
