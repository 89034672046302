import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import { Map } from 'immutable';
import { Fab, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Style from '../../style.scss';

class GearButton extends PureComponent {
    render() {
        const { headerStyle, toggleGear } = this.props;
        const toolTipgear = <FormattedMessage id="Call.ToolTipGear" defaultMessage="Settings" />;
        return (
            <Tooltip title={toolTipgear}>
                <Fab
                    size="small"
                    style={{
                        background: headerStyle.get('button').get('background'),
                        color: headerStyle.get('button').get('color')
                    }}
                    className={Style['callTopButtons']}
                    onClick={toggleGear}
                >
                    <SettingsIcon style={{ color: headerStyle.get('button').get('color') }} />
                </Fab>
            </Tooltip>
        );
    }
}

GearButton.propTypes = {
    headerStyle: PropTypes.instanceOf(Map),
    toggleGear: PropTypes.func
};

GearButton.defaultProps = {
    headerStyle: Map({}),
    toggleGear: null
};

export default GearButton;
