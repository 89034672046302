import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextField from '@material-ui/core/TextField';
import Star from '@material-ui/icons/Star';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import {
    createTheme,
    ThemeProvider
} from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';
import Style from './style.scss';
import Logger from '../../../../../../../../logger';
import widgetOperations from '../../../../../../redux/operations';
import Service from '../../../../../../../../services';
import BandyerSDK from '../../../../../../../../services';

const axios = require('axios').default;

const FormattedMessageFixed = props => <FormattedMessage {...props} />;

class FeedBack extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { starValue: 5, textValue: '', view: 'rating' };
        this.theme = createTheme({ palette: { primary: { main: props.feedbackStyle.get('buttons').get('background') } } });
    }

    ratingChanged = (event, newRating) => {
        this.setState({ starValue: newRating });
    }

    textRatingChange = (event) => {
        this.setState({ textValue: event.target.value });
    }

    sendFeedback = async() => {
        const { starValue, textValue } = this.state;
        const { feedbackInfo } = this.props;
        const ratingEndPoint = BandyerSDK.getInstance().environment === 'production' ? `https://cs.${BandyerSDK.getInstance().region}.bandyer.com/connect/api/rate` : `https://cs.${BandyerSDK.getInstance().environment}.${BandyerSDK.getInstance().region}.bandyer.com//connect/api/rate`;
        try {
            await axios.post(ratingEndPoint, {
                rating: starValue,
                message: textValue,
                sessionAlias: feedbackInfo.sessionAlias
            }, { headers: { Authorization: `Bearer ${feedbackInfo.jwt}` } });
        } catch (e) {
            Logger.warn('[sendRating] - error', e);
        } finally {
            this.setState({ view: 'success' });
        }
    };

    close = () => {
        const { resetToChannelView } = this.props;
        resetToChannelView();
    };


    render() {
        const { feedbackStyle } = this.props;
        const { starValue, view } = this.state;
        return (
            <div
                className={Style['feedback-container']}
                style={{
                    color: feedbackStyle.get('body').get('color'),
                    background: feedbackStyle.get('body').get('background')
                }}
            >
                <IconButton aria-label="close" className={Style.closeButton} onClick={this.close}>
                    <CloseIcon />
                </IconButton>
                {view === 'rating' && (
                <>
                    <p className={Style['call-rating-header']}><FormattedMessage id="Feedback.Header" defaultMessage="Please evaluate the quality of your video call" /></p>
                    <div className={Style['rating-content']}>
                        <Rating
                            className={Style.stars}
                            name="stars"
                            size="large"
                            onChange={this.ratingChanged}
                            defaultValue={5}
                            precision={1}
                            color="primary"
                            value={starValue}
                            emptyIcon={<StarBorderIcon style={{ color: feedbackStyle.get('buttons').get('background') }} fontSize="inherit" />}
                            icon={<Star style={{ color: feedbackStyle.get('buttons').get('background') }} fontSize="inherit" />}
                        />
                        <p className="call-rating-label">
                            <FormattedMessageFixed id={`Feedback.RatingLabel.${starValue}`} defaultMessage="Great" />
                        </p>
                        <ThemeProvider theme={this.theme}>
                            <TextField className={Style['comment-area']} fullWidth label={<FormattedMessage id="Feedback.Comment" defaultMessage="Send" />} margin="dense" maxRows={3} multiline onChange={this.textRatingChange} />
                        </ThemeProvider>
                    </div>

                    <Button
                        variant="contained"
                        className={Style.sendButton}
                        style={{
                            color: feedbackStyle.get('buttons').get('color'),
                            background: feedbackStyle.get('buttons').get('background')
                        }}
                        onClick={this.sendFeedback}
                    >
                        <FormattedMessage id="Feedback.Send" defaultMessage="Send" />
                    </Button>
                </>
                )}
                {view === 'success' && (
                    <>
                        <p className={Style['call-rating-success-title1']}><FormattedMessage id="Feedback.Success.Title1" defaultMessage="Thank you" /></p>
                        <p className={Style['call-rating-success-title2']}><FormattedMessage id="Feedback.Success.Title2" defaultMessage="Hope to see you soon." /></p>
                        <Button
                            variant="contained"
                            className={Style.sendButton}
                            style={{
                                color: feedbackStyle.get('buttons').get('color'),
                                background: feedbackStyle.get('buttons').get('background')
                            }}
                            onClick={this.close}
                        >
                            <FormattedMessage id="Feedback.Success.Close" defaultMessage="Close" />
                        </Button>
                    </>

                )}
            </div>
        );
    }
}

FeedBack.propTypes = { feedbackStyle: PropTypes.instanceOf(Map), feedbackInfo: PropTypes.instanceOf(Object), resetToChannelView: PropTypes.func };

FeedBack.defaultProps = { feedbackStyle: Map({}), feedbackInfo: {}, resetToChannelView: null };

const mapStateToProps = state => ({ feedbackStyle: state.styles.get('feedback'), feedbackInfo: state.behavior.get('feedbackInfo') });

const mapDispatchToProps = dispatch => ({ resetToChannelView: () => dispatch(widgetOperations.resetToChannelView()) });

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
