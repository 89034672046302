import actions from './types';

/** *********** SET STATE ********* */

function setCommunicationCenterState(state) {
    return {
        type: actions.UPDATE_COMMUNICATION_CENTER_STATE,
        state
    };
}

function setChatSocketState(state) {
    return {
        type: actions.UPDATE_CHAT_SOCKET_STATE,
        state
    };
}

function setCallSocketState(state) {
    return {
        type: actions.UPDATE_CALL_SOCKET_STATE,
        state
    };
}

export default {
    setCommunicationCenterState,
    setChatSocketState,
    setCallSocketState
};
