// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-channel-header{padding:20px;border-radius:8px 8px 0 0;display:flex;justify-content:flex-start;align-items:center}.Bandyer-channel-header .Bandyer-big-avatar{width:60px;height:60px;margin-right:16px}.Bandyer-channel-header .Bandyer-user-name{flex:1;min-width:0;max-width:100%}.Bandyer-channel-header .Bandyer-user-name .Bandyer-userNameLabel{font-size:1.17em !important;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:200px}.Bandyer-channel-header .Bandyer-action-buttons{right:15px;display:flex}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-channel-header .Bandyer-big-avatar{width:70px;height:70px}.Bandyer-channel-header .Bandyer-user-name .Bandyer-userNameLabel{font-size:1.5rem}}\n", ""]);
// Exports
exports.locals = {
	"channel-header": "Bandyer-channel-header",
	"big-avatar": "Bandyer-big-avatar",
	"user-name": "Bandyer-user-name",
	"userNameLabel": "Bandyer-userNameLabel",
	"action-buttons": "Bandyer-action-buttons"
};
module.exports = exports;
