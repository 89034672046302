import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
    snackbar: {
        margin: theme.spacing.unit,
        'background-color': 'rgba(0,0,0,0.7)'
    }
});
/* */

function AudioOnlySnackBar(props) {
    const { classes, handleClose } = props;
    /* const { formatMessage } = this.context.intl;
    const message = formatMessage({ id: 'SnackBar.AudioOnly' });
    console.log('intl', intl); */
    const action = (
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon />
        </IconButton>
    );
    return (
        <div>
            <SnackbarContent
                className={classes.snackbar}
                action={action}
                message={
                    <FormattedMessage
                        id="SnackBar.AudioOnly"
                        defaultMessage="Oops.. this is an audio only call. You can't use the webcam"
                    />
                }
            />
        </div>
    );
}

AudioOnlySnackBar.propTypes = {
    classes: PropTypes.object,
    handleClose: PropTypes.func
};

AudioOnlySnackBar.defaultProps = {
    classes: {},
    handleClose: null
};

export default withStyles(styles)(AudioOnlySnackBar);
