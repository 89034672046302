import { Map } from 'immutable';
import actionTypes from './types';

const initialState = Map({
    usersDetails: new Map({}),
    usersStatus: new Map({})
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_USERS_DETAILS: {
            let usersDetails = new Map(state.get('usersDetails'));
            action.userDetails.forEach((u) => {
                usersDetails = usersDetails.set(u.get('userAlias'), u);
            });
            return state.update('usersDetails', () => usersDetails);
        }

        case actionTypes.SET_USER_STATUS: {
            let usersStatus = new Map({});
            action.onlineUsers.forEach((user) => {
                usersStatus = usersStatus.set(user, 'online');
            });
            return state.update('usersStatus', () => usersStatus);
        }
        case actionTypes.UPDATE_USER_STATUS: {
            let onlineUsers = state.get('usersStatus');
            onlineUsers = onlineUsers.set(action.userAlias, action.status);
            return state.update('usersStatus', () => onlineUsers);
        }
        default:
            return state;
    }
}
