export const WIDGET_INSTANCE_ALREADY_INITIATED = 'WIDGET_INSTANCE_ALREADY_INITIATED';
export const ADD_CHANNEL_GENERIC_ERROR = 'ADD_CHANNEL_GENERIC_ERROR';
export const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR';
export const REMOVE_CHANNEL_GENERIC_ERROR = 'REMOVE_CHANNEL_GENERIC_ERROR';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const CHANNEL_ALREADY_CREATED = 'CHANNEL_ALREADY_CREATED';
export const CHANNEL_NOT_FOUND = 'CHANNEL_NOT_FOUND';
export const NO_MEMBER_SELECTED = 'NO_MEMBER_SELECTED';
export const NO_INPUT_MESSAGE = 'NO_INPUT_MESSAGE';
export const MEMBER_ALREADY_EXISTS = 'MEMBER_ALREADY_EXISTS';
export const CALL_ALREADY_ANSWERED = 'CallActionAlreadyTaken';

class InvalidTokenError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class GetUserMediaError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = 'GetUserMediaError';
        this.message = message;
    }
}

class InvalidConfigError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = 'invalid_config_error';
        this.message = message;
    }
}

class MalformedAuthTokenError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class ExpiredAuthTokenError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class WidgetAlreadyInitiateError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class UserNotValidError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class AddChatError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class ChannelNotFoundError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class BandyerChatWidgetNotAuthenticated extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = 'authentication_error';
        this.code = 'authentication_error';
        this.message = 'The widget must be correctly initialized to perform this action';
    }
}

class ChatAddedError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class ChatRemoveError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class WebRTCPluginNotWhitelistedError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class NotValidPermissionError extends Error {
    constructor(message, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = this.constructor.name;
        this.message = message;
    }
}

class ExposeError extends Error {
    constructor(error, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error().stack;
        }
        this.name = error.code;
        this.code = error.code;
        this.message = error.message;
    }
}


export {
    AddChatError,
    BandyerChatWidgetNotAuthenticated,
    ChannelNotFoundError,
    ChatAddedError,
    ChatRemoveError,
    ExpiredAuthTokenError,
    InvalidConfigError,
    InvalidTokenError,
    MalformedAuthTokenError,
    UserNotValidError,
    WidgetAlreadyInitiateError,
    WebRTCPluginNotWhitelistedError,
    GetUserMediaError,
    NotValidPermissionError,
    ExposeError
};
