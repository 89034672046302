import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ChatIcon from '@material-ui/icons/Chat';
import { Fab, Tooltip } from '@material-ui/core';
import { Map, List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { buildChannelUniqueName } from '../../../../../../../../helpers/utils';
import { CHANNELS } from '../../../../../../../../constants';
import Style from '../../style.scss';

class BackToChat extends PureComponent {
    getNotification() {
        const { call, channels } = this.props;
        let notification = 0;
        const callParticipants = call.get('callParticipants');
        if (!call || !call.has('callParticipants')) {
            return notification;
        }
        const callParticipantsArray = [];
        callParticipants.forEach(p => {
            if (p.has('user') && p.get('user').has('userAlias')) {
                callParticipantsArray.push(p.get('user').get('userAlias'));
            }
        });
        if (callParticipantsArray.length < 2) {
            return notification;
        }
        const uniqueName = buildChannelUniqueName(callParticipantsArray);
        channels.some(c => {
            if (c.get('uniqueName') === uniqueName) {
                notification = c.get('unreadMessages');
                return true;
            }
        });
        const callTopContainer = document.getElementById(Style['call-top-container']);
        if (callTopContainer && notification === 1) {
            callTopContainer.className = Style['call-top-container'].concat(' ', Style['show-hide']);
        }
        return notification;
    }

    backToChat = async () => {
        const { call, addChannel, localUser, selectChannel, changeView, channels } = this.props;

        const callParticipants = call.get('callParticipants');
        if (!call || !call.has('callParticipants')) {
            changeView(CHANNELS);
        }
        const callParticipantsArray = [];
        callParticipants.forEach(p => {
            if (p.has('user') && p.get('user').has('userAlias')) {
                callParticipantsArray.push(p.get('user').get('userAlias'));
            }
        });
        if (callParticipantsArray.length < 2) {
            changeView(CHANNELS);
        }
        /* bring the alias of the user with which to start a conversation */
        const uniqueName = buildChannelUniqueName(callParticipantsArray);
        try {
            /* try to addChannel, if the call is programmatically created, maybe there is not a channel */
            let channel = null;
            channels.forEach((c) => {
                if (c.get('uniqueName') === uniqueName) {
                    channel = c;
                }
            });
            if (!channel) {
                const member = callParticipantsArray.find(userAlias => userAlias !== localUser.get('userAlias'));
                await addChannel(member);
            }
            selectChannel(uniqueName, callParticipants); // now the channel exist, back to them
        } catch (e) {
            const error = e.message;
            switch (error) {
                case 'The chat was already created':
                    selectChannel(uniqueName, callParticipants);
                    break;
                case 'The user passed is not valid':
                    changeView(CHANNELS);
                    break;
                default:
                    changeView(CHANNELS);
            }
        }
    };

    renderbackToChatIcon = () => {
        const { headerStyle, size } = this.props;
        const ToolTipBackToChat = <FormattedMessage id="Call.ToolTipBackToChat" defaultMessage="Back to chat" />;
        return (
            <Tooltip title={ToolTipBackToChat}>
                <Fab
                    size={size}
                    style={{
                        background: headerStyle.get('button').get('background'),
                        color: headerStyle.get('button').get('color'),
                        overflow: 'visible'
                    }}
                    className={Style.callTopButtons}
                    onClick={this.backToChat}
                >
                    <ChatIcon style={{ color: headerStyle.get('button').get('color') }} />
                    {!!this.getNotification() && (
                        <span className={Style['notification-badge']}>{this.getNotification()}</span>
                    )}
                </Fab>
            </Tooltip>
        );
    };

    render() {
        return this.renderbackToChatIcon();
    }
}

BackToChat.propTypes = {
    headerStyle: PropTypes.instanceOf(Map),
    call: PropTypes.instanceOf(Map),
    addChannel: PropTypes.func,
    localUser: PropTypes.instanceOf(Map),
    selectChannel: PropTypes.func,
    changeView: PropTypes.func,
    channels: PropTypes.instanceOf(List),
    notification: PropTypes.number
};

BackToChat.defaultProps = {
    headerStyle: Map({}),
    call: Map({}),
    addChannel: null,
    localUser: Map({}),
    selectChannel: null,
    changeView: null,
    channels: List([]),
    notification: 0
};

export default BackToChat;
