import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Button, Typography, IconButton } from '@material-ui/core';

import { red, blue } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

createTheme({
    palette: {
        primary: blue,
        secondary: red
    }
});
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const backdrop = { position: 'absolute' };

const permissionHeader = <FormattedMessage id="PermissionPlay.Header" defaultMessage="Interaction request" />;
const permissionContent = (
    <FormattedMessage
        id="PermissionPlay.Content"
        defaultMessage="We need an interaction to play the audio/video source"
    />
);
const closeButton = <FormattedMessage id="PermissionPlay.CloseButton" defaultMessage="Got it!" />;

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class RequestPlayPermission extends PureComponent {
    close = () => {
        const { failedMediaElements, setRequestPlayPermission } = this.props;
        failedMediaElements.forEach((elem) => {
            elem.play().catch((err) => {
                this._L.error('Cannot play mediaElement after checkPermission modal', err);
            });
        });
        setRequestPlayPermission(false);
    };

    render() {
        const { showRequestPlayPermission, container } = this.props;
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={showRequestPlayPermission}
                container={container}
                style={{ position: 'absolute' }}
                onClose={this.close}
                fullWidth
                maxWidth="xl"
                BackdropProps={{ style: backdrop }}
                PaperProps={{ style: { margin: 20, maxHeight: 'inherit', width: 'auto' } }}
            >
                <DialogTitle onClose={this.close}>{permissionHeader}</DialogTitle>

                <DialogContent style={{ overflow: 'hidden' }}>{permissionContent}</DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={this.close} autoFocus>
                        {closeButton}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
RequestPlayPermission.propTypes = {
    showRequestPlayPermission: PropTypes.bool,
    failedMediaElements: PropTypes.instanceOf(Array),
    setRequestPlayPermission: PropTypes.func,
    container: PropTypes.shape({ component: PropTypes.instanceOf(React.Component) })
};

RequestPlayPermission.defaultProps = {
    showRequestPlayPermission: null,
    failedMediaElements: [],
    setRequestPlayPermission: null,
    container: null
};

export default RequestPlayPermission;
