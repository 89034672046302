/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ReactDOM from 'react-dom';
import { Button } from '@material-ui/core';
import Style from './style.scss';
import {
    BANDYER_CHAT_CONTAINER,
    INACTIVE_USER,
    INACTIVE_USER_TOKEN_EXPIRED,
    KICKED_VIEW
} from '../../../../../../constants';


class DisconnectUserView extends Component {
    render() {
        const { bodyStyle, headerStyle, type, onClick } = this.props;
        return (
            <div
                className={Style['inactive-user-container']}
                style={{
                    background: bodyStyle.get('background'),
                    color: bodyStyle.get('color')
                }}
            >
                <div className={Style['main-error']}>
                    {type === INACTIVE_USER && <FormattedMessage id="UserInactive.ForceDisconnect.Message" defaultMessage="Your account has been disabled or deleted" />}
                    {type === INACTIVE_USER_TOKEN_EXPIRED && <FormattedMessage id="UserInactive.TokenExpired.Message" defaultMessage="Your session is expired." />}
                    {type === KICKED_VIEW && <FormattedMessage id="UserKicked.Message" defaultMessage="The admin has kicked you" />}

                    <Button
                        style={{
                            background: headerStyle.get('button').get('background'),
                            color: headerStyle.get('button').get('color')
                        }}
                        aria-label="back to chat"
                        onClick={onClick}
                        className={Style['main-controls']}
                    >
                        <FormattedMessage id="Dialog.CloseButton" defaultMessage="Close" />
                    </Button>
                </div>
            </div>
        );
    }
}

DisconnectUserView.propTypes = {
    headerStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    type: PropTypes.string,
    intl: intlShape.isRequired,
    onClick: PropTypes.func
};

DisconnectUserView.defaultProps = {
    headerStyle: Map({}),
    bodyStyle: Map({}),
    type: INACTIVE_USER,
    onClick: null
};

export default injectIntl(DisconnectUserView);
