// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader-hidden{display:none}.loader-active{display:block}.Bandyer-dial-in-container{padding:10px 5px;height:350px;border-radius:8px;max-width:370px}.Bandyer-dial-in-container .Bandyer-recording-badge{display:flex;align-items:center;font-size:12px;position:absolute;top:5px;right:5px;z-index:5}.Bandyer-dial-in-container .Bandyer-big-avatar{width:60px;height:60px}.Bandyer-dial-in-container .Bandyer-actions{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);text-align:center}.Bandyer-dial-in-container .Bandyer-accept-call{background-color:#42af73;color:#fff}.Bandyer-dial-in-container .Bandyer-reject-call{background-color:#e63247;color:#fff}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-dial-in-container{max-width:none;height:100%}}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"dial-in-container": "Bandyer-dial-in-container",
	"recording-badge": "Bandyer-recording-badge",
	"big-avatar": "Bandyer-big-avatar",
	"actions": "Bandyer-actions",
	"accept-call": "Bandyer-accept-call",
	"reject-call": "Bandyer-reject-call"
};
module.exports = exports;
