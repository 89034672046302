import React from 'react';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return this.props.children;
    }
}

export default connect()(ErrorBoundary);
