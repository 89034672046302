// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader-hidden{display:none}.loader-active{display:block}.Bandyer-call-window-container{padding:10px 5px;height:350px;border-radius:8px;display:flex;flex-direction:column;justify-content:center;align-items:center;max-width:370px}.Bandyer-call-window-container .Bandyer-actions button{margin:5px}.Bandyer-call-window-container .Bandyer-actions .Bandyer-focus-call{background-color:#42af73;color:#fff}.Bandyer-call-window-container .Bandyer-actions .Bandyer-reject-call{background-color:#e63247;color:#fff}.Bandyer-call-window-container .Bandyer-text{padding-bottom:20px}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-call-window-container{height:100%;width:100%;max-width:none}}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"call-window-container": "Bandyer-call-window-container",
	"actions": "Bandyer-actions",
	"focus-call": "Bandyer-focus-call",
	"reject-call": "Bandyer-reject-call",
	"text": "Bandyer-text"
};
module.exports = exports;
