import { connect } from 'react-redux';
import { BrowserPlugin } from '@bandyer/web-core-av';
import { CHANNELS } from '../../../../constants';
import MainComponent from './MainComponent';
import widgetOperations from '../../redux/operations';
import { socketStateOperations } from '../../../socketStatus/redux';
import { usersDetailsOperations } from '../../../usersDetails/redux';
import callOperations from './components/call/redux/operations';

const mapStateToProps = state => ({
    selectedChannel: state.behavior.get('selectedChannel'),
    isWidgetReady: state.behavior.get('widgetReady'),
    isLoading: state.behavior.get('isLoading'),
    haveChat: state.behavior.get('haveChat'),
    recording: state.behavior.get('recording'),
    recordingInfo: state.call.get('recordingInfo'),
    channels: state.channels,
    currentView: state.behavior.get('view'),
    call: state.behavior.get('call'),
    localUser: state.behavior.get('localUser'),
    mode: state.behavior.get('mode'),
    fontFamily: state.styles.get('fontFamily'),
    headerStyle: state.styles.get('header'),
    bodyStyle: state.styles.get('body'),
    messageStyle: state.styles.get('message'),
    callStyle: state.styles.get('call'),
    dialStyle: state.styles.get('dial'),
    widgetCallType: state.behavior.get('widgetCallType'),
    allowCamera: state.behavior.get('allowCamera'),
    videoHasAudioTrack: state.call.get('videoHasAudio'),
    videoHasVideoTrack: state.call.get('videoHasVideo'),
    communication_center_state: state.socketState.get('communication_center_state'),
    chat_socket_state: state.socketState.get('chat_socket_state'),
    call_socket_state: state.socketState.get('call_socket_state'),
    language: state.behavior.get('language'),
    usersDetails: state.usersDetails.get('usersDetails'),
    fullScreenMode: state.behavior.get('fullScreenMode'),
    notification: state.channels.filter(ch => ch.get('unreadMessages') > 0).size,
    chat: state.behavior.get('chat'),
    tools: state.behavior.get('callTools'),
    participantsStateInRoom: state.call.get('participantsStateInRoom')
});

const mapDispatchToProps = (dispatch) => {
    const onRemoveChannel = (participants) => {
        const toRemove = participants.toJS().userAlias;
        dispatch(widgetOperations.removeChannel(toRemove));
    };
    const downloadPlugin = () => {
        BrowserPlugin.downloadPlugin();
    };
    const startCreateCall = (callee, options) => {
        dispatch(callOperations.createCall(callee, options));
    };
    const onTypingEvent = (selectedChannel, event) => {
        if (event && event.keyCode !== 13) {
            dispatch(widgetOperations.sendIsTypingEvent(selectedChannel));
        }
    };
    return {
        onRemoveChannel,
        onTypingEvent,
        downloadPlugin,
        startCreateCall,
        sendIsTypingEvent: widgetOperations.sendIsTypingEvent,
        createCall: (uniqueName, options) => dispatch(callOperations.createCall(uniqueName, options)),
        answerTheCall: callAlias => dispatch(callOperations.answerTheCall(callAlias)),
        declineTheCall: callAlias => dispatch(callOperations.declineTheCall(callAlias)),
        focusWindowCall: call => dispatch(callOperations.focusWindowCall(call)),
        closeWindowCall: call => dispatch(callOperations.closeWindowCall(call)),
        hangUpCall: call => dispatch(callOperations.hangUpTheCall(call)),
        changeToChannel: () => dispatch(widgetOperations.changeView(CHANNELS)),
        selectCurrentChannel: () => dispatch(widgetOperations.selectCurrentChannel('')),
        changeView: view => dispatch(widgetOperations.changeView(view)),
        selectChannel: uniqueName => dispatch(widgetOperations.selectChannel(uniqueName)),
        addChannel: (user, options) => dispatch(widgetOperations.addChannel(user, options)),
        updateCommunicationCenterState: state => dispatch(socketStateOperations.setCommunicationCenterState(state)),
        provideDetails: userDetails => dispatch(usersDetailsOperations.provideDetails(userDetails)),
        fetchMessages: (uniqueName, anchor) => dispatch(widgetOperations.fetchMessages(uniqueName, anchor)),
        closePlugin: call => dispatch(callOperations.closePlugin(call))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);
