import * as log from 'loglevel';

function prepareLine(prefix, args) {
    return [prefix].concat(Array.from(args));
}

class Logger {
    constructor(prefix = 'BandyerWidget') {
        this.prefix = prefix;
    }

    static scope(prefix) {
        //this.prefix += ' ' + prefix;
        return new Logger();
    }

    setLevel(level) {
        log.setLevel(level);
    }

    static setLevel(level) {
        log.setLevel(level);
    }

    trace(...args) {
        log.trace.apply(null, prepareLine('BandyerChat T:' + this.prefix, args));
    }

    debug(...args) {
        log.debug.apply(null, prepareLine('BandyerChat D:' + this.prefix, args));
    }

    info(...args) {
        log.info.apply(null, prepareLine('BandyerChat I:' + this.prefix, args));
    }

    warn(...args) {
        log.warn.apply(null, prepareLine('BandyerChat W:' + this.prefix, args));
    }

    error(...args) {
        log.error.apply(null, prepareLine('BandyerChat E:' + this.prefix, args));
    }

    static trace(...args) {
        log.trace.apply(null, prepareLine('BandyerChat T:', args));
    }

    static debug(...args) {
        log.debug.apply(null, prepareLine('BandyerChat D:', args));
    }

    static info(...args) {
        log.info.apply(null, prepareLine('BandyerChat I:', args));
    }

    static warn(...args) {
        log.warn.apply(null, prepareLine('BandyerChat W:', args));
    }

    static error(...args) {
        log.error.apply(null, prepareLine('BandyerChat E:', args));
    }
}

export default Logger;
