import React from 'react';
import { connect } from 'react-redux';
import widgetOperations from './redux/operations';
import WidgetComponent from './WidgetComponent';

const mapStateToProps = state => ({
    selectedChannel: state.behavior.get('selectedChannel'),
    isWidgetReady: state.behavior.get('widgetReady'),
    currentView: state.behavior.get('view'),
    fullScreenMode: state.behavior.get('fullScreenMode'),
    hideWidget: state.behavior.get('hideWidget'),
    showChat: state.behavior.get('showChat'),
    isCalling: state.behavior.get('call').size,
    disabledInput: state.behavior.get('disabledInput'),
    fontFamily: state.styles.get('fontFamily'),
    headerStyle: state.styles.get('header'),
    bodyStyle: state.styles.get('body'),
    launcherStyle: state.styles.get('launcher')
});

const mapDispatchToProps = (dispatch) => {
    const onToggleConversation = () => dispatch(widgetOperations.toggleChat());
    const onSendMessage = (selectedChannel, event) => {
        event.preventDefault();
        const userInput = event.target.message.value;
        if (userInput) {
            dispatch(widgetOperations.sendMessage(selectedChannel, userInput));
        }
        event.target.message.value = '';
    };
    const handleToggleFullScreen = () => {
        dispatch(widgetOperations.toggleFullScreen());
    };
    return {
        onToggleConversation,
        onSendMessage,
        toggleFullScreen: handleToggleFullScreen
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetComponent);
