import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import { constants, BandyerEnum } from 'bandyersdkcommon';
import { Map, fromJS } from 'immutable';
import { BASE_URL_AVATAR, MESSAGES_TYPES, PAST, CURRENT } from '../constants';

const clientName = require('../../package.json').name;
const clientVersion = require('../../package.json').version;

const buildChannelUniqueName = (usersList, separator = '#') => {
    usersList.sort();
    let result = '';
    for (let i = 0; i < usersList.length; i += 1) {
        result += `${usersList[i]}${separator}`;
    }
    if (result) {
        result = result.substring(0, result.length - 1);
    }
    return result;
};

const _convertRoomStructureKeyToRoomName = roomStructureKey => roomStructureKey.substring(0, roomStructureKey.length).split('#');

/**
 * This fn build the array of the users in call. If you specify the me param, the array will not contain yourself.
 * @param roomStructureKey
 * @param me
 * @returns {*}
 */
const buildParticipantFromChannelUniqueName = (roomStructureKey, me = null) => {
    const users = _convertRoomStructureKeyToRoomName(roomStructureKey);
    const index = users.indexOf(me);
    if (index !== -1) {
        users.splice(index, 1);
    }
    return users;
};

const buildUserName = user => user.get('userAlias');

const buildUserEmail = (user) => {
    let toReturn = '';
    if (Map.isMap(user)) {
        if (user.has('email') && user.get('email')) {
            toReturn = `${user.get('email')}`;
        }
        return toReturn;
    }
    return toReturn;
};

const buildLastMessage = (lastMessage = null) => {
    if (Map.isMap(lastMessage) && lastMessage.has('message')) {
        return lastMessage.get('message');
    }
    return '';
};

const buildLastMessageTimestamp = (lastMessage = null, language = 'en') => {
    if (Map.isMap(lastMessage) && lastMessage.has('timestamp')) {
        const dateTimestamp = new Date(lastMessage.get('timestamp'));
        const diff = new Date().getTime() - dateTimestamp.getTime();
        if (diff > 60 * 1000) {
            return humanizeDuration(diff, {
                language,
                largest: 1,
                round: true,
                units: ['y', 'mo', 'd', 'h', 'm', 's']
            })
                .toString()
                .concat(PAST.get(language));
        }
        return CURRENT.get(language);
    }
    return null;
};

const buildMessageTimestamp = (messageTimestamp) => {
    if (messageTimestamp) {
        const messageMoment = moment(messageTimestamp);
        return messageMoment.format('LT');
    }
    return null;
};

const fetchUserImage = (user) => {
    try {
        if (user) {
            new URL(user.get('image'));
            return user.get('image');
        }
        return `${BASE_URL_AVATAR}${'default.jpg'}`;
    } catch (err) {
        return `${BASE_URL_AVATAR}${'default.jpg'}`;
    }
};

const getLinkForCall = (createRoom, userAlias = null) => {
    if (createRoom && createRoom.user_urls.constructor === Array && createRoom.user_urls.length > 1) {
        const indexOfUser = createRoom.user_urls.map(u => u.user).indexOf(userAlias);
        if (indexOfUser !== -1) {
            return createRoom.user_urls[indexOfUser].url;
        }
        return null;
    }
    return null;
};

const parseAttributesMessage = (attributes = {}) => {
    if (Object.prototype.hasOwnProperty.call(attributes, 'createRoom')) {
        return MESSAGES_TYPES.ACTIVITY;
    }
    return MESSAGES_TYPES.TEXT;
};

const buildDeclineReason = (reason = null) => {
    let toReturn = reason;
    if (!reason || !Object.values(BandyerEnum.DeclineReason).includes(reason)) {
        toReturn = BandyerEnum.DeclineReason.NONE;
    }
    return toReturn;
};

const buildHangupReason = (reason = null) => {
    let toReturn = reason;
    if (!reason || !Object.values(BandyerEnum.StopReason).includes(reason)) {
        toReturn = BandyerEnum.StopReason.HANGUP;
    }
    return toReturn;
};

const buildChannelListForClient = (channels, userAlias) => {
    const list = [];
    for (const ch of channels) {
        list.push({
            chat: ch.uniqueName,
            unreadMessages: ch.unreadMessages,
            participant: buildParticipantFromChannelUniqueName(ch.uniqueName, userAlias),
            lastMessage: ch.lastMessage
        });
    }
    return list;
};

const detectIE = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
};

const buildUnreadMessagesForClient = (data) => {
    const toReturn = [];
    for (const key of Object.keys(data)) {
        toReturn.push({
            chat: key,
            unreadMessages: data[key]
        });
    }
    return toReturn;
};

const buildUserInfoFromImmutable = (user) => {
    if (Map.isMap(user)) {
        return user.toJS();
    }
    return { image: 'profile.jpg' };
};

const buildPlatformInfo = () => ({
    clientName,
    clientVersion,
    type: constants.WEB_PLATFORM,
    userAgent: window.navigator.userAgent
});

const isBrowserSupported = (browser, version) => {
    const supportedBrowsers = {
        chrome: { minVersion: 72 },
        firefox: { minVersion: 54 },
        safari: { minVersion: 12 },
        ie: { minVersion: 11 },
        edge: { minVersion: 17 }
    };
    if (supportedBrowsers[browser]) {
        return version >= supportedBrowsers[browser].minVersion;
    }
    return false;
};


export {
    buildChannelListForClient,
    buildChannelUniqueName,
    buildDeclineReason,
    buildLastMessage,
    buildMessageTimestamp,
    buildLastMessageTimestamp,
    buildParticipantFromChannelUniqueName,
    buildUnreadMessagesForClient,
    buildUserEmail,
    buildUserName,
    buildUserInfoFromImmutable,
    fetchUserImage,
    getLinkForCall,
    parseAttributesMessage,
    buildHangupReason,
    buildPlatformInfo,
    detectIE,
    isBrowserSupported
};
