import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { constants } from 'bandyersdkcommon';

// map for language traduction in the channels view
export const PAST = new Map([
    ['it', ' fa'],
    ['en', ' ago']
]);
export const CURRENT = new Map([
    ['it', ' Adesso'],
    ['en', ' Now']
]);
export const MESSAGE_SENDER = {
    CLIENT: 'client',
    RESPONSE: 'response',
    SYSTEM: 'system'
};

export const MESSAGES_TYPES = {
    TEXT: 'text',
    ACTIVITY: 'activity',
    SNIPPET: { LINK: 'snippet' },
    CUSTOM_COMPONENT: 'component'
};

export const MESSAGES_STATUS = {
    SENT: 'sent',
    READ: 'read'
};

export const MESSAGE_PROP_TYPES = {
    MESSAGE: ImmutablePropTypes.contains({
        type: PropTypes.oneOf([MESSAGES_TYPES.TEXT, MESSAGES_TYPES.SNIPPET.LINK, MESSAGES_TYPES.ACTIVITY]),
        text: PropTypes.string,
        sender: PropTypes.oneOf([MESSAGE_SENDER.CLIENT, MESSAGE_SENDER.RESPONSE, MESSAGE_SENDER.SYSTEM])
    }),

    SNIPPET: ImmutablePropTypes.contains({
        type: PropTypes.oneOf([MESSAGES_TYPES.TEXT, MESSAGES_TYPES.SNIPPET.LINK]),
        title: PropTypes.string,
        link: PropTypes.string,
        sender: PropTypes.oneOf([MESSAGE_SENDER.CLIENT, MESSAGE_SENDER.RESPONSE, MESSAGE_SENDER.SYSTEM])
    })
};

export const SESSION_STORAGE_KEYS = { AUTH: 'AUTH' };

export const BANDYER_CHAT_CONTAINER = 'bandyer-chat-widget';

export const BASE_URL = 'https://static.bandyer.com/';
export const BASE_URL_AVATAR = `${BASE_URL}corporate/sample_avatar/`;

/* WIDGET VIEWS */

export const DIAL_OUT = 'DIAL_OUT';
export const DIAL_IN = 'DIAL_IN';
export const CALL = 'CALL';
export const FEEDBACK = 'FEEDBACK';
export const CALL_WINDOW = 'CALL_WINDOW';
export const KICKED_VIEW = 'KICKED_VIEW';
export const CONVERSATION = 'CONVERSATION';
export const CHANNELS = 'CHANNELS';
export const ERROR = 'ERROR';
export const GUM_ERROR = 'GUM_ERROR';
export const INACTIVE_USER = 'INACTIVE_USER';
export const INACTIVE_USER_TOKEN_EXPIRED = 'INACTIVE_USER_TOKEN_EXPIRED';
export const TERMS_REQUIRED = 'TERMS_REQUIRED';
export const MULTIPLE_SOCKET_ERROR = 'MULTIPLE_SOCKET_ERROR';
export const SOCKET_CALL_ERROR = 'SOCKET_CALL_ERROR';
export const GENERIC_CALL_ERROR = 'GENERIC_CALL_ERROR';
export const JOIN_CALL_INVALID_URL = 'join_call_invalid_url';
export const JOIN_CALL_REQUEST_FAILED = 'join_call_request_failed';
export const JOIN_CALL_INVALID_MTM = 'join_call_invalid_mtm';
export const USERS_BUSY_ERROR = 'USERS_BUSY_ERROR';
export const INVALID_PERMISSION_CAN_VIDEO = 'INVALID_PERMISSION_CAN_VIDEO';
export const PLUGIN_NOT_WHITELISTED = 'PLUGIN_NOT_WHITELISTED';
export const NOT_SUPPORTED_BROWSER = 'NOT_SUPPORTED_BROWSER';
export const IE_PLUGIN = 'IE_PLUGIN';
export const INITIATOR_NOT_AVAILABLE = 'INITIATOR_NOT_AVAILABLE';
export const ANSWER_CALL_ERROR = 'ANSWER_CALL_ERROR';
export const ACTION_ALREADY_TAKEN = 'ACTION_ALREADY_TAKEN';

/* WIDGET VIDEO DIMENSIONS */
export const PUBLISHER_WEBCAM = 'PUBLISHER_WEBCAM';
export const PUBLISHER_SCREEN = 'PUBLISHER_SCREEN';
export const SUBSCRIBER_WEBCAM = 'SUBSCRIBER_WEBCAM';
export const SUBSCRIBER_SCREEN = 'SUBSCRIBER_SCREEN';

/* EVENTS */
export const ON_CALL_INCOMING = constants.SDK_EVENTS_CALL_INCOMING;
export const MESSAGE_RECEIVED = constants.SDK_EVENTS_MESSAGE_RECEIVED;
export const MESSAGE_SENT = constants.SDK_EVENTS_MESSAGE_SENT;
export const TYPING_STARTED = constants.SDK_EVENTS_TYPING_START;
export const TYPING_ENDED = constants.SDK_EVENTS_TYPING_ENDED;
export const MESSAGE_READ = constants.SDK_EVENTS_MESSAGE_READ;
export const USER_CONNECTED = constants.SDK_EVENTS_USER_CONNECTED;
export const USER_DISCONNECTED = constants.SDK_EVENTS_USER_DISCONNECTED;
export const CONNECTION_STATE_CHANGED = 'connectionStateChanged';
export const TIMEOUT = 'TIMEOUT';
export const E_BANDYER_CALL_ROOM_RECONNECTING = 'reconnecting';
export const E_BANDYER_CALL_ROOM_RECONNECTED = 'reconnected';

/* STREAM EVENT */

export const LOCAL_WEBCAM = 'local-webcam';
export const LOCAL_SS = 'local-ss';
export const REMOTE_WEBCAM = 'remote-webcam';
export const REMOTE_SS = 'remote-ss';

/* CALL TYPE */

export const CALL_TYPE_AUDIO_ONLY = 'audio_only';
export const CALL_TYPE_AUDIO_UPGRADABLE = 'audio_upgradable';
export const CALL_TYPE_AUDIO_VIDEO = 'audio_video';

/* INIT ERROR MESSAGE */

export const BROWSER_NOT_SUPPORTED = 'browser_not_supported';
export const WIDGET_ALREADY_INITIATED = 'widget_already_initiated';
export const PUBLISH_STREAM_NOT_VALID = 'publish_stream_not_valid';
export const UNPUBLISH_STREAM_NO_STREAM = 'unpublish_stream_no_stream';
export const UNPUBLISH_STREAM_FAILED = 'unpublish_stream_failed';
export const NO_AVAILABLE_MICROPHONE = 'no_available_microphone';
export const GUM_NOT_ALLOWED_ERROR = 'NotAllowedError';
export const GUM_SECURITY_ERROR = 'SecurityError';
export const PERMISSION_DENIED = 'Permission denied';

/* WIDGET MODE */
export const WIDGET_MODE_EMBED = 'embed';
export const WIDGET_MODE_WINDOW = 'window';

/* WIDGET CALL WINDOW ACTION TYPE */
export const WIDGET_WINDOW_ACTION_ANSWER = 'widget_window_action_answer';
export const WIDGET_WINDOW_ACTION_DECLINE = 'widget_window_action_decline';
export const CALL_WINDOW_HANGUP_ACTION = 'call_window_hangup';
