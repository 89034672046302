import * as Creators from './actions';
import { validateStyleConfig } from '../../../../helpers/parseConfig';
import { setFeedbackStyleBody, setFeedbackStyleButtons } from './actions';

const {
    setFontFamily,
    setHeaderStyleBackground,
    setHeaderStyleColor,
    setHeaderButtonStyleBackground,
    setHeaderButtonStyleColor,
    setBodyStyleBackground,
    setBodyStyleColor,
    setLauncherStyleBackground,
    setLauncherStyleColor,
    setMessageSentStyleBackground,
    setMessageReceivedStyleBackground,
    setMessageReceivedStyleColor,
    setDialStyleBackground,
    setDialStyleColor,
    setCallStyleBackground,
    setCallStyleColor,
    setMessageSentStyleColor
} = Creators;

function handleConfigLayout(dispatch, config) {
    if (config.fontFamily) {
        dispatch(setFontFamily(config.fontFamily));
    }
    if (config) {
        const { header, headerButton, body, launcher, messageSent, messageReceived, dial, call, feedback } = config;
        if (validateStyleConfig(header, 'background')) {
            dispatch(setHeaderStyleBackground(header.background));
        }
        if (validateStyleConfig(header, 'color')) {
            dispatch(setHeaderStyleColor(header.color));
        }
        if (validateStyleConfig(headerButton, 'background')) {
            dispatch(setHeaderButtonStyleBackground(headerButton.background));
        }
        if (validateStyleConfig(headerButton, 'color')) {
            dispatch(setHeaderButtonStyleColor(headerButton.color));
        }
        if (validateStyleConfig(body, 'background')) {
            dispatch(setBodyStyleBackground(body.background));
        }
        if (validateStyleConfig(body, 'color')) {
            dispatch(setBodyStyleColor(body.color));
        }
        if (validateStyleConfig(launcher, 'background')) {
            dispatch(setLauncherStyleBackground(launcher.background));
        }
        if (validateStyleConfig(launcher, 'color')) {
            dispatch(setLauncherStyleColor(launcher.color));
        }
        if (validateStyleConfig(messageSent, 'background')) {
            dispatch(setMessageSentStyleBackground(messageSent.background));
        }
        if (validateStyleConfig(messageSent, 'color')) {
            dispatch(setMessageSentStyleColor(messageSent.color));
        }
        if (validateStyleConfig(messageReceived, 'background')) {
            dispatch(setMessageReceivedStyleBackground(messageReceived.background));
        }
        if (validateStyleConfig(messageReceived, 'color')) {
            dispatch(setMessageReceivedStyleColor(messageReceived.color));
        }
        if (validateStyleConfig(dial, 'background')) {
            dispatch(setDialStyleBackground(dial.background));
        }
        if (validateStyleConfig(dial, 'color')) {
            dispatch(setDialStyleColor(dial.color));
        }
        if (validateStyleConfig(call, 'background')) {
            dispatch(setCallStyleBackground(call.background));
        }
        if (validateStyleConfig(call, 'color')) {
            dispatch(setCallStyleColor(call.color));
        }
        if (validateStyleConfig(feedback, 'body')) {
            dispatch(setFeedbackStyleBody(feedback.body));
        }
        if (validateStyleConfig(feedback, 'buttons')) {
            dispatch(setFeedbackStyleButtons(feedback.buttons));
        }
    }
}

export default {
    setFontFamily,
    setHeaderStyleBackground,
    setHeaderStyleColor,
    setHeaderButtonStyleBackground,
    setHeaderButtonStyleColor,
    setBodyStyleBackground,
    setBodyStyleColor,
    setLauncherStyleBackground,
    setLauncherStyleColor,
    setMessageSentStyleBackground,
    setMessageReceivedStyleBackground,
    setMessageReceivedStyleColor,
    setDialStyleBackground,
    setDialStyleColor,
    setCallStyleBackground,
    handleConfigLayout,
    setMessageSentStyleColor
};
