// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-screen-share-extension{position:absolute;top:20%;left:50%;transform:translate(-50%, -20%);background:rgba(0,0,0,0.85);width:100%;height:100%;color:#fff;z-index:10}.Bandyer-screen-share-extension .Bandyer-install-container{margin-top:15%;text-align:center}.Bandyer-screen-share-extension .Bandyer-button-close{text-align:right}.Bandyer-screen-share-extension .Bandyer-install-button{margin-top:5%}\n", ""]);
// Exports
exports.locals = {
	"screen-share-extension": "Bandyer-screen-share-extension",
	"install-container": "Bandyer-install-container",
	"button-close": "Bandyer-button-close",
	"install-button": "Bandyer-install-button"
};
module.exports = exports;
