// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-screenshare-menu-popper{z-index:5;padding:10px;width:100%;max-width:490px;min-width:370px}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu{border-radius:8px;padding:35px;padding-bottom:25px}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menu-title{font-size:13px}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menuitem{display:flex;flex-direction:column;width:100%}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menuitem .Bandyer-primary-content{display:flex;height:30px;font-weight:500;flex-direction:row;justify-content:space-between}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menuitem .Bandyer-primary-content .Bandyer-screenshare-menu-icon{height:40px;width:40px}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menuitem .Bandyer-primary-content .Bandyer-screenshare-menu-icon path{fill:#000}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menuitem .Bandyer-secondary-content{white-space:pre-wrap;width:90%;font-size:13px;line-height:13px}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menuitem:hover .Bandyer-primary-content{color:#009fe3}.Bandyer-screenshare-menu-popper .Bandyer-screenshare-menu .Bandyer-screenshare-menuitem:hover .Bandyer-primary-content .Bandyer-screenshare-menu-icon path{fill:#009fe3}\n", ""]);
// Exports
exports.locals = {
	"screenshare-menu-popper": "Bandyer-screenshare-menu-popper",
	"screenshare-menu": "Bandyer-screenshare-menu",
	"screenshare-menu-title": "Bandyer-screenshare-menu-title",
	"screenshare-menuitem": "Bandyer-screenshare-menuitem",
	"primary-content": "Bandyer-primary-content",
	"screenshare-menu-icon": "Bandyer-screenshare-menu-icon",
	"secondary-content": "Bandyer-secondary-content"
};
module.exports = exports;
