import { InvalidConfigError, UserNotValidError } from './errorHandler';
import {
    CALL_TYPE_AUDIO_ONLY,
    CALL_TYPE_AUDIO_UPGRADABLE,
    CALL_TYPE_AUDIO_VIDEO,
    WIDGET_MODE_EMBED,
    WIDGET_MODE_WINDOW
} from '../constants';

const _isObject = obj => obj === Object(obj);

const validateInitWidgetConfig = (config) => {
    if (!config) {
        throw new InvalidConfigError('The initial config is empty');
    }
    if (!config.region || !['eu', 'in', 'us'].includes(config.region)) {
        throw new InvalidConfigError('The region is empty or not valid');
    }
    if (!config.appId) {
        throw new InvalidConfigError('Please specify the appId');
    }
    if (!config.environment) {
        throw new InvalidConfigError('The environment is empty or not valid');
    }
    if (config.hidden && typeof config.hidden !== 'boolean') {
        throw new InvalidConfigError('The hidden option has a not valid value, must be a boolean');
    }
    if (config.recordingType && (typeof config.recordingType !== 'string' || !['none', 'automatic', 'manual'].includes(config.recordingType))) {
        throw new InvalidConfigError('The recordingType option has a not valid value, must one of `none`, `manual`, `automatic`');
    }
    if (config.layout && !_isObject(config.layout)) {
        throw new InvalidConfigError('The layout option has a not valid value, must be an object');
    }
    if (config.callType && typeof config.callType !== 'string') {
        throw new InvalidConfigError('The callType option has a not valid value, must be a string');
    }
    if (config.mode && typeof config.mode !== 'string') {
        throw new InvalidConfigError('The mode option has a not valid value, must be a string');
    }
    if (config.language && typeof config.language !== 'string') {
        throw new InvalidConfigError('The language option has a not valid value, must be a string');
    }
    if (config.userDetailsProvider && typeof config.userDetailsProvider !== 'function') {
        throw new InvalidConfigError('The user details provider option has a not valid value, must be a function');
    }
    if (config.userDetailsFormatter && typeof config.userDetailsFormatter !== 'function') {
        throw new InvalidConfigError('The user details formatter option has a not valid value, must be a function');
    }
    if (config.tools && !_isObject(config.tools)) {
        throw new InvalidConfigError('The tools option has a not valid value, must be a valid object');
    }
    if (config.virtualBackground && config.virtualBackground !== 'image' && config.virtualBackground !== 'blur') {
        throw new InvalidConfigError('The virtualBackground option must be one of image or blur');
    }
    if (config.isAdmin && typeof config.isAdmin !== 'boolean') {
        throw new InvalidConfigError('The isAdmin option must be a boolean value');
    }
    if (config.feedback && typeof config.feedback !== 'boolean') {
        throw new InvalidConfigError('The feedback option must be a boolean value');
    }
    return config;
};

const validateAddChannelConfig = (user, localUser, options) => {
    // Per ora è solo così, quando avrò le options dovrò convalidare anche quelle
    if (user && (typeof user === 'string' || user instanceof String)) {
        if (user === localUser) {
            throw new UserNotValidError('The user specified is the same who is authenticated in the widget.');
        }
        return { user, options };
    }
    throw new UserNotValidError(`The user specified to init the chat is not valid. Value: ${user}`);
};

/**
 * This fn checks if there are the property background e color
 * @param config
 * @returns {{color}|{background}|Object}
 */
const validateStyleConfig = (config, property) => {
    // se contiene la proprietà fontFamily, la setta altrimenti va avanti
    let toReturn = false;
    if (!config || !property) {
        return toReturn;
    }
    if (
        _isObject(config)
        && config[property]
        && (typeof config[property] === 'string' || config[property] instanceof String)
    ) {
        toReturn = true;
    }
    return toReturn;
};

const validateCallTypeConfig = (callType = CALL_TYPE_AUDIO_VIDEO) => {
    let toReturn = CALL_TYPE_AUDIO_VIDEO;
    if (callType === CALL_TYPE_AUDIO_ONLY) {
        toReturn = CALL_TYPE_AUDIO_ONLY;
    } else if (callType === CALL_TYPE_AUDIO_UPGRADABLE) {
        toReturn = CALL_TYPE_AUDIO_UPGRADABLE;
    }
    return toReturn;
};

const validateModeConfig = (mode = WIDGET_MODE_EMBED) => (mode === WIDGET_MODE_WINDOW ? WIDGET_MODE_WINDOW : WIDGET_MODE_EMBED);

const validateLanguageConfig = (lang) => {
    if (lang === 'en' || lang === 'it') {
        return lang;
    }
    const detectedLanguage = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
    const languageWithoutRegionCode = detectedLanguage.toLowerCase().split(/[_-]+/)[0];
    if (languageWithoutRegionCode === 'en' || languageWithoutRegionCode === 'it') {
        return languageWithoutRegionCode;
    }
    // default
    return 'en';
};

const validateToolsConfig = (tools) => {
    const availableTools = [
        'chat',
        'screen_sharing',
        'file_upload',
        'live_edit',
        'snapshot',
        'whiteboard',
        'live_pointer'
    ];
    const availableWbTools = ['wb_add_file', 'wb_cursor', 'wb_text', 'wb_shape', 'wb_pen', 'wb_eraser'];
    const toReturn = {};

    availableTools.forEach((t) => {
        toReturn[t] = !!tools[t];
    });
    if (toReturn.whiteboard) {
        toReturn.whiteboard = {};
        const allEnabled = typeof tools.whiteboard === 'boolean';
        availableWbTools.forEach((wbT) => {
            toReturn.whiteboard[wbT] = allEnabled || !!tools.whiteboard[wbT];
        });
    }

    return toReturn;
};

export {
    validateAddChannelConfig,
    validateInitWidgetConfig,
    validateModeConfig,
    validateStyleConfig,
    validateCallTypeConfig,
    validateLanguageConfig,
    validateToolsConfig
};
