// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-bandyer-audio-visualizer{height:30px}.Bandyer-bandyer-audio-visualizer>div.Bandyer-audio-visualizer-item{display:inline-block;width:4px;height:30px;margin:0 3px;border-radius:4px;background:currentColor;background-color:#0c2e41}.Bandyer-bandyer-audio-visualizer.Bandyer-error{color:#f7451d;min-width:20em;max-width:30em;margin:0 auto;white-space:pre-line}\n", ""]);
// Exports
exports.locals = {
	"bandyer-audio-visualizer": "Bandyer-bandyer-audio-visualizer",
	"audio-visualizer-item": "Bandyer-audio-visualizer-item",
	"error": "Bandyer-error"
};
module.exports = exports;
