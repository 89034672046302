/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import Style from './style.scss';


class TermsRequiredView extends Component {
    constructor(props) {
        super(props);
        const { terms } = this.props;
        this.state = {
            onAgreeClicked: false,
            termsMap: new Map(Object.entries(terms)),
            termsObtained: ''
        };
    }


    onClickAgree(termsType) {
        const { termsObtained, termsMap } = this.state;
        const terms = termsObtained.concat(`${termsType};`);
        this.setState({ onAgreeClicked: true });

        if (termsMap.size === 1) {
            const { onAgree } = this.props;
            onAgree(terms);
        } else {
            termsMap.delete(termsType);
            this.setState({ termsMap, onAgreeClicked: false, termsObtained: terms });
        }
    }

    render() {
        const { onDisagree } = this.props;
        const { onAgreeClicked, termsMap } = this.state;
        return (
            <div className={Style['terms-container']}>
                <>
                    <p className={Style['terms-header']}>
                        {termsMap.entries().next().value[1].title}
                    </p>
                    <div className={Style['terms-content']}>
                        {termsMap.entries().next().value[1].body}
                    </div>

                    <div className={Style['main-controls']}>
                        <Button
                            aria-label="disagree"
                            disabled={onAgreeClicked}
                            onClick={onDisagree}
                            className={Style.disagreeButton}
                        >
                            {termsMap.entries().next().value[1].disagree}
                        </Button>
                        <Button
                            disabled={onAgreeClicked}
                            aria-label="agree"
                            className={Style.agreeButton}
                            onClick={() => this.onClickAgree(termsMap.entries().next().value[0])}
                        >
                            {onAgreeClicked && <CircularProgress className={Style.circularProgress} size={15} />}
                            {!onAgreeClicked && <span>{termsMap.entries().next().value[1].agree}</span>}
                        </Button>
                    </div>
                </>
            </div>
        );
    }
}

TermsRequiredView.propTypes = {
    terms: PropTypes.object,
    onAgree: PropTypes.func,
    onDisagree: PropTypes.func
};

TermsRequiredView.defaultProps = {
    terms: null,
    onAgree: null,
    onDisagree: null
};

export default connect(store => ({ terms: store.behavior.get('terms') }))(TermsRequiredView);
