import {
    getChannelTextArea,
    selectChat,
    setChannelTextArea
} from '../../../store/actions/dispatcher';
import Channel from './Channel';
import { buildChannelUniqueName } from '../../../helpers/utils';

let channelUniqueName = null;
export default class ChannelView {
    constructor(uniqueName) {
        channelUniqueName = uniqueName;
        this._channel = new Channel(uniqueName);
    }

    select = () => {
        selectChat(channelUniqueName);
    }

    set textArea(message) {
        setChannelTextArea(channelUniqueName, message);
    }

    get textArea() {
        return getChannelTextArea(channelUniqueName);
    }


    get channel() {
        return this._channel;
    }
}
