import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { Map } from 'immutable';
import { Fab, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Style from '../../style.scss';

class ScreenShare extends PureComponent {
    render() {
        const { screenPublished, toggleScreen, headerStyle } = this.props;
        const ToolTipEnableScreenShare = (
            <FormattedMessage id="Call.ToolTipEnableScreenShare" defaultMessage="Share screen" />
        );
        const ToolTipDisableScreenShare = (
            <FormattedMessage id="Call.ToolTipDisableScreenShare" defaultMessage="Disable screen share" />
        );
        return (
            <Tooltip title={screenPublished ? ToolTipDisableScreenShare : ToolTipEnableScreenShare}>
                <Fab
                    size="small"
                    style={{
                        background: headerStyle.get('button').get('background'),
                        color: headerStyle.get('button').get('color')
                    }}
                    className={Style.callTopButtons}
                    onClick={toggleScreen}
                >
                    {screenPublished ? (
                        <StopScreenShareIcon style={{ color: headerStyle.get('button').get('color') }} />
                    ) : (
                        <ScreenShareIcon style={{ color: headerStyle.get('button').get('color') }} />
                    )}
                </Fab>
            </Tooltip>
        );
    }
}

ScreenShare.propTypes = {
    screenPublished: PropTypes.bool,
    toggleScreen: PropTypes.func,
    headerStyle: PropTypes.instanceOf(Map)
};

ScreenShare.defaultProps = {
    screenPublished: false,
    toggleScreen: null,
    headerStyle: Map({})
};

export default ScreenShare;
