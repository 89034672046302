import { answerCall, declineCall, disconnectCall, hangUpCall } from '../../../store/actions/dispatcher';
import BandyerSDK from '../../index';
import { ExposeError } from '../../../helpers/errorHandler';

const events = require('events');

let call = null;
export default class Call extends events {
    constructor(createdCall) {
        super();
        call = createdCall;
        this._recordingState = 'stopped';
    }

    answer = async() => {
        if (this.status === 'dialing' && this.direction === 'incoming') {
            return answerCall(call.callAlias);
        }
        throw new ExposeError({ code: 'invalid_call_status', message: 'The call is not in dialing phase or is outgoing' });
    }

    end = async() => {
        if (this.status === 'connected' || this.status === 'connecting') {
            return disconnectCall(call.callAlias);
        }
        if (this.status === 'dialing' && this.direction === 'incoming') {
            return declineCall(call.callAlias);
        }
        if (this.status === 'dialing' && this.direction === 'outgoing') {
            return hangUpCall(call.callAlias);
        }
        throw new ExposeError({ code: 'invalid_call_status', message: 'Unable to end the call' });
    }

    get roomId() {
        return call.callAlias;
    }

    get options() {
        return {
            callType: call.callOptions.callType,
            live: call.callOptions.live,
            recordingType: call.callOptions.recording
        };
    }

    get recordingState() {
        return this._recordingState;
    }

    get creationDate() {
        return call.callOptions.creationDate;
    }

    get participants() {
        return call.callParticipants.map(participant => participant.user.userAlias);
    }

    get direction() {
        return call.callDirection === 'call_direction_incoming' ? 'incoming' : 'outgoing';
    }

    get status() {
        if (call) {
            return BandyerSDK.getInstance().switchboard.getCall(call.callAlias).callStatus;
        }
        return 'ended';
    }

    get endReason() {
        if (call) {
            return BandyerSDK.getInstance().switchboard.getCall(call.callAlias).endReason || null;
        }
        return 'hang_up';
    }
}
