const UPDATE_CALL = 'UPDATE_CALL';
const PUBLISH_VIDEO = 'PUBLISH_VIDEO';
const PUBLISH_SCREEN = 'PUBLISH_SCREEN';
const SUBSCRIBE_STREAM = 'SUBSCRIBE_STREAM';
const CHANGE_MAIN_VIDEO = 'CHANGE_MAIN_VIDEO';
const CHANGE_MUTE_AUDIO = 'CHANGE_MUTE_AUDIO';
const CHANGE_MUTE_VIDEO = 'CHANGE_MUTE_VIDEO';
const SET_EXTENSION_URL = 'SET_EXTENSION_URL';
const SHOW_EXTENSION_ALERT = 'SHOW_EXTENSION_ALERT';
const SET_FULL_SCREEN_MODE = 'SET_FULL_SCREEN_MODE';
const VIDEO_HAS_TRACK_AUDIO = 'VIDEO_HAS_TRACK_AUDIO';
const VIDEO_HAS_TRACK_VIDEO = 'VIDEO_HAS_TRACK_VIDEO';
const SET_MANTAIN_CALL = 'SET_MANTAIN_CALL';
const MEDIADEVICES = 'MEDIADEVICES';
const SELECT_AUDIO_DEVICE = 'SELECT_AUDIO_DEVICE';
const SELECT_VIDEO_DEVICE = 'SELECT_VIDEO_DEVICE';
const SET_CAMERA_PERMISSION_DENIED = 'SET_CAMERA_PERMISSION_DENIED';
const SET_MICROPHONE_PERMISSION_DENIED = 'SET_MICROPHONE_PERMISSION_DENIED';
const SET_MEDIA_STREAM = 'SET_MEDIA_STREAM';
const SET_STREAM_ENDED = 'SET_STREAM_ENDED';
const SET_STREAM_FAILED = 'SET_STREAM_FAILED';
const SET_RECONNECTING_CALL = 'SET_RECONNECTING_CALL';
const SET_ERROR = 'SET_ERROR';
const SET_VERIFICATION = 'SET_VERIFICATION';
const SET_REMOTE_MUTED = 'SET_REMOTE_MUTED';
const SET_FILE_TO_DOWNLOAD = 'SET_FILE_TO_DOWNLOAD';
const SET_FILE_UPLOADED = 'SET_FILE_UPLOADED';
const SET_FILES_TO_DOWNLOAD = 'SET_FILES_TO_DOWNLOAD';
const SET_FILES_TO_UPLOAD = 'SET_FILES_TO_UPLOAD';
const REMOVE_FILE_UPLOADED = 'REMOVE_FILE_UPLOADED';
const UPDATE_RECORDING_INFO = 'UPDATE_RECORDING_INFO';
const UPDATE_RECORDING_IN_PROGRESS = 'UPDATE_RECORDING_IN_PROGRESS';
const SET_IS_ADMIN = 'SET_IS_ADMIN';
const SET_VIRTUAL_BACKGROUNDS = 'SET_VIRTUAL_BACKGROUNDS';
const SHOW_REQUEST_PLAY_PERMISSION = 'SHOW_REQUEST_PLAY_PERMISSION';
const SET_FAILED_MEDIA_ELEMENT = 'SET_FAILED_MEDIA_ELEMENT';
const SET_RECORDING_ERROR = 'SET_RECORDING_ERROR';
const SHOW_MUTE_BY_ADMIN = 'SHOW_MUTE_BY_ADMIN';
const UPDATE_PARTICIPANTS_STATE_IN_ROOM = 'UPDATE_PARTICIPANTS_STATE_IN_ROOM';

export default {
    UPDATE_CALL,
    PUBLISH_VIDEO,
    PUBLISH_SCREEN,
    SUBSCRIBE_STREAM,
    CHANGE_MAIN_VIDEO,
    CHANGE_MUTE_AUDIO,
    CHANGE_MUTE_VIDEO,
    SET_EXTENSION_URL,
    SHOW_EXTENSION_ALERT,
    SET_FULL_SCREEN_MODE,
    VIDEO_HAS_TRACK_AUDIO,
    VIDEO_HAS_TRACK_VIDEO,
    SET_MANTAIN_CALL,
    MEDIADEVICES,
    SELECT_AUDIO_DEVICE,
    SELECT_VIDEO_DEVICE,
    SET_CAMERA_PERMISSION_DENIED,
    SET_MICROPHONE_PERMISSION_DENIED,
    SET_MEDIA_STREAM,
    SET_STREAM_ENDED,
    SET_STREAM_FAILED,
    SET_RECORDING_ERROR,
    SET_RECONNECTING_CALL,
    SET_ERROR,
    SET_VERIFICATION,
    SET_REMOTE_MUTED,
    SET_FILE_TO_DOWNLOAD,
    SET_FILE_UPLOADED,
    SET_FILES_TO_DOWNLOAD,
    SET_FILES_TO_UPLOAD,
    REMOVE_FILE_UPLOADED,
    UPDATE_RECORDING_INFO,
    UPDATE_RECORDING_IN_PROGRESS,
    SET_IS_ADMIN,
    SET_VIRTUAL_BACKGROUNDS,
    SHOW_REQUEST_PLAY_PERMISSION,
    SET_FAILED_MEDIA_ELEMENT,
    SHOW_MUTE_BY_ADMIN,
    UPDATE_PARTICIPANTS_STATE_IN_ROOM
};
