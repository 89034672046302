// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader-hidden{display:none}.loader-active{display:block}.Bandyer-header{border-radius:8px 8px 0 0;padding:20px;color:#fff;display:flex;z-index:99;height:100px;justify-content:space-between;align-items:center}.Bandyer-header .Bandyer-header-body{display:flex;align-items:center}.Bandyer-header .Bandyer-header-body .Bandyer-username{padding-left:5px}.Bandyer-header .Bandyer-header-body .Bandyer-username .Bandyer-userNameLabel{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:150px;font-size:1rem !important;margin-bottom:5px}.Bandyer-header .Bandyer-header-body .Bandyer-username span{font-size:10px;display:block}.Bandyer-header .Bandyer-header-body .Bandyer-username span::first-letter{text-transform:capitalize}.Bandyer-header .Bandyer-action-buttons{display:flex}.full-screen .Bandyer-header{flex-shrink:0;width:100%}.full-screen .Bandyer-title{padding:0 0 15px 0}.full-screen .Bandyer-close-button{background-color:#0C2E41;border:0;display:block;position:absolute;right:10px;top:20px;width:40px}.full-screen .Bandyer-close{width:20px;height:20px}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-header{flex-shrink:0;width:100%}.Bandyer-header .Bandyer-header-body .Bandyer-username .Bandyer-userNameLabel{font-size:1.5rem}.Bandyer-title{padding:0 0 15px 0}.Bandyer-close-button{background-color:#0C2E41;border:0;display:block;position:absolute;right:10px;top:20px;width:40px}.Bandyer-close{width:20px;height:20px}}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"header": "Bandyer-header",
	"header-body": "Bandyer-header-body",
	"username": "Bandyer-username",
	"userNameLabel": "Bandyer-userNameLabel",
	"action-buttons": "Bandyer-action-buttons",
	"title": "Bandyer-title",
	"close-button": "Bandyer-close-button",
	"close": "Bandyer-close"
};
module.exports = exports;
