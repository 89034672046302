import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Mic from '@material-ui/icons/Mic';
import CallEnd from '@material-ui/icons/CallEnd';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import { Fab, Tooltip } from '@material-ui/core';
import { intlShape, injectIntl } from 'react-intl';
import {
    CALL_TYPE_AUDIO_ONLY,
    CALL_TYPE_AUDIO_UPGRADABLE,
    CALL_TYPE_AUDIO_VIDEO
} from '../../../../../../../../constants';
import Style from './style.scss';

class Footer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { videoIcon: true };
    }

    componentDidMount() {
        const { call, allowCamera } = this.props;
        switch (call.get('callType')) {
            case CALL_TYPE_AUDIO_VIDEO:
                // se non ha preso la webcam quale icona faccio vedere?
                // se ha can video a false?
                if (allowCamera) {
                    this.setState({ videoIcon: true });
                    return;
                }
                this.setState({ videoIcon: false });
                break;
            case CALL_TYPE_AUDIO_UPGRADABLE:
            case CALL_TYPE_AUDIO_ONLY:
                this.setState({ videoIcon: false });
                break;
            default:
        }
    }

    renderAudioIcon = () => {
        const { audioMuted, videoHasAudioTrack } = this.props;
        return !audioMuted && videoHasAudioTrack ? <Mic /> : <MicOff />;
    };

    renderVideoIcon = () => {
        const { videoMuted, videoHasVideoTrack } = this.props;
        return !videoMuted && videoHasVideoTrack ? <Videocam /> : <VideocamOff />;
    };

    toggleWebcam = async() => {
        const { call, toggleVideo } = this.props;
        const result = await toggleVideo();
        switch (call.get('callType')) {
            case CALL_TYPE_AUDIO_UPGRADABLE:
                this.setState({ videoIcon: result });
                break;
            default:
        }
    };

    disconnectCall = async() => {
        const { call, hangUp } = this.props;
        /* Call only hungUp, the hang up handle also the changeView */
        hangUp(call.get('callAlias'));
    };

    render() {
        const { toggleAudio, cameraPermissionDenied, microphonePermissionDenied, call, allowCamera, intl } = this.props;
        const AllowCameraLabel = intl.formatMessage({
            id: 'Call.AllowCamera',
            defaultMessage: 'Hardware error'
        });
        return (
            <div className={Style['footer-call-container']}>
                <div className={Style.buttons}>
                    <Fab
                        size="small"
                        style={
                            microphonePermissionDenied
                                ? { backgroundColor: '#e63247' }
                                : { backgroundColor: 'rgba(0, 0, 0, 0.45)' }
                        }
                        color="primary"
                        aria-label="Mic"
                        onClick={toggleAudio}
                        className={`${Style['main-controls']} ${Style['toggle-audio']}`}
                    >
                        {this.renderAudioIcon()}
                    </Fab>
                    <Fab
                        size="medium"
                        color="primary"
                        aria-label="hangup"
                        onClick={this.disconnectCall}
                        className={Style['hangup-call']}
                    >
                        <CallEnd />
                    </Fab>
                    {call.get('callType') !== CALL_TYPE_AUDIO_ONLY && (
                        <Tooltip
                            placement="top-start"
                            PopperProps={{ style: { maxWidth: 155 } }}
                            title={allowCamera ? '' : AllowCameraLabel}
                        >
                            <div className={Style['camera-button-container']}>
                                <Fab
                                    size="small"
                                    style={
                                        cameraPermissionDenied
                                            ? { backgroundColor: '#e63247' }
                                            : { backgroundColor: 'rgba(0, 0, 0, 0.45)' }
                                    }
                                    color="primary"
                                    aria-label="Videocam"
                                    disabled={!allowCamera}
                                    onClick={this.toggleWebcam}
                                    className={Style['main-controls toggle-video']}
                                >
                                    {this.renderVideoIcon()}
                                </Fab>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    }
}

Footer.propTypes = {
    audioMuted: PropTypes.bool,
    videoMuted: PropTypes.bool,
    hangUp: PropTypes.func,
    toggleAudio: PropTypes.func,
    toggleVideo: PropTypes.func,
    allowCamera: PropTypes.bool,
    call: PropTypes.instanceOf(Map),
    videoHasAudioTrack: PropTypes.bool,
    videoHasVideoTrack: PropTypes.bool,
    cameraPermissionDenied: PropTypes.bool,
    microphonePermissionDenied: PropTypes.bool,
    intl: intlShape.isRequired
};

Footer.defaultProps = {
    audioMuted: false,
    videoMuted: false,
    hangUp: null,
    toggleAudio: null,
    toggleVideo: null,
    allowCamera: true,
    call: {},
    videoHasAudioTrack: false,
    videoHasVideoTrack: false,
    cameraPermissionDenied: false,
    microphonePermissionDenied: false
};

export default injectIntl(Footer);
