import actions from './types';

/** *********** SET STATE ********* */

function updateUsersDetails(userDetails) {
    return {
        type: actions.UPDATE_USERS_DETAILS,
        userDetails
    };
}

function setOnlineUsers(onlineUsers) {
    return {
        type: actions.SET_USER_STATUS,
        onlineUsers
    };
}

function updateOnlineUsers(userAlias, status) {
    return {
        type: actions.UPDATE_USER_STATUS,
        userAlias,
        status
    };
}

export default { updateUsersDetails, setOnlineUsers, updateOnlineUsers };
