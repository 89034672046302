export default class Message {
    constructor(channel, message) {
        this._channel = channel;
        this._messageId = message.messageId;
        this._text = message.message;
        this._sender = message.who;
        this._timestamp = message.timestamp;
    }

    get messageId() {
        return this._messageId;
    }

    get channel() {
        return this._channel;
    }

    get sender() {
        return this._sender;
    }

    get text() {
        return this._text;
    }

    get creationDate() {
        return new Date(this._timestamp).toISOString();
    }
}
