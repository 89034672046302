import { Map } from 'immutable';
import actionTypes from './types';
import { CALL_TYPE_AUDIO_VIDEO, CHANNELS, WIDGET_MODE_EMBED } from '../../../constants';

const initialState = Map({
    widgetReady: false,
    wasClose: false,
    wasHide: false,
    showChat: false,
    haveChat: true,
    hideWidget: false,
    recording: 'none',
    isAdmin: false,
    disabledInput: false,
    fullScreenMode: false,
    selectedChannel: '',
    localUser: Map({}),
    isLoading: false,
    call: Map({}),
    view: CHANNELS,
    widgetCallType: CALL_TYPE_AUDIO_VIDEO,
    allowCamera: true,
    mode: WIDGET_MODE_EMBED,
    language: 'en',
    virtualBackground: { enable: false, type: 'blur' },
    chat: false,
    callTools: {
        chat: false,
        screen_sharing: false,
        file_upload: false,
        snapshot: false,
        live_edit: false,
        live_pointer: false,
        whiteboard: {
            wb_add_file: false,
            wb_cursor: false,
            wb_text: false,
            wb_shape: false,
            wb_pen: false,
            wb_eraser: false
        }
    },
    feedbackInfo: { enabled: false, jwt: null, sessionAlias: null },
    connectionInfo: { region: null, environment: null, appId: null },
    terms: null
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TOGGLE_CHAT: {
            return state.update('showChat', showChat => !showChat);
        }
        case actionTypes.TOGGLE_FULL_SCREEN: {
            return state.update('fullScreenMode', fullScreenMode => !fullScreenMode);
        }
        case actionTypes.SET_FULL_SCREEN_MODE: {
            return state.update('fullScreenMode', () => action.active);
        }
        case actionTypes.SET_CHAT_OPEN: {
            return state.update('showChat', () => true);
        }
        case actionTypes.SET_CHAT_CLOSE: {
            return state.update('showChat', () => false);
        }
        case actionTypes.HIDE_CHAT: {
            return state.update('hideWidget', () => action.hide);
        }
        case actionTypes.SET_RECORDING_VALUE: {
            return state.update('recording', () => action.recording);
        }
        case actionTypes.SET_ADMIN: {
            return state.update('isAdmin', () => action.isAdmin);
        }
        case actionTypes.TOGGLE_INPUT_DISABLED: {
            return state.update('disabledInput', disabledInput => !disabledInput);
        }
        case actionTypes.SELECT_CHANNEL: {
            return state.update('selectedChannel', () => action.selectedChannel);
        }
        case actionTypes.SET_LOCAL_USER_INFO: {
            return state.update('localUser', () => action.localUserInfo);
        }
        case actionTypes.WIDGET_READY: {
            return state.update('widgetReady', () => action.widgetReady);
        }
        case actionTypes.CHAT_IS_LOADING: {
            return state.update('isLoading', () => action.isLoading);
        }
        case actionTypes.CHANGE_VIEW: {
            return state.update('view', () => action.view);
        }
        case actionTypes.SET_TERMS: {
            return state.update('terms', () => action.terms);
        }
        case actionTypes.UPDATE_CALL: {
            return state.update('call', () => action.call);
        }
        case actionTypes.SET_WIDGET_CALL_TYPE: {
            return state.update('widgetCallType', () => action.widgetCallType);
        }
        case actionTypes.SET_ALLOW_CAMERA: {
            return state.update('allowCamera', () => action.allowCamera);
        }
        case actionTypes.SET_WIDGET_MODE: {
            return state.update('mode', () => action.widgetMode);
        }
        case actionTypes.SET_LANGUAGE: {
            return state.update('language', () => action.language);
        }
        case actionTypes.HAVE_CHAT: {
            return state.update('haveChat', () => action.haveChat);
        }
        case actionTypes.WAS_CLOSE: {
            return state.update('wasClose', () => action.wasClose);
        }
        case actionTypes.WAS_HIDE: {
            return state.update('wasHide', () => action.wasHide);
        }
        case actionTypes.SET_CHAT_INIT: {
            return state.update('chat', () => action.chat);
        }
        case actionTypes.SET_CALL_TOOLS: {
            return state.update('callTools', () => action.callTools);
        }
        case actionTypes.SET_VIRTUAL_BACKGROUND: {
            return state.update('virtualBackground', () => action.virtualBackground);
        }
        case actionTypes.SET_FEEDBACK_INFO: {
            let feedbackInfo = state.get('feedbackInfo');
            feedbackInfo = { ...feedbackInfo, ...action.feedbackInfo };
            return state.update('feedbackInfo', () => feedbackInfo);
        }
        case actionTypes.SET_FEEDBACK_ENABLED: {
            const feedbackInfo = state.get('feedbackInfo');
            feedbackInfo.enabled = action.enabled;
            return state.update('feedbackInfo', () => feedbackInfo);
        }
        case actionTypes.SET_CONNECTION_INFO: {
            return state.update('connectionInfo', () => action.connectionInfo);
        }
        default:
            return state;
    }
}
