import Call from './Call';
import { selectCall } from '../../../store/actions/dispatcher';

export default class CallView {
    constructor(call) {
        this._call = new Call(call);
    }

    get call() {
        return this._call;
    }

    select = () => {
        selectCall();
    }
}
