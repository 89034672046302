// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-terms-container{width:490px;min-width:360px;height:380px;max-height:490px;background-color:#ffffff;border-radius:8px;display:flex;flex-direction:column;align-items:center}.Bandyer-terms-container .Bandyer-terms-header{width:100%;padding-left:30px;padding-top:15px;font-size:1.5em;justify-content:left}.Bandyer-terms-container .Bandyer-terms-content{height:100%;justify-content:left;padding:0px 30px;display:flex;flex-direction:column;align-items:center;overflow-y:auto;flex-grow:0;margin-bottom:30px}.Bandyer-terms-container .Bandyer-main-controls{margin-bottom:30px;display:flex;width:50%;justify-content:space-around}.Bandyer-terms-container .Bandyer-main-controls .Bandyer-agreeButton{color:#FFFFFF;text-transform:capitalize;background-color:#24292E}.Bandyer-terms-container .Bandyer-main-controls .Bandyer-disagreeButton{text-transform:capitalize;color:#92979D}.Bandyer-terms-container .Bandyer-main-controls .Bandyer-circularProgress{color:#FFFFFF}.Bandyer-terms-container .Bandyer-closeButton{position:absolute;right:5px}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-terms-container{max-height:100%;height:100%;margin:0;max-width:none;width:100%;min-width:auto;min-height:auto}}\n", ""]);
// Exports
exports.locals = {
	"terms-container": "Bandyer-terms-container",
	"terms-header": "Bandyer-terms-header",
	"terms-content": "Bandyer-terms-content",
	"main-controls": "Bandyer-main-controls",
	"agreeButton": "Bandyer-agreeButton",
	"disagreeButton": "Bandyer-disagreeButton",
	"circularProgress": "Bandyer-circularProgress",
	"closeButton": "Bandyer-closeButton"
};
module.exports = exports;
