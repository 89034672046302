// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-feedback-container{width:490px;min-width:360px;height:380px;max-height:490px;background-color:#ffffff;border-radius:8px;display:flex;flex-direction:column;align-items:center}.Bandyer-feedback-container .Bandyer-call-rating-header{padding-top:15px;font-size:1.5em}.Bandyer-feedback-container .Bandyer-rating-content{padding-top:20px;display:flex;flex-direction:column;align-items:center}.Bandyer-feedback-container .Bandyer-rating-content .Bandyer-call-rating-label{font-size:1.2em;font-weight:bold}.Bandyer-feedback-container .Bandyer-rating-content .Bandyer-stars label{padding-right:15px}.Bandyer-feedback-container .Bandyer-rating-content .Bandyer-comment-area{min-width:350px}.Bandyer-feedback-container .Bandyer-sendButton{margin-top:40px}.Bandyer-feedback-container .Bandyer-closeButton{position:absolute;right:5px}.Bandyer-feedback-container .Bandyer-call-rating-success-title1{font-size:1.2em;font-weight:bold;padding-top:100px;text-align:center;width:350px}.Bandyer-feedback-container .Bandyer-call-rating-success-title2{font-size:1em;color:#7A7A7A}\n", ""]);
// Exports
exports.locals = {
	"feedback-container": "Bandyer-feedback-container",
	"call-rating-header": "Bandyer-call-rating-header",
	"rating-content": "Bandyer-rating-content",
	"call-rating-label": "Bandyer-call-rating-label",
	"stars": "Bandyer-stars",
	"comment-area": "Bandyer-comment-area",
	"sendButton": "Bandyer-sendButton",
	"closeButton": "Bandyer-closeButton",
	"call-rating-success-title1": "Bandyer-call-rating-success-title1",
	"call-rating-success-title2": "Bandyer-call-rating-success-title2"
};
module.exports = exports;
