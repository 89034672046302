import { constants } from 'bandyersdkcommon';
import { Map } from 'immutable';
import {
    DIAL_IN,
    DIAL_OUT,
    GENERIC_CALL_ERROR,
    GUM_ERROR,
    MULTIPLE_SOCKET_ERROR,
    USERS_BUSY_ERROR,
    INVALID_PERMISSION_CAN_VIDEO,
    INITIATOR_NOT_AVAILABLE,
    ANSWER_CALL_ERROR,
    ACTION_ALREADY_TAKEN,
    PUBLISH_STREAM_NOT_VALID,
    SOCKET_CALL_ERROR,
    JOIN_CALL_REQUEST_FAILED,
    JOIN_CALL_INVALID_URL,
    CALL,
    JOIN_CALL_INVALID_MTM,
    CHANNELS,
    CONVERSATION,
    ERROR, WIDGET_MODE_WINDOW
} from '../../../../../../../constants';
import { buildUserInfoFromImmutable } from '../../../../../../../helpers/utils';
import Logger from '../../../../../../../logger';
import BandyerSDK from '../../../../../../../services';
import widgetOperations from '../../../../../redux/operations';
import errorOperations from '../../../../../../errors/redux/operations';
import actions from './actions';
import store from '../../../../../../../store/store';
import * as dispatcher from '../../../../../../../store/actions/dispatcher';
import { ExposeError } from '../../../../../../../helpers/errorHandler';

const {
    updateCall,
    resetVideo,
    resetAudio,
    publishScreen,
    publishWebcam,
    setFullScreenMode,
    showExtensionAlert,
    toggleAudio,
    toggleVideo,
    changeMainVideo,
    setVideoHasAudioTrack,
    setVideoHasVideoTrack,
    enumerateDevices,
    selectAudioDevice,
    selectVideoDevice,
    setCameraPermissionDenied,
    setMicrophonePermissionDenied,
    setMediaStream,
    setStreamEndedError,
    setStreamFailedError,
    setReconnectingCall,
    setError,
    setVerification,
    setRemoteVideoMuted,
    addFile,
    setFileUploaded,
    updateDownloadFile,
    setFilesDownload,
    setFilesUpload,
    removeFileUploaded,
    updateRecordingInfo,
    updateRecordingInProgress,
    setIsAdmin,
    setVirtualBackgrounds,
    showRequestPlayPermission,
    setFailedMediaElements,
    setRecordingError,
    showMuteByAdmin,
    updateParticipantsStateInRoom
} = actions;

function createCall(callee, options = {}) {
    return async(dispatch) => {
        try {
            const { behavior } = store.getState();
            if (!behavior.get('call').isEmpty()) {
                throw new ExposeError({ code: 'another_call_in_progress', message: 'Another call in progress' });
            }
            if (behavior.get('view') === ERROR) {
                dispatch(widgetOperations.changeView(CHANNELS));
            }
            const call = await BandyerSDK.getInstance().switchboard.startCall(callee, options);
            Logger.debug('[createCall] - call: ', call);
            dispatch(updateCall(call));
            dispatch(widgetOperations.changeView(DIAL_OUT));
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            return call;
        } catch (error) {
            Logger.warn('[createCall] - Error: ', error);
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            switch (error.code) {
                case 'current_user_not_available':
                case 'current_user_busy':
                    dispatch(errorOperations.changeToViewError(INITIATOR_NOT_AVAILABLE));
                    break;
                case 'all_users_busy':
                    dispatch(errorOperations.changeToViewError(USERS_BUSY_ERROR));
                    break;
                case 'creator_has_not_video_permission':
                case 'no_user_has_video_permission':
                    dispatch(errorOperations.changeToViewError(INVALID_PERMISSION_CAN_VIDEO));
                    break;
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
            throw new ExposeError(error);
        }
    };
}

function joinCallURL(url) {
    return async(dispatch) => {
        try {
            const { behavior } = store.getState();
            if (!behavior.get('call').isEmpty()) {
                throw new ExposeError({ code: 'another_call_in_progress', message: 'Another call in progress' });
            }
            if (behavior.get('view') === ERROR) {
                dispatch(widgetOperations.changeView(CHANNELS));
            }
            const call = await BandyerSDK.getInstance().switchboard.joinCallURL(url);
            Logger.debug('[joinCallURL] - call: ', call);
            dispatch(updateCall(call));
            if (call.callStatus === 'dialing') {
                dispatch(widgetOperations.changeView(DIAL_OUT));
            } else {
                dispatch(widgetOperations.changeView(CALL));
            }
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            return call;
        } catch (error) {
            Logger.warn('[joinCallURL] - Error: ', error);
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            switch (error.code) {
                case 'all_users_busy':
                case 'current_user_busy':
                    dispatch(errorOperations.changeToViewError(JOIN_CALL_REQUEST_FAILED));
                    break;
                case 'invalid_url':
                case 'invalid_token':
                case 'user_not_associated_to_link':
                    dispatch(errorOperations.changeToViewError(JOIN_CALL_INVALID_URL));
                    break;
                case 'join_url_mtm':
                    dispatch(errorOperations.changeToViewError(JOIN_CALL_INVALID_MTM));
                    break;
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
            throw new ExposeError(error);
        }
    };
}

function handleIncomingCall(call) {
    return (dispatch) => {
        dispatch(widgetOperations.hideWidget(false));
        dispatch(updateCall(call));
        dispatch(widgetOperations.changeView(DIAL_IN));
        dispatch(widgetOperations.expandWidget());
    };
}

function answerTheCall(callAlias) {
    return async(dispatch) => {
        try {
            const currentCall = await BandyerSDK.getInstance().switchboard.answer(callAlias);
            dispatch(updateCall(currentCall));
        } catch (error) {
            Logger.warn('[Err in answerTheCall] - Err:', error);
            switch (error.code) {
                case 'dial_answer_error':
                    dispatch(errorOperations.changeToViewError(ANSWER_CALL_ERROR));
                    break;
                case ACTION_ALREADY_TAKEN:
                    dispatch(errorOperations.changeToViewError(ACTION_ALREADY_TAKEN));
                    break;
                default:
                    BandyerSDK.getInstance().call.disconnectCall(callAlias);
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
                    break;
            }
            throw new ExposeError(error);
        }
    };
}

function declineTheCall(callAlias) {
    return async(dispatch) => {
        try {
            await BandyerSDK.getInstance().switchboard.decline(callAlias);
            dispatch(widgetOperations.resetToChannelView());
            dispatch(updateCall({}));
            dispatch(setFullScreenMode(false));
            dispatch(resetAudio());
            dispatch(resetVideo());
        } catch (error) {
            Logger.warn('[Err in declineTheCall] - Err:', error);
            BandyerSDK.getInstance().call.disconnectCall(callAlias);
            dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            throw new ExposeError(error);
        }
    };
}

function hangUpTheCall(callAlias) {
    return async(dispatch) => {
        try {
            await BandyerSDK.getInstance().switchboard.hangUp(callAlias);
        } catch (e) {
            Logger.warn('[hangUpTheCall] - error:', e);
            throw new ExposeError(e);
        }
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function closePlugin(call) {
    if (call.get('callDirection') === 'call_direction_incoming') {
        return declineTheCall(call);
    }
    return hangUpTheCall(call);
}

function handleDeclinedCall() {
    return (dispatch) => {
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function closeError() {
    return (dispatch) => {
        const state = store.getState();
        const selectedChannel = state.behavior.get('selectedChannel');

        if (selectedChannel === '') {
            dispatch(widgetOperations.changeView(CHANNELS));
        } else {
            dispatch(widgetOperations.changeView(CONVERSATION));
        }
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function handleStoppedCall(call) {
    return async(dispatch) => {
        if (call) {
            await BandyerSDK.getInstance().call.disconnectCall(call.callAlias);
        }

        const state = store.getState();
        const isCalling = state.behavior.get('call').size;
        if (isCalling) {
            dispatch(widgetOperations.resetToChannelView());
        }
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function errorGum() {
    return (dispatch) => {
        dispatch(errorOperations.changeToViewError(GUM_ERROR));
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
    };
}

function multipleSocketError() {
    return (dispatch) => {
        dispatch(errorOperations.changeToViewError(MULTIPLE_SOCKET_ERROR));
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
    };
}

function errorCallSocket() {
    return (dispatch) => {
        dispatch(errorOperations.changeToViewError(SOCKET_CALL_ERROR));
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
    };
}

function focusWindowCall() {
    return () => BandyerSDK.getInstance().switchboard.focusWindowCall();
}

function closeWindowCall() {
    return (dispatch) => {
        BandyerSDK.getInstance().switchboard.closeWindowCall();
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

/** **********************************************************************************************************
 ************************************************ ROOM BANDYER KIT ********************************************
 *********************************************************************************************************** */

function publishWebcamStream(call) {
    return async(dispatch) => {
        try {
            dispatch(publishWebcam(false));
            await BandyerSDK.getInstance().call.publishStream(
                call.get('callAlias')
            );
            dispatch(publishWebcam(true));
        } catch (err) {
            Logger.warn('[Err in publishWebcamStream] - Err:', err);
            switch (err) {
                case PUBLISH_STREAM_NOT_VALID:
                    // Forse non va qua
                    // the stream is not valid but the user stays connected
                    break;
                case GUM_ERROR:
                    dispatch(errorOperations.changeToViewError(GUM_ERROR));
                    break;
                default:
                    BandyerSDK.getInstance().call.disconnectCall(call.get('callAlias'));
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
        }
    };
}

/**
 * This function handle two cases: if the local webcam has audio stream, it toggles the audio.
 * @param {*} call object
 */
function togglePublisherAudio(call) {
    return async(dispatch) => {
        if (BandyerSDK.getInstance().call.hasAudio()) {
            const result = BandyerSDK.getInstance().call.toggleAudio(call.get('callAlias'));
            dispatch(toggleAudio(!result));
        }
    };
}

function togglePublisherVideo(call) {
    return async(dispatch) => {
        const result = BandyerSDK.getInstance().call.toggleVideo(call.get('callAlias'));
        dispatch(toggleVideo(!result));
    };
}

function publisherHasAudio() {
    return async(dispatch) => {
        const result = BandyerSDK.getInstance().call.hasAudio();
        dispatch(setVideoHasAudioTrack(result));
    };
}

function publisherHasVideo() {
    return async(dispatch) => {
        const result = BandyerSDK.getInstance().call.hasVideo();
        dispatch(setVideoHasVideoTrack(result));
    };
}

function requestPermissionToUpgradeVideo(call) {
    return async() => BandyerSDK.getInstance().call.requestPermissionToUpgradeVideo(call);
}

function upgradeToPublisherWebcam(callAlias) {
    return async(dispatch) => {
        try {
            await BandyerSDK.getInstance().call.unpublishWebcam();
            await BandyerSDK.getInstance().call.publishWebCam(callAlias, { audio: true, video: true });
            dispatch(resetAudio());
            dispatch(resetVideo());
        } catch (err) {
            // todo da migliorare
            switch (err) {
                case GUM_ERROR:
                    dispatch(errorOperations.changeToViewError(GUM_ERROR));
                    break;
                case PUBLISH_STREAM_NOT_VALID:
                    break;
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
        }
    };
}

function disconnectTheCall(callAlias) {
    return async(dispatch) => {
        if (dispatcher.getWidgetMode() === WIDGET_MODE_WINDOW) {
            await BandyerSDK.getInstance().switchboard.closeWindowCall();
        } else {
            await BandyerSDK.getInstance().call.disconnectCall(callAlias);
        }
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function addFileToDownload(file) {
    return async(dispatch) => {
        const name = file.url.split('/').pop();
        const fileMap = Map({ url: file.url, name, progress: 0, withCredentials: file.withCredentials });
        dispatch(addFile(fileMap));
    };
}

function resetFile() {
    return async(dispatch) => {
        dispatch(setFilesDownload(Map([])));
        dispatch(setFilesUpload(Map([])));
    };
}

function publishScreenStream(call, fps) {
    return async(dispatch) => {
        try {
            await BandyerSDK.getInstance().call.publishScreen(call.get('callAlias'), fps);
            dispatch(publishScreen(true));
        } catch (e) {
            const errorMessage = e.message;
            if (errorMessage) {
                switch (errorMessage) {
                    case constants.SDK_SCREENSHARE_EXTENSION_NOT_INSTALLED:
                        dispatch(showExtensionAlert(true));
                        break;
                    case constants.SDK_REPLACE_TRACK_NOT_SUPPORTED:
                        break;
                    default:
                    // dispatch(showExtensionAlert(true));
                }
            }
        }
    };
}

function unpublishScreenStream(call) {
    return async(dispatch) => {
        try {
            await BandyerSDK.getInstance().call.unpublishScreen(call.get('callAlias'));
            dispatch(publishScreen(false));
        } catch (e) {
            Logger.warn('Error in unpublishScreenStream', e);
        }
    };
}

function getEnumerateDevices(request = 'all') {
    return async(dispatch) => {
        let result = await BandyerSDK.getInstance().call.enumerateDevices();
        switch (request) {
            case 'audio':
                if (result.every(device => (device.kind === 'audioinput' ? device.label === '' : true))) {
                    return navigator.mediaDevices
                        .getUserMedia({ audio: true })
                        .then(async(mediaStream) => {
                            BandyerSDK.getInstance().call.enumerateDevices()
                                .then((devices) => {
                                    result = devices;
                                    dispatch(enumerateDevices(result));
                                    dispatch(setMicrophonePermissionDenied(false));
                                })
                                .finally(() => mediaStream.getTracks().forEach(t => t.stop()));
                        })
                        .catch(() => {
                            Logger.warn('getEnumerateDevices - setMicrophonePermissionDenied true');
                            dispatch(setMicrophonePermissionDenied(true));
                            dispatch(enumerateDevices(result));
                        });
                }
                break;
            case 'video':
                if (result.every(device => (device.kind === 'videoinput' ? device.label === '' : true))) {
                    return navigator.mediaDevices
                        .getUserMedia({ video: true })
                        .then(async(mediaStream) => {
                            BandyerSDK.getInstance().call.enumerateDevices()
                                .then((devices) => {
                                    result = devices;
                                    dispatch(enumerateDevices(result));
                                    dispatch(setCameraPermissionDenied(false));
                                })
                                .finally(() => mediaStream.getTracks().forEach(t => t.stop()));
                        })
                        .catch(() => {
                            dispatch(setCameraPermissionDenied(true));
                            dispatch(enumerateDevices(result));
                        });
                }
                break;
            case 'all':
                if (
                    result.every(device => (device.kind === 'videoinput' || device.kind === 'audioinput' ? device.label === '' : true))
                ) {
                    return navigator.mediaDevices
                        .getUserMedia({ audio: true, video: true })
                        .then(async(mediaStream) => {
                            result = await BandyerSDK.getInstance().call.enumerateDevices()
                                .then((devices) => {
                                    result = devices;
                                    dispatch(enumerateDevices(result));
                                    dispatch(setCameraPermissionDenied(false));
                                    dispatch(setMicrophonePermissionDenied(false));
                                })
                                .finally(() => mediaStream.getTracks().forEach(t => t.stop()));
                        })
                        .catch(() => {
                            dispatch(setCameraPermissionDenied(true));
                            dispatch(enumerateDevices(result));
                        });
                }
                break;
            default:
                dispatch(enumerateDevices(result));
                return true;
        }

        dispatch(enumerateDevices(result));
        return true;
    };
}

function changeDeviceSource(callAlias, audioDevice, videoDevice) {
    return async(dispatch) => {
        try {
            await BandyerSDK.getInstance().call.unpublishWebcam();
            const config = {
                audio: { deviceId: { exact: audioDevice } },
                video: { deviceId: { exact: videoDevice } }
            };
            if (audioDevice === 'none') {
                config.audio = false;
                dispatch(toggleAudio(true));
            } else {
                dispatch(resetAudio());
            }
            if (videoDevice === 'none') {
                config.video = false;
                dispatch(toggleVideo(true));
            } else {
                dispatch(resetVideo());
            }
            /* if (audioDevice === 'none' && videoDevice === 'video') {
                config.data = true;
            } */
            await BandyerSDK.getInstance().call.publishWebCam(callAlias, config);
        } catch (err) {
            // todo da migliorare
            switch (err) {
                case PUBLISH_STREAM_NOT_VALID:
                    break;
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
        }
    };
}

function handleManualRecording(recording) {
    return async(dispatch) => {
        try {
            let handledRecording = await BandyerSDK.getInstance().call.handleManualRecording(recording);
            // if we start a recording we expect true and make the isRecording flag to true, if we stop the recording, we expect true but we update with false
            handledRecording = recording ? handledRecording : !handledRecording;
            return dispatch(updateRecordingInProgress(handledRecording));
        } catch (err) {
            // DO NOTHING ??
        }
    };
}

export default {
    createCall,
    updateCall,
    handleDeclinedCall,
    handleIncomingCall,
    handleStoppedCall,
    errorGum,
    errorCallSocket,
    hangUpTheCall,
    answerTheCall,
    declineTheCall,
    showExtensionAlert,
    toggleAudio,
    toggleVideo,
    changeMainVideo,
    publishWebcamStream,
    unpublishScreenStream,
    publishScreenStream,
    disconnectTheCall,
    togglePublisherAudio,
    togglePublisherVideo,
    closeWindowCall,
    publishWebcam,
    publishScreen,
    focusWindowCall,
    upgradeToPublisherWebcam,
    requestPermissionToUpgradeVideo,
    publisherHasAudio,
    publisherHasVideo,
    getEnumerateDevices,
    selectAudioDevice,
    selectVideoDevice,
    changeDeviceSource,
    setCameraPermissionDenied,
    setMicrophonePermissionDenied,
    setMediaStream,
    setStreamEndedError,
    setStreamFailedError,
    multipleSocketError,
    joinCallURL,
    setReconnectingCall,
    setError,
    setVerification,
    setRemoteVideoMuted,
    addFileToDownload,
    setFileUploaded,
    updateDownloadFile,
    resetFile,
    removeFileUploaded,
    closePlugin,
    closeError,
    updateRecordingInfo,
    handleManualRecording,
    updateRecordingInProgress,
    setIsAdmin,
    setVirtualBackgrounds,
    showRequestPlayPermission,
    setFailedMediaElements,
    setRecordingError,
    showMuteByAdmin,
    setFullScreenMode,
    resetVideo,
    resetAudio,
    updateParticipantsStateInRoom
};
