import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Style from './style.scss';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

class IEPlugin extends PureComponent {
    render() {
        const close = () => {
            const { closePlugin, call } = this.props;
            closePlugin(call);
        };
        return (
            <div className={Style['ie-plugin-container']}>
                <h4>
                    <FormattedMessage
                        id="IEPlugin.InstallMessage"
                        defaultMessage="To use Bandyer on Internet Explorer please install the plugin of our partner Temasys; It will only take 30 seconds."
                    />
                </h4>
                <img
                    alt="ie-plugin-img"
                    className={Style['plugin-img']}
                    src="https://static.bandyer.com/corporate/images/plugin_temasys.png"
                />
                <div className={Style['install-container']}>
                    <Button variant="contained" className={Style.pluginButton} onClick={close}>
                        <FormattedMessage id="IEPlugin.CloseButton" defaultMessage="Close" />
                    </Button>
                    <Button variant="contained" className={Style.pluginButton} onClick={this.props.downloadPlugin}>
                        <FormattedMessage id="IEPlugin.InstallButton" defaultMessage="Install" />
                    </Button>
                </div>
            </div>
        );
    }
}

IEPlugin.propTypes = { downloadPlugin: PropTypes.func, closePlugin: PropTypes.func, call: PropTypes.instanceOf(Map) };

IEPlugin.defaultProps = { downloadPlugin: null, closePlugin: null, call: Map({}) };

export default IEPlugin;
