// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-ie-plugin-container{padding:10px 5px;text-align:center;color:#fff;background-color:#0c2e41}.Bandyer-ie-plugin-container .Bandyer-plugin-img{height:30%}.Bandyer-ie-plugin-container .Bandyer-pluginButton{background:#FF6D00;color:#ffffff;margin:0 20px}.Bandyer-ie-plugin-container .Bandyer-install-container{margin:0 20px;text-align:center}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"ie-plugin-container": "Bandyer-ie-plugin-container",
	"plugin-img": "Bandyer-plugin-img",
	"pluginButton": "Bandyer-pluginButton",
	"install-container": "Bandyer-install-container"
};
module.exports = exports;
