import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import CloseIcon from '@material-ui/icons/Close';
import ChatIcon from '@material-ui/icons/Chat';
import Fab from '@material-ui/core/Fab';
import Style from './style.scss';

function LauncherComponent({ toggle, chatOpened, notification, launcherStyle }) {
    return (
        <Fab
            type="button"
            className={chatOpened ? `${Style.launcher} ${Style['hide-sm']}` : Style.launcher}
            onClick={toggle}
            style={{ background: launcherStyle.get('background'), color: launcherStyle.get('color') }}
        >
            {chatOpened ? (
                <CloseIcon className={Style['close-launcher']} alt="close-launcher" fontSize="large" />
            ) : (
                <ChatIcon className={Style['open-launcher']} alt="open-launcher" fontSize="large" />
            )}
            {!chatOpened && !!notification && <span className={Style['notification-badge']}>{notification}</span>}
        </Fab>
    );
}

LauncherComponent.propTypes = {
    toggle: PropTypes.func,
    chatOpened: PropTypes.bool,
    notification: PropTypes.number,
    launcherStyle: PropTypes.instanceOf(Map)
};

LauncherComponent.defaultProps = {
    toggle: null,
    chatOpened: false,
    notification: 0,
    launcherStyle: Map({})
};

export default LauncherComponent;
