import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import Style from './style.scss';
import { buildUserEmail, fetchUserImage } from '../../../../../../../../helpers/utils';
import { WIDGET_MODE_EMBED, CALL_WINDOW, CALL } from '../../../../../../../../constants';

class Header extends PureComponent {
    backToCall = () => {
        const { changeView, mode, focusWindowCall, call } = this.props;
        if (mode === WIDGET_MODE_EMBED) {
            changeView(CALL);
        } else {
            changeView(CALL_WINDOW);
            focusWindowCall(call);
        }
    };

    render() {
        const ToolTipBackToCall = <FormattedMessage id="Channels.ToolTipBackToCall" defaultMessage="Back to call" />;
        return (
            <div
                className={Style['channel-header']}
                style={{
                    background: this.props.headerStyle.get('background'),
                    color: this.props.headerStyle.get('color')
                }}
            >
                <div>
                    {this.props.localUser.has('image') ? (
                        <Avatar src={fetchUserImage(this.props.localUser)} className={Style['big-avatar']} />
                    ) : null}
                </div>
                <div className={Style['user-name']}>
                    <h3 className={Style.userNameLabel} style={{ color: this.props.headerStyle.get('color') }}>
                        {this.props.localUser.get('formattedName')}
                    </h3>
                </div>
                <div className={Style['action-buttons']}>
                    {!this.props.call.isEmpty() && (
                        <Tooltip title={ToolTipBackToCall}>
                            <IconButton
                                aria-label="back-to-call"
                                onClick={this.backToCall}
                                style={{ color: this.props.headerStyle.get('button').get('color') }}
                            >
                                <PhoneCallbackIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    localUser: PropTypes.instanceOf(Map),
    headerStyle: PropTypes.instanceOf(Map),
    call: PropTypes.instanceOf(Map),
    changeView: PropTypes.func,
    focusWindowCall: PropTypes.func
};
Header.defaultProps = {
    localUser: Map({}),
    headerStyle: Map({}),
    call: Map({}),
    changeView: null,
    focusWindowCall: null
};

export default Header;
