import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import Style from './style.scss';

const installText = (
    <FormattedMessage
        id="Call.installScreenShareExtensionText"
        defaultMessage="Add this extension to share your screen"
    />
);
const installButton = <FormattedMessage id="Call.installScreenShareExtensionButton" defaultMessage="Install" />;
class ScreenShareExtension extends PureComponent {
    render() {
        return (
            <div className={Style['screen-share-extension']}>
                <div className={Style['button-close']}>
                    <IconButton aria-label="Close" onClick={this.props.closeAlert} style={{ color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={Style['install-container']} onClick={this.props.closeAlert}>
                    <p>{installText}</p>
                    <Button
                        variant="contained"
                        style={{
                            background: this.props.headerStyle.get('button').get('background'),
                            color: this.props.headerStyle.get('button').get('color')
                        }}
                        className={Style['install-button']}
                        href={this.props.extensionUrl}
                        target="_blank"
                    >
                        {installButton}
                    </Button>
                </div>
            </div>
        );
    }
}

ScreenShareExtension.propTypes = {
    extensionUrl: PropTypes.string,
    closeAlert: PropTypes.func,
    headerStyle: PropTypes.instanceOf(Map)
};

ScreenShareExtension.defaultProps = {
    extensionUrl: 'url',
    closeAlert: null,
    headerStyle: Map({})
};

export default ScreenShareExtension;
