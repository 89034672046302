// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-footer-call-container{position:absolute;bottom:-5px;left:0;right:0}.Bandyer-footer-call-container .Bandyer-buttons{text-align:center}.Bandyer-footer-call-container .Bandyer-buttons .Bandyer-camera-button-container{display:inline-block}.Bandyer-footer-call-container .Bandyer-buttons .Bandyer-hangup-call{background-color:#e63247;color:#fff;z-index:6}.Bandyer-footer-call-container .Bandyer-buttons .Bandyer-main-controls{background-color:rgba(0,0,0,0.45);color:#fff}.Bandyer-footer-call-container .Bandyer-buttons .Bandyer-main-controls.Bandyer-toggle-audio,.Bandyer-footer-call-container .Bandyer-buttons .Bandyer-main-controls.Bandyer-toggle-video{z-index:2}.Bandyer-footer-call-container .Bandyer-buttons button{margin:0 5px}\n", ""]);
// Exports
exports.locals = {
	"footer-call-container": "Bandyer-footer-call-container",
	"buttons": "Bandyer-buttons",
	"camera-button-container": "Bandyer-camera-button-container",
	"hangup-call": "Bandyer-hangup-call",
	"main-controls": "Bandyer-main-controls",
	"toggle-audio": "Bandyer-toggle-audio",
	"toggle-video": "Bandyer-toggle-video"
};
module.exports = exports;
