
import { validateInitWidgetConfig } from './helpers/parseConfig';
import store from './store/store';
import {
    configureSDK
} from './store/actions/dispatcher';
import BandyerSDK from './services';
import { ExposeError } from './helpers/errorHandler';

require('./helpers/polyfill');
require('./helpers/prependPolyfill');

const { version } = require('../package.json');

let client = null;

export default {
    // This is the public interface of the widget
    configure: (config) => {
        if (!client) {
            const parsedConfig = validateInitWidgetConfig(config);
            client = configureSDK(parsedConfig);
            return client;
        }

        throw new ExposeError({ code: 'client_already_exists', message: 'A client already exists, you need to destroy the client before create a new one' });
    },

    destroyClient: async() => {
        try {
            await client.disconnect();
        } catch (e) {

        } finally {
            client = null;
            BandyerSDK.destroy();
        }
    },
    isAuthenticated: () => store.getState().behavior.get('widgetReady'),
    get version() {
        return version;
    }


};
