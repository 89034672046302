import actions from './actions';
import { ERROR } from '../../../constants';
import widgetOperations from '../../widget/redux/operations';

const { changeErrorKey } = actions;

const changeToViewError = function(errorKey) {
    return dispatch => {
        dispatch(widgetOperations.changeView(ERROR));
        dispatch(changeErrorKey(errorKey));
    };
};

export default {
    changeErrorKey,
    changeToViewError
};
