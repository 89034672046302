import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import variables from '../../../../../../../../styles/variables.scss';
import Style from './style.scss';

const styles = theme => ({ progress: { margin: theme.spacing(2), color: variables.bOrange } });

function CircularIndeterminate(props) {
    const { classes } = props;
    return (
        <div className={Style['call-loader']}>
            <div className={Style['call-loader-container']}>
                <CircularProgress className={classes.progress} size={60} color="secondary" />
            </div>
        </div>
    );
}

CircularIndeterminate.propTypes = { classes: PropTypes.object.isRequired };
CircularIndeterminate.defaultTypes = { classes: {} };

export default withStyles(styles)(CircularIndeterminate);
