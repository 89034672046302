import { Map } from 'immutable';
import actionTypes from './types';

const initialState = Map({
    errorKey: ''
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CHANGE_ERROR_KEY:
            return state.update('errorKey', () => action.errorKey);
        default:
            return state;
    }
}
