import React, { PureComponent } from 'react';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import PropTypes from 'prop-types';
import Style from './style.scss';

class AudioVisualiser extends PureComponent {
    constructor() {
        super();
        this.audioVisualizerRef = React.createRef();
        this.state = { supportedBrowser: true };
    }

    componentDidMount() {
        if (!window.currentAudioContext) {
            window.currentAudioContext = window.AudioContext || window.webkitAudioContext || false;
            if (window.currentAudioContext) {
                window.currentAudioContext = new window.currentAudioContext();
                this.setState({ supportedBrowser: true });
            } else {
                this.setState({ supportedBrowser: false });
                window.currentAudioContext = null;
            }
        }
        this.audioContext = window.currentAudioContext;
    }

    componentDidUpdate() {
        const { mediaStream } = this.props;
        const { supportedBrowser } = this.state;
        if (supportedBrowser && mediaStream && mediaStream.active && mediaStream.getAudioTracks().length) {
            const visualMainElement = this.audioVisualizerRef.current;
            while (visualMainElement.firstChild) {
                visualMainElement.removeChild(visualMainElement.firstChild);
            }
            const visualValueCount = 3;
            let visualElements;
            const dataMap = { 0: 4, 1: 2, 2: 4 };
            this.processFrame = data => {
                const values = Object.values(data);
                let i;
                for (i = 0; i < visualValueCount; ++i) {
                    const value = Math.max(values[dataMap[i]] / 255, 0.1);
                    const elmStyles = visualElements[i].style;
                    elmStyles.transform = `scaleY( ${value} )`;
                    // elmStyles.opacity = Math.max(0.25, value);
                }
            };
            this.processError = () => {
                visualMainElement.classList.add('error');
                visualMainElement.innerText = 'Error';
            };

            this.connectStream(mediaStream);
            const createDOMElements = () => {
                let i;
                for (i = 0; i < visualValueCount; ++i) {
                    const elm = document.createElement('div');
                    elm.className = Style['audio-visualizer-item'];
                    visualMainElement.appendChild(elm);
                }
                visualElements = visualMainElement.querySelectorAll('div');
            };
            createDOMElements();
        }
    }

    connectStream(stream) {
        this.analyser = this.audioContext.createAnalyser();
        const source = this.audioContext.createMediaStreamSource(stream);
        source.connect(this.analyser);
        this.analyser.smoothingTimeConstant = 0.8;
        this.analyser.fftSize = 32;
        this.initRenderLoop();
    }

    initRenderLoop() {
        const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
        const processFrame = this.processFrame || (() => {});

        const renderFrame = () => {
            this.analyser.getByteFrequencyData(frequencyData);
            processFrame(frequencyData);
            requestAnimationFrame(renderFrame);
        };
        requestAnimationFrame(renderFrame);
    }

    render() {
        const { audioMuted } = this.props;
        const { supportedBrowser } = this.state;
        return (
            <>
                {supportedBrowser && (
                    <div className={Style['bandyer-audio-visualizer']} ref={this.audioVisualizerRef} />
                )}
                {!supportedBrowser && (
                    <div>
                        {audioMuted ? (
                            <MicOffIcon color="error" fontSize="large" />
                        ) : (
                            <MicIcon style={{ color: '#0c2e41' }} fontSize="large" />
                        )}
                    </div>
                )}
            </>
        );
    }
}

AudioVisualiser.propTypes = {
    mediaStream: PropTypes.any || PropTypes.instanceOf(window.MediaStream),
    audioMuted: PropTypes.bool
};

AudioVisualiser.defaultProps = { mediaStream: null, audioMuted: false };

export default AudioVisualiser;
