import { connect } from 'react-redux';
import GenericCallErrorComponent from './GenericaCallErrorComponent';
import widgetOperations from '../../widget/redux/operations';
import { CHANNELS } from '../../../constants';
import { callOperations } from '../../widget/components/main/components/call/redux';

const mapStateToProps = state => {
    return {
        errorKey: state.errors.get('errorKey')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeToChannel: () => dispatch(callOperations.closeError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericCallErrorComponent);
