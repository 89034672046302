import actions from './actions';
import UserDetailsService from '../../../services/userDetails';

const { updateUsersDetails, setOnlineUsers, updateOnlineUsers } = actions;

const provideDetails = function(users, force = false) {
    return () => {
        UserDetailsService.getInstance().provideDetails(users, force);
    };
};

export default {
    updateUsersDetails,
    provideDetails,
    setOnlineUsers,
    updateOnlineUsers
};
