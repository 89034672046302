// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-sender{align-items:center;display:flex;background-color:#f4f7f9;height:45px;padding:5px;border-radius:0 0 10px 10px;margin:0}.Bandyer-new-message{width:100%;border:0;background-color:#f4f7f9;height:30px;padding-left:15px}.Bandyer-new-message:focus{outline:none}.Bandyer-send{background:#f4f7f9;border:0}.Bandyer-send .Bandyer-send-icon{height:25px}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-new-message{font-size:17px}.Bandyer-send .Bandyer-send-icon{height:30px}}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"sender": "Bandyer-sender",
	"new-message": "Bandyer-new-message",
	"send": "Bandyer-send",
	"send-icon": "Bandyer-send-icon"
};
module.exports = exports;
