// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Bandyer-call-loader{background-color:rgba(0,0,0,0.7);position:absolute;top:0;left:0;right:0;bottom:0;z-index:-1}.Bandyer-call-loader .Bandyer-call-loader-container{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}\n", ""]);
// Exports
exports.locals = {
	"call-loader": "Bandyer-call-loader",
	"call-loader-container": "Bandyer-call-loader-container"
};
module.exports = exports;
