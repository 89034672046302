import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DoneIcon from '@material-ui/icons/Done';
import { LinearProgress, Box, Tooltip, SvgIcon } from '@material-ui/core';
import DownloadJS from 'downloadjs';
import { ReactComponent as NewFile } from '../../../../../../../assets/icon/New_File.svg';
import { ReactComponent as File } from '../../../../../../../assets/icon/File.svg';
import Style from '../style.scss';

const DetectRTC = require('detectrtc');

class DownloadItem extends PureComponent {
    constructor(props) {
        super(props);
        const { fileItem, newFile } = this.props;
        this.state = { progress: fileItem.get('progress'), newFile };
    }

    componentDidUpdate(prevProps) {
        const { newFile } = this.props;
        if (prevProps.newFile !== newFile) {
            this.setState({ newFile });
        }
    }

    download = () => {
        const { fileItem, updateDownloadFile, setDownloadNotification } = this.props;
        this.setState({ progress: 0, newFile: false });
        if (DetectRTC.osName === 'iOS' && DetectRTC.osVersion.split('.')[0] < 13) {
            window.open(fileItem.get('url'), '_blank');
            this.setState({ progress: 100 });
            const newFile = fileItem.set('progress', 100);
            updateDownloadFile(newFile);
        } else {
            DownloadJS(fileItem.get('url'), undefined, undefined, fileItem.withCredentials || false).addEventListener(
                'progress',
                progressEvent => {
                    const progress = parseInt((progressEvent.loaded / progressEvent.total) * 100, 10);
                    this.setState({ progress });
                    const newFile = fileItem.set('progress', progress);
                    updateDownloadFile(newFile);
                }
            );
        }
        setDownloadNotification(0);
    };

    render() {
        const { fileItem } = this.props;
        const { progress, newFile } = this.state;
        return (
            <Box
                p={1}
                display="flex"
                alignItems="center"
                flexDirection="row"
                className={Style['file-item']}
                key={fileItem.get('name')}
            >
                {newFile ? (
                    <SvgIcon style={{ color: '#0c2e41' }} component={NewFile} />
                ) : (
                    <SvgIcon style={{ color: '#606060' }} component={File} />
                )}
                <Box
                    p={1}
                    flexDirection="column"
                    flexGrow={1}
                    className={Style['progress-container']}
                    onClick={this.download}
                >
                    <Tooltip title={fileItem.get('name')}>
                        <div className={Style.fileName}>{fileItem.get('name')}</div>
                    </Tooltip>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                {progress === 100 ? <DoneIcon className={Style.doneIcon} /> : ''}
            </Box>
        );
    }
}

DownloadItem.propTypes = {
    fileItem: PropTypes.instanceOf(Object),
    progress: PropTypes.func,
    updateDownloadFile: PropTypes.func,
    newFile: PropTypes.bool,
    setDownloadNotification: PropTypes.func
};

DownloadItem.defaultProps = {
    fileItem: Map([]),
    progress: null,
    updateDownloadFile: null,
    newFile: false,
    setDownloadNotification: null
};

export default DownloadItem;
